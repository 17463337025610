import React, { useContext, useEffect, useState } from "react";
import "./MktgNotificationsContainer.css";
import axios from "axios";
import AuthContext from "../../../../contexts/AuthContext";
import DeleteConfirmationModal from "../../../../components/DeleteConfirmationModal/DeleteConfirmationModal";
import TanStackTable from "../../../../components/Table/TanStackTable/TanStackTable";
import { createColumnHelper } from "@tanstack/react-table";
import ContentCreator from "../../../../components/ContentCreator/ContentCreator";
import showNotification from "../../../../utils/showNotification";
import { ERROR_HANDLER } from "../../../../utils/error-handler";
import SponsorBanner from "../../../../components/SponsorBanner/SponsorBanner";
import MktgNotificationsForm from "../MktgNotificationsForm.js/MktgNotificationsForm";
import {EVENT_NATIONALITY} from "../../../../utils/countries-list";
import moment from "moment";
import useS3 from "../../../../hooks/useS3";

const mapEventsType = {
  General: "General",
  Nacionalidad: "Todos los países",
  ...EVENT_NATIONALITY.reduce((acc, country) => {
    acc[country] = country;
    return acc;
  }, {}),
};


const createEvent = async (values, config) => {
  if (values.title === undefined) {
    values.title = "  "; 
  }
  const jsonData = {
    data: {
      ...values,
    },
  };
  return axios.post(
    `${process.env.REACT_APP_BACKEND_SERVER}admin/marketing/newscampus`,
    jsonData,
    config
  );
};
const editEvent = async (values, config) => {
  if (values.title === undefined) {
    values.title = "  "; 
  }
  const jsonData = {
    item: {_id: values.id},
    field: {
      ...values,
    },
  };
  return axios.put(
    `${process.env.REACT_APP_BACKEND_SERVER}admin/marketing/newscampus`,
    jsonData,
    config
  );
};

function MktgNotificationsContainer() {
  const { authToken } = useContext(AuthContext);
  const [notificationsEventsData, setNotificationsEventsData] = useState(null);
  const [displayForm, setDisplayForm] = useState(false);
  const [initialValuesEdit, setInitialValuesEdit] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedNotificationsEventId, setSelectedNotificationsEventId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const { uploadToS3 } = useS3()

  // COLUMN DEFINITIONS
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("expireAt", {
      cell: (info) => {
        const dateValue = new Date(info.getValue());
        return  moment(dateValue).format("DD/MM/YYYY");
      },
      header: "Fecha de expiracion",
      enableColumnFilter: false,
      enableSorting: true,
      sortingFn: "datetime",
      size: 64,
    }),
    columnHelper.accessor("title", {
      cell: (info) => info.getValue(),
      header: "Titulo",
      enableColumnFilter: true,
      enableSorting: false,
      filter: "text",
      filterFn: "includesString",
    }),
    columnHelper.accessor("place", {
      cell: (info) => mapEventsType[info.getValue()],
      header: "Ubicación",
    }),
    columnHelper.accessor("link", {
      cell: (info) => info.getValue(),
      header: "Link",
    }),
  ];

  // TABLE FUNCTIONS
  const editNotificationsEvent = (rowData) => {
    if (rowData) {
      setInitialValuesEdit({
        id: rowData._id,
        title: rowData.title,
        link: rowData.link,
        notification: rowData.notification,
        img: rowData.img,
        expireAt: rowData.expireAt,
        place: rowData.place,
      });
      setDisplayForm(true);
    }
  };

  const deleteNotificationsEvent = async (idNotification = null) => {
    if (deleteModalOpen) {
      try {
        setLoading(true);
        await axios.delete(
          `${process.env.REACT_APP_BACKEND_SERVER}admin/marketing/newscampus`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + authToken,
            },
            data: { _id: selectedNotificationsEventId._id },
          }
        );
        setUpdateData(!updateData);
        showNotification({
          color: "green",
          status: "success",
          title: "¡Operación exitosa!",
          message: "El elemento seleccionado ha sido correctamente eliminado.",
          autoClose: 6000,
        });
      } catch (error) {
        console.error(error);
        showNotification({
          color: "red",
          status: "error",
          title: "Operación fallida.",
          message: `No se pudo eliminar la notificación. Detalle: ${
            ERROR_HANDLER[error.request?.status] || ERROR_HANDLER.defaultError
          }`,
          autoClose: 12000,
        });
      } finally {
        setDeleteModalOpen(false);
        setLoading(false);
      }
    } else {
      setSelectedNotificationsEventId(idNotification);
      setDeleteModalOpen(true);
    }
  };

  const handleSubmit = async (values) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      },
    };
  
    try {
      setLoading(true);
      if (values.img) {
        const confirmTitle = values.title === undefined ?  " emptyTitle " : values.title;
        const clearTitle = confirmTitle.replace(/[^\w\s]/g, '').trim();
        values.img = await uploadToS3({
          image: values.img,
          fileName: `${clearTitle}-${moment().format('DD-MM-YYYY-HH-mm-ss')}`,
          fileDirectory: "public/images/newscampus", 
          isRequired: true,
        });

        // Check if it's an edit or a new event
        if (initialValuesEdit) {
          await editEvent(values, config);
        } else {
          await createEvent(values, config);
        }
      }
  
      showNotification({
        color: "green",
        status: "success",
        title: "¡Excelente! Operación exitosa.",
        message: `El evento ${values.name} ha sido creado / editado con éxito.`,
        autoClose: 6000,
      });
      setUpdateData(!updateData);
    } catch (error) {
      showNotification({
        color: "red",
        status: "error",
        title: "Operación fallida.",
        message: `No se pudo crear / editar el evento. Detalle: ${
          ERROR_HANDLER[error.request?.status] || ERROR_HANDLER.defaultError
        }`,
        autoClose: 6000,
      });
    } finally {
      setLoading(false);
      setDisplayForm(false);
      setInitialValuesEdit(null);
    }
  };
  

  // DATA INITIALIZATION
  const init = async () => {
    const url = `${process.env.REACT_APP_BACKEND_SERVER}admin/marketing/newscampus`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const notificationsResponse = await axios.get(url, config);
    setNotificationsEventsData(notificationsResponse.data);
  };

  useEffect(() => {
    init();
  }, [updateData]);

  return (
    <div className="mktgNotifications__container">
      {displayForm && (
        <ContentCreator
          title={initialValuesEdit ? "Editar evento" : "Crear evento"}
          submitFunc={(values) => handleSubmit(values)}
          onCancel={() => {
            setDisplayForm(false);
            setInitialValuesEdit(null);
          }}
          initialValues={initialValuesEdit ?? null}
          renderPreview={(_form) => (
            <SponsorBanner
              link={_form.form.values.link}
              img={_form.form.values.img}
              title={_form.form.values.title}
            />
          )}
          renderForm={(_form) => (
            <MktgNotificationsForm form={_form.form} loading={loading} />
          )}
        />
      )}
      {notificationsEventsData && (
        <TanStackTable
          loading={loading}
          data={notificationsEventsData}
          columns={columns}
          onDelete={deleteNotificationsEvent}
          editFunc={editNotificationsEvent}
          displayForm={displayForm}
          addButtonFunc={() => {
            setDisplayForm(!displayForm);
            if (displayForm) setInitialValuesEdit(null);
          }}
        />
      )}

      <DeleteConfirmationModal
        opened={deleteModalOpen}
        loading={loading}
        onClose={() => {
          setDeleteModalOpen(false);
          setSelectedNotificationsEventId(null);
        }}
        onCancel={() => {
          setDeleteModalOpen(false);
          setSelectedNotificationsEventId(null);
        }}
        onConfirm={deleteNotificationsEvent}
      />
    </div>
  );
}

export default MktgNotificationsContainer;
