import AcademicDbTeachersRank from "../../../../components/AcademicDashboardComponents/AcademicDbTeachersRank/AcademicDbTeachersRank";
import "./AcademicDbTeachers.css";

const AcademicDbTeachers = ({ data }) => {
  return (
    <>
      <div className="AcademicDbTeachers-mainDiv">
        <h3 className="AcademicDbTeachers-ranks-header">Profesores</h3>
        <AcademicDbTeachersRank teachers={data} />
      </div>
    </>
  );
};
export default AcademicDbTeachers;
