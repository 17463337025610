import React, {useContext, useEffect, useState} from 'react';
import "./Lessons.css";
import {Grid, Button, Tabs, MultiSelect} from "@mantine/core";
import AcademicContext from "../../../../../contexts/AcademicContext";
import MantineSelect from "../../../../../components/MantineSelect/MantineSelect";
import LessonCard from "../../../../../components/LessonCard/LessonCard";
import {IconEdit, IconTrash} from '@tabler/icons-react'
import CsvFileInput from "../../../../../components/CsvFileInput/CsvFileInput";
import MantineSwitchButton from "../../../../../components/MantineSwitchButton/MantineSwitchButton";
import PreloadedLessonsTable from "../../../../../features/Academic/PreloadedLessonsTable/PreloadedLessonsTable";
import moment from "moment";

const EMPTY_ROW = {
    discard: "MÓDULO",
    module_number: 0,
    module_name: "",
    date: moment().format('DD/MM/YYYY'),
    teacher: "",
    lesson_name: "",
}

function LessonsForm(props) {
    const {form, setCreateModuleModal, setCreateLessonModal, isCreateNew, setEditLessonModal} = props;
    const {modulesOptions, lessonsOptions, lessonsData, lessonsDict, specialtiesOptions} = useContext(AcademicContext);
    const [activeTab, setActiveTab] = useState(null);
    const [editingRow, setEditingRow] = useState(null);

    const handleFileLoad = (csvData) => {
        form.setFieldValue('csvData', csvData.filter(row => row.discard !== "null"));
    };

    useEffect(() => {
        const currentModule = form.values.modules[activeTab];
        if (activeTab && currentModule.length === 0) { // Access only if the module is empty
            const lessons = lessonsData.filter(lesson => lesson.modules.includes(activeTab))
            form.setFieldValue(`modules.${activeTab}`, lessons.sort((a, b) => moment.utc(a.initDate).local().valueOf() - moment.utc(b.initDate).local().valueOf()).map(lesson => lesson._id))
        }
    }, [activeTab]);

    return (
        <Grid style={{width: "100%"}}>
            <Grid.Col span={12}>
                <MultiSelect
                    {...form.getInputProps("specialties")}
                    label="Especialidades"
                    data={specialtiesOptions}
                    withAsterisk
                    searchable
                />
            </Grid.Col>
            {isCreateNew &&
                (<Grid.Col span={12}>
                        <div style={{width: "100%", display: "grid", placeItems: "center"}}>
                            <MantineSwitchButton
                                checked={form.values.uploadWithCSV}
                                onChange={() =>
                                    form.setFieldValue("uploadWithCSV", !form.values.uploadWithCSV)
                                }
                                label="Carga con CSV"
                            />
                        </div>
                    </Grid.Col>
                )}
            {form.values.uploadWithCSV && (
                <>
                    <Grid.Col span={11}>
                        <CsvFileInput
                            onFileLoad={handleFileLoad}
                            value={form.values.csvFile}
                            disabled={form.values.csvFile}
                            saveFileOnForm={(_file) => form.setFieldValue("csvFile", _file)}/>
                    </Grid.Col>
                    <Grid.Col span={1} justify="center" align="center">
                        <IconTrash size={30}
                                   className={'lessonForm__iconTrash'}
                                   onClick={() => {
                                       form.setFieldValue("csvData", null)
                                       form.setFieldValue("csvFile", null)
                                   }} m={'0 auto'}/>
                    </Grid.Col>
                    <Grid.Col span={12}>
                        {form.values.csvData &&
                            <PreloadedLessonsTable form={form} editingRow={editingRow} setEditingRow={setEditingRow}/>}
                    </Grid.Col>
                    <Grid.Col span={12} justify="center" align="center">
                        <Button
                            variant="outline"
                            color="secondaryColor"
                            className="lessonForm__button--create"
                            disabled={!form.values.csvData}
                            onClick={() => {
                                form.setFieldValue('csvData', [...form.values.csvData, EMPTY_ROW])
                                setEditingRow(form.values.csvData.length)
                            }}
                        >
                            Agregar fila
                        </Button>
                    </Grid.Col>
                </>
            )}
            {!form.values.uploadWithCSV && (
                <>
                    <Grid.Col span={9}>
                        <MantineSelect
                            form={form}
                            name="selectedModule"
                            label="Módulos"
                            description="Selecciona el módulo al que quieres agregar una clase"
                            options={modulesOptions}
                        />
                    </Grid.Col>

                    <Grid.Col span={3} justify="center" align="center">
                        <Button
                            className="lessonForm__button"
                            styles={{label: {whiteSpace: 'normal'}}}
                            onClick={() => {
                                setActiveTab(form.values.selectedModule)
                                form.setFieldValue(`modules.${form.values.selectedModule}`, [])
                                form.setFieldValue('selectedModule', null)
                            }}
                            disabled={!form.values.selectedModule}
                        >
                            Agregar módulo
                        </Button>
                    </Grid.Col>
                    <Grid.Col span={12} justify="center" align="center">
                        <Button
                            variant="outline"
                            color="secondaryColor"
                            className="lessonForm__button--create"
                            onClick={() => {
                                setCreateModuleModal(true)
                            }}
                        >
                            Crear módulo
                        </Button>
                        <Button
                            variant="outline"
                            color="secondaryColor"
                            className="lessonForm__button--create"
                            onClick={() => {
                                setCreateLessonModal(true)
                            }}
                        >
                            Crear clase
                        </Button>
                    </Grid.Col>


                    <div className="lessonForm__module__wrapper">
                        <Tabs value={activeTab} onChange={setActiveTab} orientation="vertical"
                              classNames={{
                                  tab: "lessonForm__tab",
                                  panel: "lessonForm__panel",
                                  tabLabel: "lessonForm__tabLabel",
                                  tabSection: "lessonForm__tabLabel",
                              }}
                        >
                            <Tabs.List>
                                {
                                    Object.keys(form.values.modules).map((_module, _index) => {
                                        return (
                                            <Tabs.Tab value={_module}>{`Módulo ${_index + 1}`}</Tabs.Tab>
                                        )
                                    })
                                }
                            </Tabs.List>

                            {
                                Object.keys(form.values.modules).map((_module) => {
                                    const moduleData = modulesOptions.find((module) => module.value === _module)
                                    return (
                                        <Tabs.Panel value={_module}>
                                            <div className="lessonForm__module__container">
                                                <h3 className="lessonForm__subtitle">{moduleData.label}</h3>

                                                <Grid style={{width: "100%"}}>
                                                    <Grid.Col span={9}>
                                                        <MantineSelect
                                                            form={form}
                                                            name="selectedLesson"
                                                            label="Clases"
                                                            description="Selecciona la clase que quieres agregar, luego presiona el botón 'Agregar clase'"
                                                            options={lessonsOptions}
                                                        />
                                                    </Grid.Col>

                                                    <Grid.Col span={3} justify="center" align="center">
                                                        <Button
                                                            className="lessonForm__button"
                                                            styles={{label: {whiteSpace: 'normal'}}}
                                                            onClick={() => {
                                                                form.setFieldValue(`modules.${_module}`, [...form.values.modules[_module], form.values.selectedLesson])
                                                                form.setFieldValue('selectedLesson', null)
                                                            }}
                                                            disabled={!form.values.selectedLesson}
                                                        >
                                                            Agregar clase
                                                        </Button>
                                                    </Grid.Col>
                                                </Grid>
                                                   
                                                <div className="lessonForm__lesson__wrapper">
                                                    {form.values.modules[_module]
                                                    .map((lesson) => {
                                                        const lessonData = lessonsDict[lesson];
                                                        return (
                                                            <div className="lessonForm__lesson__container">
                                                                <Grid style={{width: "100%"}}>

                                                                    <Grid.Col span={11} justify="center" align="center">
                                                                        <LessonCard
                                                                            name={lessonData.name}
                                                                            initDate={lessonData.initDate}
                                                                            roomUrl={lessonData.roomUrl}
                                                                        />
                                                                    </Grid.Col>

                                                                    <Grid.Col span={1} justify="center" align="center"
                                                                              style={{alignSelf: "center"}}>
                                                                        <IconTrash
                                                                            className='lessonForm__lessonIcon' size={30}
                                                                            onClick={() => form.setFieldValue(`modules.${_module}`, form.values.modules[_module].filter((_lesson) => _lesson !== lesson))}/>
                                                                        <IconEdit
                                                                            className='lessonForm__lessonIcon' size={30}
                                                                            onClick={() => setEditLessonModal(lesson)}/>
                                                                    </Grid.Col>
                                                                </Grid>

                                                            </div>
                                                        )
                                                    })}
                                                </div>

                                                <Button
                                                    className="lessonForm__button"
                                                    styles={{label: {whiteSpace: 'normal'}}}
                                                    variant="outline"
                                                    onClick={() => {
                                                        // Filter modules and remove the selected module
                                                        const newModules = Object.keys(form.values.modules)
                                                            .filter(key => key !== _module)
                                                            .reduce((obj, key) => {
                                                                obj[key] = form.values.modules[key];
                                                                return obj;
                                                            }, {});
                                                        form.setFieldValue(`modules`, newModules)
                                                    }}
                                                >
                                                    Eliminar módulo
                                                </Button>
                                            </div>
                                        </Tabs.Panel>
                                    )
                                })
                            }
                        </Tabs>
                    </div>
                </>
            )}
        </Grid>
    );
}

export default LessonsForm;