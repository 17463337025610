import { Skeleton } from "@mantine/core";
import "./AcademicDbRankings.css";

const AcademicDbRankingsSkeleton = () => {
  return (
    <div className="academicDbRankings-mainDiv">
      <h3 className="academicDbRankings-ranks-header">
        <Skeleton height={30} width="40%" />
      </h3>
      <div className="academicDbRankings-gridContainer">
        <article className="academicDbRankings-card">
          <Skeleton height={20} width="30%" />
          <div className="academicDbRankings-stack-wrapper">
            {[...Array(4)].map((_, index) => (
              <div
                className="academicDbRankings-card-rowItem"
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                key={index}
              >
                <Skeleton height={20} width="60%" />
                <Skeleton height={20} width="30%" />
              </div>
            ))}
          </div>
        </article>

        <article className="academicDbRankings-card">
          <Skeleton height={20} width="30%" />
          <div className="academicDbRankings-stack-wrapper">
            {[...Array(4)].map((_, index) => (
              <div
                className="academicDbRankings-card-rowItem"
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                key={index}
              >
                <Skeleton height={20} width="60%" />
                <Skeleton height={20} width="30%" />
              </div>
            ))}
          </div>
        </article>

        <article className="academicDbRankings-card">
          <Skeleton height={30} width="80%" />
          <Skeleton height={100} />
        </article>
      </div>
    </div>
  );
};

export default AcademicDbRankingsSkeleton;
