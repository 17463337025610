import { PieChart } from "@mantine/charts";
import { Group, Stack } from "@mantine/core";
import { IconChartArrows } from "@tabler/icons-react";
import AcademicDbUsersTotals from "../AcademicDbUsersTotals/AcademicDbUsersTotals";
import './AcademicDbUsersPieChart.css';

const AcademicDbUsersPieChart = ({ users }) => {
  const { byType, totalStudents, totalTeachers } = users;

  const total = Object.values(byType).reduce((acc, value) => acc + value, 0);

  const chartData = [
    { name: "Curso", value: byType.Curso, color: "var(--color-pieCharts-curso)" },
    { name: "Diplomado", value: byType.Diplomado, color: "var(--color-pieCharts-diplomado)" },
    { name: "Masterclass", value: byType.Masterclass, color: "var(--color-pieCharts-masterclass)" },
    { name: "Posgrado", value: byType.Posgrado, color: "var(--color-pieCharts-posgrado)" },
    { name: "Taller", value: byType.Taller, color: "var(--color-pieCharts-taller)" },
    { name: "Webinar", value: byType.Webinar, color: "var(--color-pieCharts-webinar)" },
  ];

  return (
    <div
    className="academicDbUsersPieChart--mainDiv"
      p={10}
      miw={{ base: "100%", sm: "500px" }}
    >
      <div className="academicDbUsersPieChart--titleDiv" style={{ alignSelf: "flex-start" }}>
        <IconChartArrows />
        <h3 className="AdministrativeDashboard-card-title">
          Alumnos por tipo de capacitación
        </h3>
      </div>

      <div className="academicDbUsersPieChart--contentDiv" align="start" spacing="md">

        <Stack spacing="16px" align="center">
          <PieChart
            withTooltip
            withLabels
            labelsType="percent"
            data={chartData}
          />
          <span
            style={{
              textAlign: "center",
              fontSize: "var(--fs-small)",
              color: "var(--color-text-primary)",
            }}
          >
            En porcentajes respecto al total
          </span>
        </Stack>

        <Stack spacing="16px">
          {chartData.map((item) => (
            <Group key={item.name} spacing="xs">
              <div
                style={{
                  marginLeft: "16px",
                  width: "16px",
                  height: "16px",
                  backgroundColor: item.color,
                  borderRadius: "50%",
                }}
              />
              <span style={{ fontSize: "var(--fs-small)", color: "var(--color-text-primary)" }}>
                {item.name}
              </span>
            </Group>
          ))}
        </Stack>
        <div className="academicDbUsersPieChart--totalsDiv" spacing="16px">
          <AcademicDbUsersTotals students={totalStudents} teachers={totalTeachers} />
        </div>
      </div>
    </div>
  );
};

export default AcademicDbUsersPieChart;
