import { Group, Stack, Flex, Tooltip } from '@mantine/core';
import { IconTrendingUp, IconTrendingDown } from '@tabler/icons-react';
import './AcademicDbTeachersRank.css';

const transformToPercentage = (value) => Math.round(value);

const AcademicDbTeachersRank = ({ teachers }) => {
  const { bestValue, worstValue } = teachers?.top;

  const sortedBestValue = bestValue.sort((a, b) => b.overallTeacherScore - a.overallTeacherScore);
  const sortedWorstValue = worstValue.sort((a, b) => b.overallTeacherScore - a.overallTeacherScore );

  // Determine the CSS class based on the average value
  let averageClass = '';
  if (teachers.average > 85) {
    averageClass = 'academicDbTeachersRank-averageNumber-green';
  } else if (teachers.average >= 70) {
    averageClass = 'academicDbTeachersRank-averageNumber-yellow';
  } else {
    averageClass = 'academicDbTeachersRank-averageNumber-red';
  }

  return (
    <div className="academicDbTeachersRank-mainDiv">
      <div className='academicDbTeachersRank-box'>
        <h6 className='academicDbTeachersRank-boxText'>
          Promedio general de los profesores: <span className={`academicDbTeachersRank-averageNumber ${averageClass}`}>{teachers.average}%</span>
        </h6>
      </div>
      <div className="academicDbTeachersRank-gridContainer">
        {/* Mejor rankeadas */}
        <article className="academicDbTeachersRank-card">
          <Group>
            <Group gap="0">
              <IconTrendingUp />
            </Group>
            <h3 className="academicDbTeachersRank-card-title">Mejor rankeadas</h3>
          </Group>
          <Stack className="academicDbTeachersRank-stack-wrapper">
            {sortedBestValue && sortedBestValue.length > 0 ? (
              sortedBestValue.map((item, index) => (
                <Flex
                  className="academicDbTeachersRank-card-rowItem"
                  align="start"
                  justify="space-between"
                  w="100%"
                  gap="5px"
                  key={index}
                >
                  <Tooltip
                    label={`Cantidad de valoraciones: ${item.totalReviews}`}
                    withArrow
                  >
                    <p className="academicDbTeachersRank-card-bodyText">
                      {item.teacherName}
                    </p>
                  </Tooltip>
                  <span
                    style={{
                      backgroundColor: "#4fa04fff",
                      color: "#fff",
                      fontSize: "var(--fs-tiny)",
                      padding: "2px 8px",
                      borderRadius: "var(--border-radius-md)",
                      fontWeight: "bold",
                    }}
                  >
                    {transformToPercentage(item.overallTeacherScore)}%
                  </span>
                </Flex>
              ))
            ) : (
              <p className="academicDbTeachersRank-card-bodyText">
                Aún no hay rankings de profesores
              </p>
            )}
          </Stack>
        </article>

        {/* Peor rankeadas */}
        <article className="academicDbTeachersRank-card">
          <Group>
            <Group gap="0">
              <IconTrendingDown />
            </Group>
            <h3 className="academicDbTeachersRank-card-title">Peor rankeadas</h3>
          </Group>
          <Stack className="academicDbTeachersRank-stack-wrapper">
            {sortedWorstValue && sortedWorstValue.length > 0 ? (
              sortedWorstValue.map((item, index) => (
                <Flex
                  className="academicDbTeachersRank-card-rowItem"
                  justify="space-between"
                  align="start"
                  w="100%"
                  gap="5px"
                  key={index}
                >
                  <Tooltip
                    label={`Cantidad de valoraciones: ${item.totalReviews}`}
                    withArrow
                  >
                    <p className="academicDbTeachersRank-card-bodyText">
                      {item.teacherName}
                    </p>
                  </Tooltip>
                  <span
                    style={{
                      backgroundColor: "#a04f4fff",
                      color: "#fff",
                      fontSize: "var(--fs-tiny)",
                      padding: "2px 8px",
                      borderRadius: "var(--border-radius-md)",
                      fontWeight: "bold",
                    }}
                  >
                    {transformToPercentage(item.overallTeacherScore)}%
                  </span>
                </Flex>
              ))
            ) : (
              <p className="academicDbTeachersRank-card-bodyText">
                Aún no hay rankings de profesores
              </p>
            )}
          </Stack>
        </article>
      </div>
    </div>
  );
};

export default AcademicDbTeachersRank;
