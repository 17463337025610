import React, {useContext, useEffect, useState} from "react";
import "./SalesAcaUnitPriceContainer.css";
import axios from "axios";
import AuthContext from "../../../../contexts/AuthContext";
import DeleteConfirmationModal from "../../../../components/DeleteConfirmationModal/DeleteConfirmationModal";
import TanStackTable from "../../../../components/Table/TanStackTable/TanStackTable";
import {createColumnHelper} from "@tanstack/react-table";
import ContentCreator from "../../../../components/ContentCreator/ContentCreator";
import showNotification from "../../../../utils/showNotification";
import {ERROR_HANDLER} from "../../../../utils/error-handler";
import SalesCardPriceInfo from "../../../../components/SalesCardPriceInfo/SalesCardPriceInfo";
import SalesAcademicUnitPriceForm from "../SalesAcademicUnitPriceForm/SalesAcademicUnitPriceForm";
import {convertDate} from "../../../../utils/convertDate";
import moment from "moment";
import filterFunctions from "../../../../utils/filterFunctions";
import {currencyFormat} from "../../../../utils/currencyFormat";

const editAcademicUnitPrice = async (values, config) => {

    const jsonData = {
        data: {
            suscriptionPlans: values.comercialInformation.subscriptionPlans,
            priceArs: values.comercialInformation.price.ars,
            priceUsd: values.comercialInformation.price.usd,
        },
    };

    return axios.put(
        `${process.env.REACT_APP_BACKEND_SERVER}admin/ventas/acaUnits/${values._id}`,
        jsonData,
        config
    );
};

function SalesAcaUnitPriceContainer() {
    const {authToken} = useContext(AuthContext);
    const [acaUnitPriceData, setAcaUnitPriceData] = useState(null);
    const [displayForm, setDisplayForm] = useState(false);
    const [initialValuesEdit, setInitialValuesEdit] = useState(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [updateData, setUpdateData] = useState(false);

    // COLUMN DEFINITIONS
    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor("comercialInformation.initialDate", {
            cell: (info) => {
                const dateValue = new Date(info.getValue());
                return <div className="salesPayment-cell-center">
                    {moment(dateValue).format("DD/MM/YYYY")}
                </div>;
            },
            header: "Fecha de inicio",
            size: 248,
            enableColumnFilter: true,
            enableSorting: false,
            filter: "date",
            filterFn: filterFunctions.dateRangeFilter,
        }),
        columnHelper.accessor("comercialInformation.name", {
            cell: (info) => info.getValue(),
            header: "Nombre",
            size: 304,
            enableColumnFilter: true,
            enableSorting: false,
            filter: "text",
            filterFn: (row, id, filterValue) => {
                if (!filterValue) return true;
                return row.original.comercialInformation.name.toLowerCase().includes(filterValue.toLowerCase());
            },
        }),
        columnHelper.accessor("comercialInformation.price.usd", {
            cell: (info) => {
                return (
                    <span className="AdministrativeExpensesView__cell--center">
                    ${currencyFormat(info.getValue())}
                </span>
                )
            },
            header: "USD",
            enableColumnFilter: false,
            enableSorting: true,
            size: 104,
        }),
        columnHelper.accessor("comercialInformation.price.ars", {
            cell: (info) => {
                return (
                    <span className="AdministrativeExpensesView__cell--center">
                    ${currencyFormat(info.getValue())}
                </span>
                )
            },
            header: "ARS",
            enableColumnFilter: false,
            enableSorting: true,
            size: 104,
        }),
        columnHelper.accessor("comercialInformation.subscriptionPlans", {
            cell: (info) => {
                const subscriptionPlans = info.getValue();
                const hasSubscription = subscriptionPlans && subscriptionPlans.length > 0;
                return hasSubscription ? "SI" : "NO";
            },
            header: "Cuotas",
            enableColumnFilter: true,
            enableSorting: false,
            filter: "select",
            filterOptions: [
                {value: 'SI', label: 'SI'},
                {value: 'NO', label: 'NO'},
            ],
            size: 88,
            filterFn: (row, id, filterValue) => {
                if (!filterValue) return true;
                return row.original.comercialInformation.subscriptionPlans.length > 0 ? filterValue === "SI" : filterValue === "NO";
            }
        }),
        columnHelper.accessor("type", {
            cell: (info) => info.getValue(),
            header: "Tipo de curso",
            enableColumnFilter: true,
            enableSorting: false,
            filter: "select",
            size: 156,
            filterOptions: [
                {value: "Curso", label: "Curso"},
                {value: "Masterclass", label: "Masterclass"},
                {value: "Diplomado", label: "Diplomado"},
                {value: "Posgrado", label: "Posgrado"},
                {value: "Webinar", label: "Webinar"},
                {value: "Taller", label: "Taller"},
            ],
            filterFn: filterFunctions.selectFilter,
        }),
    ];

    // TABLE FUNCTIONS
    const editAcaUnitPrice = (rowData) => {
        if (rowData) {
            setInitialValuesEdit({
                _id: rowData._id,
                comercialInformation: {
                    price: {
                        usd: rowData.comercialInformation.price.usd,
                        ars: rowData.comercialInformation.price.ars,
                    },
                    initialDate: rowData.comercialInformation.initialDate,
                    subscriptionPlans: rowData.comercialInformation.subscriptionPlans,
                    image: rowData.comercialInformation.image,
                    name: rowData.comercialInformation.name,
                    classQuantity: rowData.comercialInformation.classQuantity,
                },
                type: rowData.type,
            });
            setDisplayForm(true);
        }
    };


    const handleSubmit = async (values) => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authToken,
            },
        };

        try {
            setLoading(true);

            await editAcademicUnitPrice(values, config);

            showNotification({
                color: "green",
                status: "success",
                title: "¡Excelente! Operación exitosa.",
                message: `El precio de ${values.comercialInformation.name} ha sido editado con éxito.`,
            });

            setUpdateData(!updateData);
        } catch (error) {
            showNotification({
                color: "red",
                status: "error",
                title: "Operación fallida.",
                message: `No se pudo editar el precio. Detalle: ${
                    ERROR_HANDLER[error.request?.status] || ERROR_HANDLER.defaultError
                }`,
                autoClose: 12000,
            });
        } finally {
            setLoading(false);
            setDisplayForm(false);
            setInitialValuesEdit(null);
        }
    };

    // DATA INITIALIZATION
    const init = async () => {
        const url = `${process.env.REACT_APP_BACKEND_SERVER}admin/ventas/acaUnits`;
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`,
            },
        };
        const acaUnitPriceResponse = await axios.get(url, config);
        const sortedData = acaUnitPriceResponse.data
            .map((item) => {
                const convertedDate = convertDate(item.comercialInformation.initialDate, "date");
                if (convertedDate === null) return null;

                return {
                    ...item,
                    comercialInformation: {
                        ...item.comercialInformation,
                        initialDate: convertedDate,
                    }
                };
            })
            .filter((item) => item !== null)
            .sort((a, b) => new Date(b.comercialInformation.initialDate) - new Date(a.comercialInformation.initialDate));
        setAcaUnitPriceData(sortedData);
    };

    useEffect(() => {
        init();
    }, [updateData]);

    return (
        <div className="acaUnitPrice__container">
            {displayForm && (
                <ContentCreator
                    title={"Editar precios"}
                    submitFunc={(values) => handleSubmit(values)}
                    onCancel={() => {
                        setDisplayForm(false);
                        setInitialValuesEdit(null);
                    }}
                    initialValues={initialValuesEdit ?? null}
                    renderPreview={(_form) => (
                        <SalesCardPriceInfo
                            id={_form.form.values._id}
                            usd={_form.form.values.comercialInformation.price.usd}
                            ars={_form.form.values.comercialInformation.price.ars}
                            init={moment(_form.form.values.comercialInformation.initialDate).format("DD/MM/YYYY")}
                            image={_form.form.values.comercialInformation.image}
                            name={_form.form.values.comercialInformation.name}
                            classQuantity={_form.form.values.comercialInformation.classQuantity}
                            type={_form.form.values.type}
                        />
                    )}
                    renderForm={(_form) => (
                        <SalesAcademicUnitPriceForm form={_form.form} loading={loading}/>
                    )}
                />
            )}
            {acaUnitPriceData && (
                <TanStackTable
                    loading={loading}
                    data={acaUnitPriceData}
                    columns={columns}
                    editFunc={editAcaUnitPrice}
                    displayForm={displayForm}
                />
            )}

            <DeleteConfirmationModal
                opened={deleteModalOpen}
                loading={loading}
                onClose={() => {
                    setDeleteModalOpen(false);
                }}
                onCancel={() => {
                    setDeleteModalOpen(false);
                }}
            />
        </div>
    );
}

export default SalesAcaUnitPriceContainer