import React, {useContext, useEffect, useState} from "react";
import AuthContext from "../../../contexts/AuthContext";
import axios from "axios";
import showNotification from "../../../utils/showNotification";
import {ERROR_HANDLER} from "../../../utils/error-handler";
import {createColumnHelper} from "@tanstack/react-table";
import {currencyFormat} from "../../../utils/currencyFormat";

const updateStudent = async (values, axiosConfig) => {
    const {_id: userId} = values
    const putURL = `${process.env.REACT_APP_BACKEND_SERVER}admin/administracion/disable/${userId}`

    return axios.put(
        putURL,
        {},
        axiosConfig
    )
}

const updateStaffOrTeacher = async (values, axiosConfig) => {
    const {_id: userId} = values
    const putURL = `${process.env.REACT_APP_BACKEND_SERVER}admin/administracion/userFee`

    return axios.put(
        putURL,
        {
            data: {
                userId: userId,
                lessonFee: values.lessonFee,
                feeCurrency: values.feeCurrency
            }
        },
        axiosConfig
    )
}


export default function useAdministrativeUsers() {
    const {authToken} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false)
    const [displayForm, setDisplayForm] = useState(null);
    const [initialValuesEdit, setInitialValuesEdit] = useState(null);
    const [users, setUsers] = useState(null)
    const [hasToRender, setHasToRender] = useState(false)
    const [studentData, setStudentData] = useState();

    const axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + authToken,
        }
    }

    useEffect(() => {
        const getUsers = async () => {
            try {
                setIsLoading(true)
                const url = `${process.env.REACT_APP_BACKEND_SERVER}admin/administracion/getUsers`
                const urlStaff = `${process.env.REACT_APP_BACKEND_SERVER}admin/administracion/getStaff`
                const {data} = await axios.get(url, axiosConfig)
                const {data: staffData} = await axios.get(urlStaff, axiosConfig)
                setUsers({
                    students: data,
                    staff: staffData.staff,
                    teachers: staffData.teachers
                })
            } catch (error) {
                showNotification({
                    color: "red",
                    status: "error",
                    title: "Error al cargar los usuarios.",
                    message: `Detalle: ${ERROR_HANDLER[error.request?.status] || ERROR_HANDLER.defaultError}`,
                    autoClose: 12000
                });
            } finally {
                setIsLoading(false)
            }
        }
        getUsers()
    }, [authToken, hasToRender]);

    const columnHelper = createColumnHelper();
    const columnsStudents = [
        columnHelper.accessor("name", {
            cell: (info) => {
                const name = `${info.row.original.name} ${info.row.original.lastname}`;
                return (
                    <span
                        className="salesPayment-studentData-span"
                        onClick={() => setStudentData({
                            name: info.row.original.name,
                            lastname: info.row.original.lastname,
                            email: info.row.original.email,
                            phone: info.row.original.phone
                        })}>
                        {name}
                    </span>
                );
            },
            header: "Nombre",
            size: 200,
            filter: "text",
            enableSorting: false,
            enableColumnFilter: true,
            filterFn: (row, id, filterValue) => {
                if (!filterValue) return true;
                return row.original.name.toLowerCase().includes(filterValue.toLowerCase()) ||
                    row.original.lastname.toLowerCase().includes(filterValue.toLowerCase());
            }
        }),
        columnHelper.accessor('email', {
            cell: info => info.getValue(),
            header: 'Email',
            enableColumnFilter: true,
            enableSorting: false,
            filter: "text",
            filterFn: "includesString",
        }),
        columnHelper.accessor('phone', {
            cell: info => info.getValue(),
            header: 'Teléfono',
            enableColumnFilter: true,
            enableSorting: false,
            size: 104
        }),
        columnHelper.accessor('dni', {
            cell: info => info.getValue(),
            header: 'DNI',
            enableColumnFilter: true,
            enableSorting: false,
            filterFn: "includesString",
            size: 104
        }),
        columnHelper.accessor('academicUnits', {
            cell: info => {
                return info.getValue()?.map(item => (
                    item.comercialInformation.name
                )).join(', ')
            },
            header: 'Unidades Académicas',
            enableColumnFilter: true,
            enableSorting: false,
            filter: "text",
            filterFn: (row, id, filterValue) => {
                if (!filterValue) return true;
                return row.original.academicUnits
                    .map(item => item.comercialInformation.name.toLowerCase())
                    .join(', ').includes(filterValue.toLowerCase());
            },
            size: 240
        }),
    ];
    const columnsStaff = [
        ...columnsStudents,
        columnHelper.accessor('staffInfo.lessonFee', {
            cell: info => {
                if (!info.getValue()) return null;
                return `$${currencyFormat(info.getValue())}`
            },
            header: 'Tarifa de Clase',
            enableColumnFilter: false,
            enableSorting: true,
            size: 176
        }),
        columnHelper.accessor('staffInfo.feeCurrency', {
            cell: info => info.getValue(),
            header: 'Moneda',
            enableColumnFilter: true,
            enableSorting: false,
            filter: "select",
            size: 104,
            filterOptions: [
                {value: 'ARS', label: 'ARS'},
                {value: 'USD', label: 'USD'},
            ],
            filterFn: (row, id, filterValue) => {
                if (!filterValue) return true;
                return row.original.staffInfo?.feeCurrency?.toLowerCase() === filterValue.toLowerCase();
            }
        }),
    ];
    const columnsTeachers = [
        ...columnsStudents,
        columnHelper.accessor('teacherInfo.lessonFee', {
            cell: info => {
                if (!info.getValue()) return null;
                return `$${currencyFormat(info.getValue())}`
            },
            header: 'Tarifa de Clase',
            enableColumnFilter: false,
            enableSorting: true,
            size: 180
        }),
        columnHelper.accessor('teacherInfo.feeCurrency', {
            cell: info => info.getValue(),
            header: 'Moneda',
            enableColumnFilter: true,
            enableSorting: false,
            filter: "select",
            size: 104,
            filterOptions: [
                {value: 'ARS', label: 'ARS'},
                {value: 'USD', label: 'USD'},
            ],
            filterFn: (row, id, filterValue) => {
                if (!filterValue) return true;
                return row.original.teacherInfo?.feeCurrency?.toLowerCase() === filterValue.toLowerCase();
            }
        }),
    ];

    async function handleSubmit(values) {
        try {
            setIsLoading(true)
            if (!values) return null

            if (values.type === "student") {
                await updateStudent(values, axiosConfig)
            } else {
                await updateStaffOrTeacher(values, axiosConfig)
            }

            showNotification({
                color: "green",
                status: "success",
                title: "Operación realizada con éxito.",
                message: `Detalle: El usuario ha sido modificado correctamente.`,
            });

            setDisplayForm(null)
            setInitialValuesEdit(null)
            setHasToRender(!hasToRender)
        } catch (error) {
            console.error(error)
            showNotification({
                color: "red",
                status: "error",
                title: "Error al intentar habilitar/inhabilitar usuario.",
                message: `Detalle: ${ERROR_HANDLER[error.request?.status] || ERROR_HANDLER.defaultError}`,
                autoClose: 12000
            });
        } finally {
            setIsLoading(false)
        }
    }

    // falta completar
    const editFunc = (rowData) => {
        if (rowData) {
            setInitialValuesEdit({
                ...rowData,
                lessonFee: rowData.staffInfo?.lessonFee || rowData.teacherInfo?.lessonFee,
                feeCurrency: rowData.staffInfo?.feeCurrency || rowData.teacherInfo?.feeCurrency
            })
            setDisplayForm(rowData.type)
        }
    }

    // no require
    const formValidation = {}

    return {
        data: users,
        columnsStudents,
        columnsStaff,
        columnsTeachers,
        isLoading,
        displayForm,
        setDisplayForm,
        initialValuesEdit,
        setInitialValuesEdit,
        handleSubmit,
        editFunc,
        formValidation,
        studentModalData: studentData,
        setStudentModalData: setStudentData
    }
}
