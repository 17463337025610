import React, {useContext, useEffect, useState} from "react";
import "./ModerationClasses.css";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext/AuthContext";
import TanStackTable from "../../../components/Table/TanStackTable/TanStackTable";
import {createColumnHelper} from "@tanstack/react-table";
import ContentCreator from "../../../components/ContentCreator/ContentCreator";
import showNotification from "../../../utils/showNotification";
import {ERROR_HANDLER} from "../../../utils/error-handler";
import {DatePickerInput} from "@mantine/dates";
import {
    Button,
    Checkbox,
    Loader,
    Modal,
    MultiSelect,
    Select,
    TextInput,
} from "@mantine/core";
import AppContext from "../../../contexts/AppContext/AppContext";
import LessonPreview from "./LessonPreview/LessonPreview";
import LessonForm from "./LessonForm/LessonForm";
import {IconAlertHexagonFilled, IconSchoolOff} from "@tabler/icons-react";
import {useIsMount} from "../../../hooks/useIsMount";
import moment from "moment";
import filterFunctions from "../../../utils/filterFunctions";

const editLesson = async (values, config) => {
    // Method coupled with editLesson on EditLessonFormWrapper.js
    const jsonData = {
        item: {_id: values._id},
        fields: {
            ...values,
            name: values.name.toUpperCase(),
        },
    };
    return axios.put(
        `${process.env.REACT_APP_BACKEND_SERVER}admin/moderation/updateLesson`,
        jsonData,
        config
    );
};

function ModerationClasses() {
    const {authToken} = useContext(AuthContext);
    const {staffUsers} = useContext(AppContext)
    const [moderationTableData, setModerationTableData] = useState(null);
    const [displayForm, setDisplayForm] = useState(false);
    const [initialValuesEdit, setInitialValuesEdit] = useState(null);
    const [selectedModerationEventId, setSelectedModerationEventId] = useState(null);
    const [fetchingData, setFetchingData] = useState(false);
    const {academicUnits, teachersUsers} = useContext(AppContext);
    const [filters, setFilters] = useState({
        dateRangeValue: [],
        acaUnitSelected: [],
        teachersData: []
    });
    const [updateData, setUpdateData] = useState(false);

    const switcherInitialValues = {
        zoomCode: '',
        moderator: '',
        teachers: [],
        createNotification: false
    }
    const [switcherState, setSwitcherState] = useState(switcherInitialValues)

    const isMount = useIsMount();

    const MAP_STATE_INFO = {
        Open: {text: 'ABIERTA', className: 'lesson-state-tag--open'},
        Closed: {text: 'CERRADA', className: 'lesson-state-tag--closed'}
    }

    // COLUMN DEFINITIONS
    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor("initDate", {
            cell: (info) => {
                const dateValue = new Date(info.getValue());
                return moment(dateValue).format("DD/MM/YYYY");
            },
            header: <span style={{fontSize: 'var(--fs-tiny)'}}>Fecha</span>,
            sortingFn: "datetime",
            size: 248,
            enableColumnFilter: true,
            enableSorting: false,
            filter: "date",
            filterFn: filterFunctions.dateRangeFilter,
        }),
        columnHelper.accessor("name", {
            cell: (info) => info.getValue(),
            header: <span style={{fontSize: 'var(--fs-tiny)'}}>Tema</span>,
            enableGlobalFilter: true,
            enableSorting: false,
            size: 250
        }),
        columnHelper.accessor("state", {
            cell: (info) => (
                <div className="moderationClasses-cell-center">
                    <span className={MAP_STATE_INFO[info.getValue()].className}>
                        {MAP_STATE_INFO[info.getValue()].text}
                    </span>
                </div>),
            header: <span style={{fontSize: 'var(--fs-tiny)'}}>Estado</span>,
            enableColumnFilter: true,
            enableSorting: false,
            filter: "select",
            filterOptions: [
                {value: 'Open', label: 'Abierta'},
                {value: 'Closed', label: 'Cerrada'},
            ],
            filterFn: filterFunctions.selectFilter,
            size: 96
        }),
        columnHelper.accessor("moderator", {
            cell: (info) => {
                const moderatorId = info.getValue();
                return findById(staffUsers, moderatorId);
            },
            header: <span style={{fontSize: 'var(--fs-tiny)'}}>Moderador</span>,
            enableColumnFilter: true,
            enableSorting: false,
            filter: "select",
            size: 120,
        }),
        columnHelper.accessor("teachers", {
            cell: (info) => {
                const teachersMap = info.getValue().map((teacher) => {
                    return `${teacher.name} ${teacher.lastname}`;
                });
                return teachersMap.join(", ");
            },
            header: <span style={{fontSize: 'var(--fs-tiny)'}}>Profesor</span>,
            enableColumnFilter: true,
            enableSorting: false,
            filter: "select",
            size: 140,
        }),
        columnHelper.accessor("academicUnits", {
            cell: (info) => {
                const value = info.getValue();
                return value.join(", ");
            },
            header: <span style={{fontSize: 'var(--fs-tiny)'}}>Unidades Académicas</span>,
            enableColumnFilter: true,
            enableSorting: false,
            size: 200
        }),
        columnHelper.accessor("roomUrl", {
            cell: (info) => info.getValue(),
            header: <span style={{fontSize: 'var(--fs-tiny)'}}>Zoom</span>,
            enableColumnFilter: true,
            enableSorting: false,
            filter: "select",
            size: 376,
        }),
    ];

    // TABLE FUNCTIONS
    const closeClassAction = (rowData) => {
        const stateValue = rowData.classState === "Open";
        if (!stateValue) return null;
        return (
            <IconSchoolOff
                className="moderationClasses-icon"
                size={28}
                onClick={() => handleSwitchChange(rowData, stateValue)}
            />
        )
    }

    const findById = (_staffUsers, id) => {
        const _moderator = _staffUsers.find((_mod) => _mod.value === id);
        return _moderator ? _moderator.label : id;
    };

    const handleSwitchChange = (_row, stateValue) => {
        if (stateValue === true) {
            const {_id, moderator, teachers} = _row
            setSwitcherState(prevState => ({
                ...prevState,
                open: true,
                moderator,
                teachers: teachers.map(item => {
                    return item._id
                })
            }));
            setSelectedModerationEventId(_id);
        } else setSwitcherState(switcherInitialValues);
    };

    const handleConfirmModal = async (_id) => {
        const {zoomCode, moderator, teachers, createNotification} = switcherState

        try {

            const jsonData = {
                lessonId: _id,
                zoomLink: zoomCode,
                moderator,
                teachers,
                createNotification
            }

            await axios.put(
                `${process.env.REACT_APP_BACKEND_SERVER}admin/moderation/closeLesson`,
                {
                    data: jsonData
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            showNotification({
                color: "green",
                status: "success",
                title: "¡Excelente! Clase cerrada con exito.",
                message: `Ahora en el campus se mostrará el link con la grabación de zoom que subiste.`,
            });

        } catch (error) {
            console.error("Error fetching data:", error);
            showNotification({
                color: "red",
                status: "error",
                title: "¡Ups! Parece que hubo un error al cerrar la clase.",
                message: `Porfavor comunícate con soporte para mas información.`,
            });
        } finally {
            setUpdateData(!updateData);
            setSwitcherState(switcherInitialValues);
        }
    };

    const editModerationEvents = (rowData) => {
        if (rowData) {
            setInitialValuesEdit({
                _id: rowData._id,
                moderator: rowData.moderator,
                name: rowData.name,
                initDate: new Date(rowData.initDate),
                teachers: rowData.teachers.map((teacher) => teacher._id),
                state: rowData.state,
                roomUrl: rowData.roomUrl,
                materials: rowData.materials,
                sendNotification: false
            });
            setDisplayForm(true);
        }
    };

    const handleSubmit = async (values) => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authToken,
            },
        };

        try {
            await editLesson(values, config);

            showNotification({
                color: "green",
                status: "success",
                title: "¡Excelente! Operación exitosa.",
                message: `El evento ${values.name} ha sido creado / editado con éxito.`,
            });

            setUpdateData(!updateData);
            setDisplayForm(false);
        } catch (error) {
            showNotification({
                color: "red",
                status: "error",
                title: "Operación fallida.",
                message: `No se pudo crear / editar el evento. Detalle: ${ERROR_HANDLER[error.request?.status] || ERROR_HANDLER.defaultError
                }`,
                autoClose: 12000,
            });
        }
    };

    // DATA INITIALIZATION & HANDLING
    const handleFilterChange = (filterName, value) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterName]: value
        }));
    };

    const init = async () => {
        // Reset data
        setFetchingData(true);
        setModerationTableData(null);

        // Set filters
        const filteredAcaUnits = filters.acaUnitSelected.filter(Boolean);
        const filteredTeachers = filters.teachersData.filter(Boolean);
        const [beforeDate, afterDate] = filters.dateRangeValue.map(date => date ? date.toISOString() : null);

        const filteredValues = {
            acaUnits: filteredAcaUnits,
            moderators: [], // TODO: Implementar
            teachers: filteredTeachers,
            beforeDate,
            afterDate
        };

        // Fetch data
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_SERVER}admin/moderation/allLessons`,
                {...filteredValues},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            setModerationTableData(response.data.map(item => ({
                ...item,
                classState: item.state
            })).sort((a, b) => new Date(a.initDate) - new Date(b.initDate)));
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setFetchingData(false);
        }
    };

    useEffect(() => {
        if (!isMount) {
            init();
        }
    }, [updateData]);

    return (
        <div className="moderationContainer__container">
            {displayForm && (
                <ContentCreator
                    title={"Editar clase"}
                    submitFunc={(values) => handleSubmit(values)}
                    onCancel={() => {
                        setDisplayForm(false);
                        setInitialValuesEdit(null);
                    }}
                    initialValues={
                        initialValuesEdit ?? null
                    }
                    renderPreview={(_form) => (
                        <LessonPreview
                            form={_form.form}
                        />
                    )}
                    renderForm={(_form) => (
                        <LessonForm
                            form={_form.form}
                        />
                    )}
                />
            )}
            {!displayForm && (
                <div className="moderationContainer-filtersContainer">
                    <DatePickerInput
                        classNames={{root: "moderationContainer-DatePicker-root"}}
                        type="range"
                        label="Selecciona un rango de fechas"
                        value={filters.dateRangeValue}
                        onChange={(value) => handleFilterChange('dateRangeValue', value)}
                    />
                    <MultiSelect
                        classNames={{root: "moderationContainer-MultiSelect-root"}}
                        label="Selecciona alguna Unidad Académica"
                        data={academicUnits}
                        searchable
                        onChange={(value) => handleFilterChange('acaUnitSelected', value)}
                    />
                    <MultiSelect
                        classNames={{root: "moderationContainer-MultiSelect-root"}}
                        label="Selecciona algún profesor"
                        placeholder="Nombre"
                        searchable
                        data={teachersUsers}
                        onChange={(value) => handleFilterChange('teachersData', value)}
                    />
                    <Button
                        onClick={() => init()}
                        disabled={fetchingData}
                        fw="normal" style={{marginTop: "20px"}}
                    >
                        Buscar
                    </Button>
                </div>
            )}

            {fetchingData && <Loader/>}
            {moderationTableData && (
                <TanStackTable
                    data={moderationTableData}
                    columns={columns}
                    displayForm={displayForm}
                    editFunc={editModerationEvents}
                    actionsFunc={(rowData) => closeClassAction(rowData)}
                />
            )}

            {switcherState && (
                <Modal
                    classNames={{title: "moderationContainer-modalTitle"}}
                    title="¡Atención!"
                    opened={switcherState?.open}
                    onClose={() => {
                        setSwitcherState(switcherInitialValues)
                    }}
                >
                    <div className="moderationContainer-father-div">
                        <div className="moderationContainer-warning-container">
                            <IconAlertHexagonFilled className="moderationContainer-alertIcon"/>
                            <p className="moderationContainer-modalText">
                                Estás por cerrar esta clase, asegurate de estar seguro del
                                siguiente cambio. De proceder, es irreversible.
                            </p>
                        </div>

                        <TextInput
                            label="Ingresá aquí el link de la grabacion de zoom:"
                            value={switcherState.zoomCode}
                            error={(!switcherState.zoomCode || !switcherState.zoomCode.startsWith('https')) && 'El link de zoom de comenzar con "https"'}
                            onChange={(event) => setSwitcherState(prevState => ({
                                ...prevState,
                                zoomCode: event.target.value
                            }))}
                        />
                        < MultiSelect
                            value={switcherState?.teachers}
                            label='Profesor'
                            data={teachersUsers}
                            error={switcherState.teachers.length === 0 && 'Debes agregar al menos a un profesor'}
                            onChange={(value) => setSwitcherState(prevState => ({...prevState, teachers: value}))}
                        />

                        < Select
                            defaultValue={switcherState.moderator}
                            label='Moderador'
                            allowDeselect={false}
                            data={staffUsers}
                            onChange={(_value, option) => setSwitcherState(prevState => ({
                                ...prevState,
                                moderator: option.value
                            }))}
                        />

                        < Checkbox
                            label='Notificar este cambio'
                            value={switcherState.createNotification}
                            onChange={({target}) => setSwitcherState(prevState => ({
                                ...prevState,
                                createNotification: target.checked
                            }))}
                        />

                        <Button
                            onClick={() =>
                                handleConfirmModal(selectedModerationEventId)
                            }
                            disabled={
                                !switcherState.zoomCode
                                || !switcherState.zoomCode.startsWith('https')
                                || !switcherState.moderator
                                || switcherState.teachers.length === 0}
                        >
                            Enviar
                        </Button>
                    </div>
                </Modal>
            )}
        </div>
    );
}

export default ModerationClasses;
