import * as yup from 'yup';

const CommercialInformationModel = () => {
   const initialValues = {
      type: "",
      state: "Closed",
      name: "",
      description: "",
      initialDate: "", //Ej: 7 de Febrero 2024 [String]
      endDate: "", //Ej: 7 de Febrero 2024 [String]
      initBreak: null,
      endBreak: null,
      classQuantity: 1,
      image: "", //URL
      classDays: "", //Ej: Lunes y Jueves [String]
      classHour: "", //Ej: 19:00 [String]
      closeCampusDate: "", //Ej: 7 de Febrero 2024 [String]
   }

   const validationSchema = yup.object().shape({
      type: yup.string().required("El tipo es obligatorio"),
      name: yup.string().required("El nombre es obligatorio"),
      description: yup.string().required("La descripción es obligatoria"),
      initialDate: yup.date().required("La fecha inicial es obligatoria"),
      endDate: yup.date().required("La fecha final es obligatoria")
         .min(yup.ref('initialDate'), 'La fecha final debe ser posterior a la inicial'),
      classQuantity: yup.number().required("La cantidad de clases es obligatoria").min(1, "La cantidad mínima de clases es 1"),
      image: yup.string().url("La imagen debe ser una URL válida").required("La imagen es obligatoria"),
      classDays: yup.string().required("Los días de clase son obligatorios"),
      classHour: yup
         .string()
         .required("La hora de clase es obligatoria")
         .matches(
            /^(?:[01]?[0-9]|2[0-3]):([0-5]?[0-9])$/,
            "La hora debe estar en formato HH:mm (00:00 a 23:59)"
         ),
      closeCampusDate: yup.date().required("La fecha de cierre de campus es obligatoria"),
   });

   return { initialValues, validationSchema }
}

export { CommercialInformationModel };
