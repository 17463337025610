import React, { useContext, useEffect, useState } from 'react';
import { createColumnHelper } from "@tanstack/react-table";
import TanStackTable from "../../../components/Table/TanStackTable/TanStackTable";
import axios from "axios";
import ContentCreator from "../../../components/ContentCreator/ContentCreator";
import AppContext from "../../../contexts/AppContext";
import AuthContext from "../../../contexts/AuthContext";
import DiscountsForm from "../DiscountsForm/DiscountsForm";
import { Badge, TextInput } from "@mantine/core";
import { IconCopy } from "@tabler/icons-react";
import { handleCopy } from "../../../utils/handleCopy";
import "./SalesDiscounts.css";
import showNotification from "../../../utils/showNotification";
import { ERROR_HANDLER } from "../../../utils/error-handler";

function SalesDiscounts() {
   const { authToken } = useContext(AuthContext);
   const { academicUnits, avals } = useContext(AppContext);

   const [discountsData, setDiscountsData] = useState(null);
   const [initialValuesEdit, setInitialValuesEdit] = useState(null);
   const [displayForm, setDisplayForm] = useState(false);
   const [updateData, setUpdateData] = useState(false);
   const [loading, setLoading] = useState(false);

   const axiosConfig = {
      headers: {
         "Content-Type": "application/json",
         Authorization: `Bearer ${authToken}`
      }
   };

   const validation = {
      institutional_name: (value) => {
         if (!value) return 'El campo de la institucion no puede estar vacío.'
      },
      percentage: (value) => {
         if (!value) return 'Ingresa un número que indique el descuento.'
         if (value > 100) return 'El descuento no puede ser mayor al 100%.'
      },
      code: (value) => {
         if (!value) return 'El nombre del código no puede estar vacío.'
         if (value.length > 100) return 'El código no puede superar los 100 caracteres'
      },
   }

   const createEvent = async (values, config) => {
      let jsonData
      if (values?.isAdvancedForm) {
         const { isAdvancedForm, ...data } = values
         jsonData = {
            data
         }
      } else {
         const { percentage, code, academicUnit, institutional_name } = values
         jsonData ={ data: {  percentage, code, academicUnit, institutional_name }}
      }

      return axios.post(
         `${process.env.REACT_APP_BACKEND_SERVER}admin/ventas/createDiscount`,
         jsonData,
         config
      );
   };

   const editEvent = async (values, config) => {
      let jsonData
      
      const {
         institution,
         max_uses,
         isAdvancedForm,
         ...data } = {
         ...values,
         institutional_name: values.institutional_name || values.institution,
      };
      jsonData = {
         item: { _id: values.id },
         field: { ...data }
      };
      
      return axios.put(
         `${process.env.REACT_APP_BACKEND_SERVER}admin/ventas/updateDiscount`,
         jsonData,
         config
      );
   };

   // COLUMN DEFINITIONS
   const columnHelper = createColumnHelper();
   const columns = [
      columnHelper.accessor('active', {
         cell: info => {
            const value = info.getValue()
            const responseValue = value ? "Sí" : "No"
            return responseValue === "Sí" ? (
               <div className='salesDiscounts__badgeDiv'><Badge classNames={{ root: "salesDiscounts__badgeRoot__green" }}>{responseValue}</Badge></div>
            ) : (
               <div className='salesDiscounts__badgeDiv'><Badge classNames={{ root: "salesDiscounts__badgeRoot__red" }}>{responseValue}</Badge></div>
            );
         },
         header: 'Activo',
         enableColumnFilter: false,
         enableSorting: true,
      }),
      columnHelper.accessor('code', {
         cell: info => info.getValue(),
         header: 'Código',
         enableColumnFilter: true,
         enableSorting: false,
         filter: 'text',
      }),
      columnHelper.accessor('academicUnitName', {
         cell: info => info.getValue(),
         header: 'Unidad Académica',
         enableColumnFilter: true,
         enableSorting: false,
         filter: 'text',
      }),
      columnHelper.accessor('', {
         cell: info => {
            const { institutional_name, institution } = info.row.original;
            const value = institutional_name || institution || info.getValue();
            const findInstitution = avals.find(aval => aval.value === value);
            return findInstitution ? findInstitution.label : value;
         },
         header: 'Institución',
         enableColumnFilter: true,
         enableSorting: false,
         filter: 'text',
      }),
      columnHelper.accessor('percentage', {
         cell: info => info.getValue(),
         header: 'Porcentaje',
         enableColumnFilter: false,
         enableSorting: true,
      }),
      columnHelper.accessor('current_uses', {
         cell: info => {
            return info.getValue().length
         },
         header: 'Veces usado',
         enableColumnFilter: false,
         enableSorting: true,
      }),
      columnHelper.accessor('max_uses', {
         cell: info => {
            const value = info.getValue();
            const showValue = value === "100000" ? "Sin límite" : value;
            return showValue
         },
         header: 'Usos máximos',
         enableColumnFilter: false,
         enableSorting: true,
      }),
      columnHelper.accessor('other_discount', {
         cell: info => {
            return info.getValue();
         },
         header: 'Otro',
         enableColumnFilter: true,
         enableSorting: false,
      }),
   ];

   // TABLE FUNCTIONS
   const handleSubmit = async (values) => {
      try {
         setLoading(true);
         if (initialValuesEdit) {
            await editEvent(values, axiosConfig);
         } else {
            await createEvent(values, axiosConfig);
         }

         showNotification({
            color: "green",
            status: "success",
            title: "¡Excelente! Operación exitosa.",
            message: `El descuento ${values.code} ha sido creado / editado con éxito.`,
         });
         setUpdateData(!updateData);
         setDisplayForm(false);
      } catch (err) {
         showNotification({
            color: "red",
            status: "error",
            title: "Operación fallida.",
            message: `No se pudo crear / editar el descuento. Detalle: ${err.message || ERROR_HANDLER[err.request?.status] || ERROR_HANDLER.defaultError
               }`,
            autoClose: 12000,
         });
      } finally {
         setLoading(false);
         setInitialValuesEdit(null);
      }
   };

   const handleEditDiscount = (rowData) => {
      if (rowData) {
         const academicUnitId = academicUnits.find(unit => unit.label === rowData.academicUnitName)?.value;
         const initialValues = {
            ...rowData,
            academicUnit: academicUnitId ?? "",
            institutional_name: rowData.institutional_name || rowData.institution,
            max_uses: rowData.max_uses
         };
         setInitialValuesEdit(initialValues);
         setDisplayForm(true);
      }
   };

   // DATA INITIALIZATION
   const init = async () => {
      // Academic Units
      const academicUnitsData = academicUnits.map((unit) => {
         const splitName = unit.label.split(" ");
         const academicUnitCode = splitName.map(_word => _word.charAt(0)).join("").toUpperCase();
         return {
            id: unit._id,
            name: unit.label,
            code: academicUnitCode,
         };
      });

      const url = `${process.env.REACT_APP_BACKEND_SERVER}admin/ventas/discounts`;

      // Discounts
      const discountsInfo = await axios.get(url, axiosConfig);
      const discountData = discountsInfo.data.map((discount) => {
         const academicUnitData = academicUnitsData.find(unit => unit.code === discount.code.split("-")[1]);
         return {
            id: discount._id,
            code: discount.code,
            percentage: discount.percentage,
            active: discount.active,
            institutional_name: discount.institutional_name,
            institution: discount.code.split("-")[0],
            academicUnitName: academicUnitData?.name || "",
            max_uses: discount.use.max_uses,
            current_uses: discount.use.current_uses,
            other_discount: discount?.other_discount
         };
      });

      setDiscountsData(discountData);
   };

   useEffect(() => {
      init();
   }, [updateData]);

   return (
      <div className="discounts__container">
         {displayForm && (
            <ContentCreator
               validation={validation}
               title={initialValuesEdit ? "Editar descuento" : "Crear descuento"}
               submitFunc={(values) => handleSubmit(values)}
               onCancel={() => {
                  setDisplayForm(false);
                  setInitialValuesEdit(null);
               }}
               initialValues={initialValuesEdit ?? null}
               renderPreview={(_form) => (
                  <div className="salesDiscounts__preview">
                     <TextInput
                        {..._form.form.getInputProps('code')}
                        style={{ width: "50%" }}
                        label="Código de descuento"
                        disabled
                     />
                     <IconCopy className="salesDiscounts__preview__icon"
                        style={
                           _form.form.values.code
                              ? {}
                              : { pointerEvents: "none" }
                        }
                        onClick={() => handleCopy(_form.form.values.code)} />
                  </div>
               )}
               renderForm={(_form) => (
                  <DiscountsForm initialValuesEdit={initialValuesEdit} form={_form.form} loading={loading} />
               )}
            />
         )}
         {discountsData && (
            <TanStackTable
               data={discountsData}
               columns={columns}
               editFunc={handleEditDiscount}
               displayForm={displayForm}
               addButtonFunc={() => {
                  setDisplayForm(!displayForm);
                  if (displayForm) setInitialValuesEdit(null);
               }}
            />
         )}
      </div>
   );
}

export default SalesDiscounts;
