import React, { useEffect, useState } from 'react';
import './AcademicDbUsersTotals.css';

const AcademicDbUsersTotals = ({ students, teachers }) => {

  const Count = ({ number, duration }) => {
    const [count, setCount] = useState("0");

    useEffect(() => {
      let start = 0;
      const end = parseInt(number);
      if (start === end) return;

      let totalMilSecDur = parseInt(duration);
      let incrementTime = (totalMilSecDur / end) * 1000;

      let timer = setInterval(() => {
        start += 1;
        setCount(String(start));
        if (start === end) clearInterval(timer);
      }, incrementTime);
    }, [number, duration]);

    return <span className='academicDbUsersTotal-countNumber'>{count}</span>;
  };

  return (
    <div className='academicDbUsersTotal-mainDiv'>
      <div className='academicDbUsersTotal-box'>
        <h6 className='academicDbUsersTotal-boxText'>Total de estudiantes:</h6>
        <Count number={students} duration={15} />
      </div>
      <div className='academicDbUsersTotal-box'>
        <h6 className='academicDbUsersTotal-boxText'>Total de profesores:</h6>
        <Count number={teachers} duration={20} />
      </div>
    </div>
  );
};

export default AcademicDbUsersTotals;
