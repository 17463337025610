import { createColumnHelper } from "@tanstack/react-table";
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import showNotification from "../../../utils/showNotification";
import { ERROR_HANDLER } from "../../../utils/error-handler";
import { Badge, Loader, Tooltip } from "@mantine/core";
import AuthContext from "../../../contexts/AuthContext/AuthContext";
import moment from "moment";
import filterFunctions from "../../../utils/filterFunctions";
import { searchFlattenedObject } from "../../../utils/searchFlattenedObject";
import { currencyFormat } from "../../../utils/currencyFormat";
import { IconSquareCheck } from "@tabler/icons-react";
import AppContext from "../../../contexts/AppContext";

const getStateBadge = (state) => {
   let text, color;
   switch (state) {
      case "PAID":
         text = "Pagado";
         color = "rgb(158, 192, 152)"; // var(--color-badge-green)
         break;
      case "PENDING":
         text = "Pendiente";
         color = "rgb(197,189,132)"; // var(--color-badge-yellow)
         break;
      case "EXPIRED":
         text = "Vencida";
         color = "rgb(150,61,61)"; // var(--color-badge-yellow)
         break;
      default:
         text = "Error";
         color = "rgb(150,61,61)"; // var(--color-badge-red)
         break;
   }
   return <div className="salesPayment__badge__container">
      <Badge color={color}>{text}</Badge>
   </div>
}

const chargeTypes = {
   'manual': ['TRANSFERENCIA'],
   'automatic': ['PAYPAL', 'PAGOS360'],
}

const getChargeType = (type) => {
   if (chargeTypes.automatic.includes(type.toUpperCase())) return 'AUTOMÁTICO';
   // Add more types here
   return 'MANUAL';
}

export default function useSalesInstallments() {
   const { authToken } = useContext(AuthContext)
   const { dollarValue } = useContext(AppContext);
   const [data, setData] = useState()
   const [isLoading, setIsLoading] = useState(false)
   const [isSwitchLoading, setIsSwitchLoading] = useState(null)
   const [reFetch, setReFetch] = useState(false)
   const [studentData, setStudentData] = useState(null);
   const [displayForm, setDisplayForm] = useState(false);
   const [initialValuesEdit, setInitialValuesEdit] = useState(null);

   const firstDayOfThisMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
   const currentDay = new Date();

   const [dateFilter, setDateFilter] = useState([firstDayOfThisMonth, currentDay]);


   const axiosConfig = {
      headers: {
         "Content-Type": "application/json",
         Authorization: `Bearer ${authToken}`,
      },
   }

   async function getSalesInstallments() {
      try {
         setIsLoading(true)
         const getURL = `${process.env.REACT_APP_BACKEND_SERVER}admin/administracion/installmentTable?initDate=${dateFilter[0]}&endDate=${dateFilter[1]}`
         // const getURL = `${process.env.REACT_APP_BACKEND_SERVER}admin/administracion/installmentTable`
         const { data: response } = await axios.get(getURL, axiosConfig)

         const mappedResponse = response.map((item) => {
            const isPaid = getIfPayed(item.installments_state);
            const dateToCompare = new Date(item.installments_date);

            if (!isPaid && dateToCompare < currentDay) {
               item.installments_state = 'EXPIRED'
            }
            return item
         });

         // Sort by date
         mappedResponse.sort((a, b) => {
            return new Date(a.installments_date) - new Date(b.installments_date)
         })
         setData(mappedResponse)

      } catch (error) {
         showNotification({
            color: "red",
            status: "error",
            title: "Operación fallida.",
            message:
               `No se pudo obtener los cobros. 
            Detalle: ${error.message || ERROR_HANDLER[error.request?.status] || ERROR_HANDLER.defaultError}`,
            autoClose: 12000,
         });
      } finally {
         setIsLoading(false)
      }
   }

   useEffect(() => {
      getSalesInstallments()
   }, [reFetch])

   useEffect(() => {
      if (dateFilter[0] && dateFilter[1])
         getSalesInstallments();
   }, [dateFilter]);

   const columnHelper = createColumnHelper();
   const columns = [
      columnHelper.accessor('installments_date', {
         cell: (info) => {
            const dateValue = new Date(info.getValue());
            return <div className="salesPayment-cell-center">
               {moment(dateValue).format("DD/MM/YYYY")}
            </div>;
         },
         header: 'Fecha a cobrar',
         size: 248,
         enableColumnFilter: true,
         enableSorting: false,
         filter: "date",
         filterFn: filterFunctions.dateRangeFilter,
      }),
      columnHelper.accessor('installments_state', {
         cell: (info) => {
            return getStateBadge(info.getValue());
         },
         header: 'Estado',
         size: 120,
         enableColumnFilter: true,
         enableSorting: false,
         filter: "select",
         filterOptions: [
            { value: 'PAID', label: 'Pagada' },
            { value: 'PENDING', label: 'Pendiente' },
            { value: 'EXPIRED', label: 'Vencida' }
         ],
         filterFn: filterFunctions.selectFilter,
         tooltip: "Indica si la cuota fue pagada o no.",
      }),
      columnHelper.accessor('dummyValue', {
         cell: info => {
            return <span className="salesPayment-studentData-span"
               onClick={() => setStudentData({
                  name: info.row.original.payer_name,
                  lastname: info.row.original.payer_lastname,
                  email: info.row.original.payer_email,
                  phone: info.row.original.payer_phone,
               })}
            >
               {`${info.row.original.payer_name} ${info.row.original.payer_lastname}`}
            </span>
         },
         header: 'Cliente',
         size: 176,
         filter: "text",
         enableSorting: false,
         enableColumnFilter: true,
         filterFn: (row, id, filterValue) => {
            if (!filterValue) return true;
            return searchFlattenedObject(row.original, filterValue)
         },
      }),
      columnHelper.accessor('installments_cycle', {
         cell: (info) => `${info.row.original.installment_cycle}/${info.row.original.installments_total_cycles}`,
         header: 'Cuota',
         enableColumnFilter: false,
         enableSorting: false,
         size: 64,
      }),
      columnHelper.accessor('installments_agreedAmount', {
         cell: (info) => {
            let { currency, installment_currency, agreedAmount, installments_total_cycles, installments_agreedAmount } = info.row.original;
            let value = info.getValue();

            // Fix for old data. USD was the default currency
            if (!installment_currency) {
               // Validate if the installment amount is in same currency as payment by checking amounts
               const installmentCheckValue = agreedAmount / installments_total_cycles;
               if (installmentCheckValue === installments_agreedAmount) {
                  // If the amounts are the same, then the currency is the same
                  return `$${currencyFormat(value)}`;
               } else {
                  // If the amounts are different, then the currency is USD and the installment amount is in ARS
                  value *= dollarValue
                  return `$${currencyFormat(parseInt(value))}`;
               }
            }

            if (currency === 'ARS' && installment_currency === 'USD') {
               value *= dollarValue; // Convert to ARS
               // The dollar value used here is today's value, and it is the same used to update /administracion/installment/:paymentId/:installmentId
            }

            return `$${currencyFormat(value)}`;
         },
         header: 'Importe',
         enableColumnFilter: false,
         enableSorting: true,
         size: 120,
         tooltip: `Importe acordado para la cuota. Los valores en ARS están sujetos a cambios de acuerdo a la cotización del dólar del día. Referencia: $${dollarValue} ARS/USD`,
      }),
      columnHelper.accessor('currency', {
         cell: info => info.getValue(),
         header: 'Moneda',
         size: 88,
         enableColumnFilter: true,
         enableSorting: false,
         filter: "select",
         filterOptions: [
            { value: 'ARS', label: 'ARS' },
            { value: 'USD', label: 'USD' },
         ],
         filterFn: (row, id, filterValue) => {
            if (!filterValue) return true;
            return row.original.currency.toLowerCase() === filterValue.toLowerCase();
         }
      }),
      columnHelper.accessor('platform', {
         cell: (info) => {
            return <div className="salesPayment-cell-center">
               {getChargeType(info.getValue())}
            </div>;
         },
         header: 'Tipo cobro',
         enableColumnFilter: true,
         enableSorting: false,
         size: 112,
         filter: "select",
         filterOptions: [
            { value: 'manual', label: 'MANUAL' },
            { value: 'automatic', label: 'AUTOMÁTICO' },
         ],
         filterFn: (row, id, filterValue) => {
            if (!filterValue) return true;
            return (chargeTypes[filterValue].includes(row.original.platform.toUpperCase()))
         },
      }),
   ]

   // TABLE FUNCTIONS
   const putInstallments = async (values) => {
      const putURL = `${process.env.REACT_APP_BACKEND_SERVER}admin/administracion/installments/${values._id}`
      const jsonData = { data: values }
      return axios.put(putURL, jsonData, axiosConfig)
   }

   const editInstallments = (rowData) => {
      if (rowData) {
         setInitialValuesEdit(rowData)
         setDisplayForm(true)
      }
   }

   const handleSubmitForm = async (values) => {
      try {
         setIsLoading(true)

         await putInstallments(values)

         showNotification({
            color: "green",
            status: "success",
            title: "Cuotas actualizadas correctamente.",
            message: `Detalle: las cuotas se han guardado correctamente.`,
         });

         setReFetch(!reFetch)
         setDisplayForm(false)
      } catch (err) {
         showNotification({
            color: "red",
            status: "error",
            title: "Error al editar cuotas.",
            message: `Detalle: ${ERROR_HANDLER[err.request?.status] || ERROR_HANDLER.defaultError}`,
            autoClose: 12000
         });
      } finally {
         setIsLoading(false)
      }
   }


   const editPaymentStateAction = (rowData) => {
      const isActionInProgress = isSwitchLoading === rowData.installment_id;
      const isPayed = getIfPayed(rowData.installments_state);

      if (isActionInProgress)
         return <Loader size={20} />;

      if (isPayed)
         return null;

      return (
         <Tooltip label="Marcar como pagado" color="rgba(54,54,54,1)">
            <IconSquareCheck
               className="salesPayment-confirmation-icon"
               onClick={() => onChangeStateInstallmentsSwitch(rowData)}
            />
         </Tooltip>
      );
   };

   const getIfPayed = (value) => {
      switch (value?.toLowerCase()) {
         case 'pending':
            return false;
         case 'success':
            return true;
         case 'paid':
            return true;
         default:
            return null;
      }
   }

   const onChangeStateInstallmentsSwitch = async (_installment) => {
      const installmentId = _installment.installment_id;
      const paymentId = _installment._id

      try {
         setIsSwitchLoading(installmentId);

         const putURL = `${process.env.REACT_APP_BACKEND_SERVER}admin/administracion/installment/${paymentId}/${installmentId}`
         await axios.put(
            putURL,
            { data: { dollarValue: dollarValue } },
            axiosConfig);

         showNotification({
            color: "green",
            status: "success",
            title: "¡Operación exitosa!",
            message: "El estado del cobro ha sido modificado.",
            autoClose: 5000,
         });

         setReFetch(!reFetch);

      } catch (error) {
         showNotification({
            color: "red",
            status: "error",
            title: "Operación fallida.",
            message: `No se pudo actualizar el estado del cobro. Detalle: ${error.message || ERROR_HANDLER[error.request?.status] || ERROR_HANDLER.defaultError
               }`,
            autoClose: 12000,
         });
      } finally {
         setIsSwitchLoading(null);
      }
   }

   return {
      data,
      columns,
      isLoading,
      studentData,
      setStudentData,
      editInstallments,
      displayForm,
      setDisplayForm,
      initialValuesEdit,
      setInitialValuesEdit,
      editPaymentStateAction,
      handleSubmitForm,
      setDateFilter,
      dateFilter
   }
}
