import { Skeleton, Stack, Group, Paper } from '@mantine/core';
import { IconChartArrows } from '@tabler/icons-react';

const AcademicDbUsersPieChartSkeleton = () => {
  return (
    <div className="academicDbUsersPieChart--mainDiv">
      <div className="academicDbUsersPieChart--titleDiv">
        <IconChartArrows />
        <h3 className="AdministrativeDashboard-card-title">
          <Skeleton height={24} width="60%" />
        </h3>
      </div>

      <div className="academicDbUsersPieChart--contentDiv">
        <Stack spacing="16px" align="center">
          <Skeleton height={200} width={200} circle />
          <span style={{ textAlign: "center", fontSize: "var(--fs-small)" }}>
            <Skeleton height={20} width="60%" />
          </span>
        </Stack>

        <div className="academicDbUsersPieChart--totalsDiv">
          <Stack spacing="16px" align="center">
            <Skeleton height={24} width="80%" />
            <Skeleton height={24} width="80%" />
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default AcademicDbUsersPieChartSkeleton;
