import { Flex, Group, Stack } from "@mantine/core";
import { IconTrendingUp } from "@tabler/icons-react";
import './AcademicDbRankByType.css';

const AcademicDbRankByType = ({ data }) => {
  const transformToPercentage = (value) => Math.round(value * 100);

  const sortedKeys = Object.keys(data).sort((a, b) => data[b] - data[a]);

  return (
    <>
      <Group>
        <Group gap="0">
          <IconTrendingUp />
        </Group>
        <h3 className="academicDbRankByType-card-title">Ranking por tipo de U.A</h3>
      </Group>
      <Stack className="academicDbRankByType-stack-wrapper">
        {sortedKeys.length > 0 ? (
          sortedKeys.map((key) => (
            <Flex
              className="academicDbRankByType-card-rowItem"
              justify="space-between"
              align="start"
              w="100%"
              gap="5px"
              key={key}
            >
              <p className="academicDbRankByType-card-bodyText">{key}</p>
              <span
                style={{
                  backgroundColor: '#4fa04fff',
                  color: '#fff',
                  fontSize: 'var(--fs-tiny)',
                  padding: '2px 8px',
                  borderRadius: 'var(--border-radius-md)',
                  fontWeight: 'bold',
                }}
              >
                {transformToPercentage(data[key])}%
              </span>
            </Flex>
          ))
        ) : (
          <p className="academicDbRankByType-card-bodyText">Aún no hay rankings disponibles</p>
        )}
      </Stack>
    </>
  );
};

export default AcademicDbRankByType;
