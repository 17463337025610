import "./AcademicDbExams.css";
import AcademicDbExamsByType from "../../../../components/AcademicDashboardComponents/AcademicDbExamsByType/AcademicDbExamsByType";
import { AcademicDbExamsGrades } from "../../../../components/AcademicDashboardComponents/AcademicDbExamsGrades/AcademicDbExamsGrades";

export function AcademicDbExams({ data }) {
  const { averageByType, graduates } = data;

  return (
    <div className="academicDbExams-mainDiv">
      <h3 className="academicDbExams-ranks-header">Exámenes</h3>
      <div>
      <AcademicDbExamsByType averageByType={averageByType} />
      </div>
      <div>
      <AcademicDbExamsGrades graduates={graduates} />
      </div>
    </div>
  );
}
