export function isBase64(str, allowedTypes = null) {
   if (typeof str !== "string") return false;

   const DEFAULT_ALLOWED_TYPES = [
      "image/jpeg", "image/jpg", "image/png",
      "video/mp4", "video/webm", "video/ogg", "video/mov", "video/avi", "video/mkv",
      "application/pdf"
   ];

   const typesToCheck = allowedTypes || DEFAULT_ALLOWED_TYPES;

   return typesToCheck.some(type => str.startsWith(`data:${type};base64,`));
}
