import React, { useContext, useEffect, useState } from 'react';
import "./MktgCalendarContainer.css";
import axios from "axios";
import AuthContext from "../../../../contexts/AuthContext";
import MktgCalendarForm from "../MktgCalendarForm/MktgCalendarForm";
import DeleteConfirmationModal from "../../../../components/DeleteConfirmationModal/DeleteConfirmationModal";
import TanStackTable from "../../../../components/Table/TanStackTable/TanStackTable";
import { createColumnHelper } from "@tanstack/react-table";
import ContentCreator from "../../../../components/ContentCreator/ContentCreator";
import moment from "moment";
import CalendarItem from "../../../../components/CalendarItem/CalendarItem";
import showNotification from "../../../../utils/showNotification";
import { ERROR_HANDLER } from "../../../../utils/error-handler";

const mapEventsType = {
    lesson: 'Clase',
    exam: 'Examen',
    recoveryExam: 'Recuperatorio',
    break: 'Receso',
    general: 'General'
}

const createEvent = async (values, config) => {
    const jsonData = {
        data: {
            ...values,
            name: values.name.toUpperCase()
        }
    }
    return axios.post(
        `${process.env.REACT_APP_BACKEND_SERVER}admin/marketing/schedule`,
        jsonData,
        config
    );
}

const editEvent = async (values, config) => {
    const jsonData = {
        item: { _id: values._id },
        field: {
            ...values,
            name: values.name.toUpperCase()
        }
    }
    return axios.put(
        `${process.env.REACT_APP_BACKEND_SERVER}admin/marketing/schedule`,
        jsonData,
        config
    );
}

function MktgCalendarContainer() {
    const { authToken } = useContext(AuthContext);
    const [calendarEventsData, setCalendarEventsData] = useState(null);
    const [displayForm, setDisplayForm] = useState(false);
    const [initialValuesEdit, setInitialValuesEdit] = useState(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedCalendarEventId, setSelectedCalendarEventId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [updateData, setUpdateData] = useState(false);

    // COLUMN DEFINITIONS
    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor('initDate', {
            cell: info => {
                const dateValue = new Date(info.getValue());
                return  moment(dateValue).format("DD/MM/YYYY");
            },
            header: 'Fecha de evento',
            enableColumnFilter: false,
            enableSorting: true,
            sortingFn: "datetime",
            size: 64
        }),
        columnHelper.accessor('name', {
            cell: info => info.getValue(),
            header: 'Nombre',
            enableColumnFilter: true,
            enableSorting: false,
            filter: 'text',
            filterFn: 'includesString',
        }),
        columnHelper.accessor('type', {
            cell: info => mapEventsType[info.getValue()],
            header: 'Tipo de evento',
            enableColumnFilter: true,
            enableSorting: false,
            filter: 'select',
            size: 64
        }),
    ];

    // TABLE FUNCTIONS
    const editCalendarEvent = (rowData) => {
        if (rowData) {
            setInitialValuesEdit({
                _id: rowData._id,
                type: rowData.type,
                name: rowData.name,
                initDate: rowData.initDate,
            })
            setDisplayForm(true)
        }
    }

    const deleteCalendarEvent = async (idCalendarEvent = null) => {
        if (deleteModalOpen) {
            try {
                setLoading(true);
                await axios.delete(
                    `${process.env.REACT_APP_BACKEND_SERVER}admin/marketing/schedule`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + authToken,
                        },
                        data: { _id: selectedCalendarEventId },
                    }
                );
                setUpdateData(!updateData);
                showNotification({
                    color: "green",
                    status: "success",
                    title: "¡Operación exitosa!",
                    message: "El elemento seleccionado ha sido correctamente eliminado.",
                    autoClose: 12000,
                });
            } catch (error) {
                console.error(error);
                showNotification({
                    color: "red",
                    status: "error",
                    title: "Operación fallida.",
                    message: `No se pudo eliminar la notificación. Detalle: ${
                        ERROR_HANDLER[error.request?.status] || ERROR_HANDLER.defaultError
                    }`,
                    autoClose: 12000,
                });
            } finally {
                setDeleteModalOpen(false);
                setLoading(false);
            }
        } else {
            setSelectedCalendarEventId(idCalendarEvent);
            setDeleteModalOpen(true)
        }
    };

    const handleSubmit = async (values) => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authToken,
            }
        }

        try {
            setLoading(true);
            // Check if it's an edit or a new event
            if (initialValuesEdit) {
                await editEvent(values, config)
            } else {
                await createEvent(values, config)
            }

            showNotification({
                color: 'green',
                status: 'success',
                title: '¡Excelente! Operación exitosa.',
                message: `El evento ${values.name} ha sido creado / editado con éxito.`,
            });

            setUpdateData(!updateData);
        } catch (error) {
            showNotification({
                color: "red",
                status: "error",
                title: "Operación fallida.",
                message: `No se pudo crear / editar el evento. Detalle: ${ERROR_HANDLER[error.request?.status] || ERROR_HANDLER.defaultError}`,
                autoClose: 12000
            });
        } finally {
            setLoading(false);
            setDisplayForm(false);
        }
    }

    // DATA INITIALIZATION
    const init = async () => {
        const url = `${process.env.REACT_APP_BACKEND_SERVER}admin/marketing/schedule?type=general`
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
            },
        }
        // Fetch schedule filtered data
        const scheduleResponse = await axios.get(url, config)
        setCalendarEventsData(scheduleResponse.data)
    }

    useEffect(() => {
        init()
    }, [updateData]);

    return (
        <div className="mktgCalendar__container">
            {displayForm && (
                <ContentCreator
                    title={initialValuesEdit ? "Editar evento" : "Crear evento"}
                    submitFunc={(values) => handleSubmit(values)}
                    onCancel={() => {
                        setDisplayForm(false)
                        setInitialValuesEdit(null)
                    }}
                    initialValues={initialValuesEdit ?? {
                        type: "general",
                        name: "",
                        initDate: moment().toDate(),
                    }}
                    renderPreview={(_form) => (
                        <CalendarItem
                            events={[
                                {
                                    "type": _form.form.values.type,
                                    "name": _form.form.values.name.toUpperCase(),
                                    "initDate": moment(_form.form.values.initDate).format(),
                                },
                            ]}
                            month={moment(_form.form.values.initDate).format("MMMM")}
                        />
                    )}
                    renderForm={(_form) => (
                        <MktgCalendarForm form={_form.form} loading={loading} />
                    )}
                />
            )}
            {calendarEventsData && (
                <TanStackTable
                    data={calendarEventsData}
                    columns={columns}
                    editFunc={editCalendarEvent}
                    onDelete={deleteCalendarEvent}
                    displayForm={displayForm}
                    addButtonFunc={() => {
                        setDisplayForm(!displayForm)
                        if (displayForm) setInitialValuesEdit(null)
                    }}
                />
            )}

            <DeleteConfirmationModal
                opened={deleteModalOpen}
                loading={loading}
                onClose={() => {
                    setDeleteModalOpen(false)
                }}
                onCancel={() => {
                    setDeleteModalOpen(false)
                }}
                onConfirm={() => deleteCalendarEvent(selectedCalendarEventId)}
            />
        </div>
    );
}

export default MktgCalendarContainer;
