// import { showNotification } from "@mantine/notifications"

// const ALLOWED_FILES_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf']
// const MAX_SIZE_IN_BYTES = 5 * 1024 * 1024 // 5 MB

// export const fileToBase64 = async (file) => {
//     if (file?.size > MAX_SIZE_IN_BYTES) {
//         showNotification({
//             color: "red",
//             status: "error",
//             title: "Ha ocurrido un problema al cargar el archivo.",
//             message: `Detalle: el archivo es demasiado grande. Prueba con otro más pequeño.`
//         })
//         return null;
//     }

//     if (!ALLOWED_FILES_TYPES.includes(file?.type)) {
//         showNotification({
//             color: "red",
//             status: "error",
//             title: "Ha ocurrido un problema al cargar el archivo.",
//             message: `El archivo debe ser en formato JPG, JPEG, PNG o PDF.`
//         })
//         return null;
//     }

//     const base64File = await new Promise((resolve, reject) => {
//         const reader = new FileReader();
//         reader.onloadend = () => {
//             resolve(reader.result);
//         };
//         reader.onerror = reject;
//         reader.readAsDataURL(file);
//     });

//     return base64File;
// }


import { showNotification } from "@mantine/notifications";

const ALLOWED_IMAGE_TYPES = ["image/jpeg", "image/jpg", "image/png"];
const ALLOWED_VIDEO_TYPES = ["video/mp4", "video/webm", "video/ogg", "video/mov", "video/avi", "video/mkv"];
const ALLOWED_PDF_TYPE = ["application/pdf"];

const MAX_IMAGE_SIZE = 5 * 1024 * 1024; // 5 MB
const MAX_VIDEO_SIZE = 50 * 1024 * 1024; // 50 MB

export const fileToBase64 = async (file) => {
   if (!file) return null;

   const { type, size } = file;

   // Validación de tipo y tamaño según el formato
   if (ALLOWED_IMAGE_TYPES.includes(type) && size > MAX_IMAGE_SIZE) {
      showNotification({
         color: "red",
         status: "error",
         title: "Error al cargar la imagen.",
         message: `El archivo es demasiado grande (máx: 5MB).`
      });
      return null;
   }

   if (ALLOWED_VIDEO_TYPES.includes(type) && size > MAX_VIDEO_SIZE) {
      showNotification({
         color: "red",
         status: "error",
         title: "Error al cargar el video.",
         message: `El archivo es demasiado grande (máx: 50MB).`
      });
      return null;
   }

   if (![...ALLOWED_IMAGE_TYPES, ...ALLOWED_VIDEO_TYPES, ...ALLOWED_PDF_TYPE].includes(type)) {
      showNotification({
         color: "red",
         status: "error",
         title: "Formato de archivo no permitido.",
         message: `Solo se aceptan imágenes (JPG, PNG), videos (MP4, WEBM) y PDFs.`
      });
      return null;
   }

   try {
      const base64File = await new Promise((resolve, reject) => {
         const reader = new FileReader();
         reader.onloadend = () => resolve(reader.result);
         reader.onerror = () => reject("Error al leer el archivo.");
         reader.readAsDataURL(file);
      });

      return base64File;
   } catch (error) {
      showNotification({
         color: "red",
         status: "error",
         title: "Error al convertir el archivo.",
         message: error,
      });
      return null;
   }
};
