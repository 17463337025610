import AcademicDbUsersPieChart from "../../../../components/AcademicDashboardComponents/AcademicDbUsersPieChart/AcademicDbUsersPieChart";
import "./AcademicDbUsers.css";

const AcademicDbUsers = ({ data }) => {
  return (
    <>
      <div className="AcademicDbUsers-mainDiv">
        <h3 className="AcademicDbUsers-ranks-header">Usuarios</h3>
        <AcademicDbUsersPieChart users={data} />
      </div>
    </>
  );
};
export default AcademicDbUsers;
