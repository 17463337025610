import './AcademicHome.css'
import { IconMicroscope, IconSchool, IconStars, IconUser } from '@tabler/icons-react'
import React from 'react'
import ViewCard from '../../../components/ViewCard/ViewCard'
import AcademicDbView from '../AcademicDashboard/AcademicDbView/AcademicDbView'

// add here
const ACADEMIC_ITEMS = [
   {
      title: 'Unidades Académicas',
      text: 'Crear capacitaciones, diplomados, cursos, clases.',
      href: 'unidades-academicas',
      icon: < IconSchool size={50} className='ViewCard--icon' color='var(--color-white)' />,
   },
   {
      title: 'Usuarios',
      text: 'Ver, crear o editar usuarios .',
      href: 'usuarios',
      icon: < IconUser size={50} className='ViewCard--icon' color='var(--color-white)' />,
   },
   {
      title: 'Especialidades',
      text: 'Generar nuevas especialidades o editarlas',
      href: 'especialidades',
      icon: < IconMicroscope size={50} className='ViewCard--icon' color='var(--color-white)' />,
   },
   {
      title: 'Valoraciones',
      text: 'Revisar las valoraciones de las clases',
      href: 'valoraciones',
      icon: < IconStars size={50} className='ViewCard--icon' color='var(--color-white)' />,
   },
]

export default function AcademicHome() {
   return (
      <>
    <div className='AcademicHome-grid-container'>
         {ACADEMIC_ITEMS.map(item => (
            < ViewCard item={item} />
        ))}
     </div>
     <div>
      <AcademicDbView/>
     </div>
     </>
   )
}