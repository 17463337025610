import React, { useContext, useEffect, useState } from "react";
import "./AdminBankAccountContainer.css";
import axios from "axios";
import AuthContext from "../../../../contexts/AuthContext";
import DeleteConfirmationModal from "../../../../components/DeleteConfirmationModal/DeleteConfirmationModal";
import TanStackTable from "../../../../components/Table/TanStackTable/TanStackTable";
import { createColumnHelper } from "@tanstack/react-table";
import ContentCreator from "../../../../components/ContentCreator/ContentCreator";
import showNotification from "../../../../utils/showNotification";
import { ERROR_HANDLER } from "../../../../utils/error-handler";
import BankAccount from "../../../../components/BankAccount/BankAccount";
import AdminBankAccountForm from "../AdminBankAccountForm/AdminBankAccountForm";


const createEvent = async (values, config) => {
  const jsonData = {
    data: {
      ...values,
    },
  };
  return axios.post(
    `${process.env.REACT_APP_BACKEND_SERVER}admin/administracion/bankaccounts`,
    jsonData,
    config
  );
};
const editEvent = async (values, config) => {
  const jsonData = {
    item: {_id: values.id},
    field: {
      ...values,
    },
  };
  return axios.put(
    `${process.env.REACT_APP_BACKEND_SERVER}admin/administracion/bankaccounts`,
    jsonData,
    config
  );
};

function AdminBankAccountContainer() {
  const { authToken } = useContext(AuthContext);
  const [adminBankAccountData, setAdminBankAccountData] = useState(null);
  const [displayForm, setDisplayForm] = useState(false);
  const [initialValuesEdit, setInitialValuesEdit] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedBankAccountId, setSelectedBankAccountId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updateData, setUpdateData] = useState(false);

  // COLUMN DEFINITIONS
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("accountAlias", {
      cell: (info) => info.getValue(),
      header: "Alias",
      enableColumnFilter: true,
      enableSorting: false,
      filter: "text",
      filterFn: "includesString",
    }),
    columnHelper.accessor("accountBank", {
      cell: (info) => info.getValue(),
      header: "Banco",
    }),
    columnHelper.accessor("accountCBU", {
      cell: (info) => info.getValue(),
      header: "CBU",
    }),
    columnHelper.accessor("accountCUIT", {
      cell: (info) => info.getValue(),
      header: "CUIT",
    }),
    columnHelper.accessor("accountHolder", {
      cell: (info) => info.getValue(),
      header: "Titular",
    }),
    columnHelper.accessor("accountNumber", {
      cell: (info) => info.getValue(),
      header: "Nº de cuenta USD",
    }),
    columnHelper.accessor("is_visible", {
      cell: (info) => {
        const value = info.getValue()
        return value ? "Habilitada" : "Deshabilitada";
    },
      header: "Estado",
    }),
  ];

  // TABLE FUNCTIONS
  const editBankAccountEvent = (rowData) => {
    if (rowData) {
      setInitialValuesEdit({
        id: rowData._id,
        accountAlias: rowData.accountAlias,
        accountBank: rowData.accountBank,
        accountCBU: rowData.accountCBU,
        accountCUIT: rowData.accountCUIT,
        accountHolder: rowData.accountHolder,
        accountType: rowData.accountType,
        accountNumber: rowData.accountNumber,
        is_visible: rowData.is_visible,
      });
      setDisplayForm(true);
    }
  };

  const deleteBankAccountEvent = async (idBankAccount = null) => {
    if (deleteModalOpen) {
      try {
        setLoading(true);
        await axios.delete(
          `${process.env.REACT_APP_BACKEND_SERVER}admin/administracion/bankaccounts`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + authToken,
            },
            data: { _id: selectedBankAccountId._id },
          }
        );
        setUpdateData(!updateData);
        showNotification({
          color: "green",
          status: "success",
          title: "¡Operación exitosa!",
          message: "El elemento seleccionado ha sido correctamente eliminado.",
          autoClose: 12000,
        });
      } catch (error) {
        console.error(error);
        showNotification({
          color: "red",
          status: "error",
          title: "Operación fallida.",
          message: `No se pudo eliminar la cuenta bancaria. Detalle: ${
            ERROR_HANDLER[error.request?.status] || ERROR_HANDLER.defaultError
          }`,
          autoClose: 12000,
        });
      } finally {
        setDeleteModalOpen(false);
        setLoading(false);
      }
    } else {
      setSelectedBankAccountId(idBankAccount);
      setDeleteModalOpen(true);
    }
  };

  const handleSubmit = async (values) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      },
    };

    try {
      setLoading(true);
      // Check if it's an edit or a new event
      if (initialValuesEdit) {
        await editEvent(values, config);
      } else {
        await createEvent(values, config);
      }

      showNotification({
        color: "green",
        status: "success",
        title: "¡Excelente! Operación exitosa.",
        message: `El evento ${values.name} ha sido creado / editado con éxito.`,
      });
      setUpdateData(!updateData);
    } catch (error) {
      showNotification({
        color: "red",
        status: "error",
        title: "Operación fallida.",
        message: `No se pudo crear / editar el evento. Detalle: ${
          ERROR_HANDLER[error.request?.status] || ERROR_HANDLER.defaultError
        }`,
        autoClose: 12000,
      });
    } finally {
      setLoading(false);
      setDisplayForm(false);
      setInitialValuesEdit(null);
    }
  };

  // DATA INITIALIZATION
  const init = async () => {
    const url = `${process.env.REACT_APP_BACKEND_SERVER}admin/administracion/bankaccounts`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const bankAccountResponse = await axios.get(url, config);
    setAdminBankAccountData(bankAccountResponse.data);
  };

  useEffect(() => {
    init();
  }, [updateData]);
  return (
    <div className="AdminBankAccountContainer__container">
      {displayForm && (
        <ContentCreator
          title={initialValuesEdit ? "Editar evento" : "Crear evento"}
          submitFunc={(values) => handleSubmit(values)}
          onCancel={() => {
            setDisplayForm(false);
            setInitialValuesEdit(null);
          }}
          initialValues={initialValuesEdit ?? null}
          renderPreview={(_form) => (
            <BankAccount
              alias={_form.form.values.accountAlias}
              bank={_form.form.values.accountBank}
              cbu={_form.form.values.accountCBU}
              cuit={_form.form.values.accountCUIT}
              owner={_form.form.values.accountHolder}
              accountType={_form.form.values.accountType}
              accountNumber={_form.form.values.accountNumber}
              formToShow={_form.form.values.formToShow}
            />
          )}
          renderForm={(_form) => (
            <AdminBankAccountForm initialValues={initialValuesEdit} form={_form.form} loading={loading} />
          )}
        />
      )}
      {adminBankAccountData && (
        <TanStackTable
          loading={loading}
          data={adminBankAccountData}
          columns={columns}
          onDelete={deleteBankAccountEvent}
          editFunc={editBankAccountEvent}
          displayForm={displayForm}
          addButtonFunc={() => {
            setDisplayForm(!displayForm);
            if (displayForm) setInitialValuesEdit(null);
          }}
        />
      )}

      <DeleteConfirmationModal
        opened={deleteModalOpen}
        loading={loading}
        onClose={() => {
          setDeleteModalOpen(false);
          setSelectedBankAccountId(null);
        }}
        onCancel={() => {
          setDeleteModalOpen(false);
          setSelectedBankAccountId(null);
        }}
        onConfirm={deleteBankAccountEvent}
      />
    </div>
  );
}

export default AdminBankAccountContainer;
