import {
  Progress,
  Box,
  Text,
  Group,
  Paper,
  SimpleGrid,
} from "@mantine/core";
import { IconDeviceAnalytics } from "@tabler/icons-react";
import "./AcademicDbExamsGrades.css";

export function AcademicDbExamsGrades({ graduates }) {
  const { approved = 0, disapproved = 0, recovery } = graduates;
  
  const totalResults = approved + disapproved;
  
  const {
    total = 0,
    approvedRecovery = 0,
    disapprovedRecovery = 0,
  } = recovery || {};

  const calculatePart = (value, total) => (total > 0 ? (value / total) * 100 : 0);

  // Define data for graduates
  const segmentsGraduates = [
    {
      label: "Aprobados",
      count: approved,
      part: calculatePart(approved, totalResults),
      color: "var(--color-badge-green)",
    },
    {
      label: "Desaprobados",
      count: disapproved,
      part: calculatePart(disapproved, totalResults),
      color: "var(--color-badge-red)",
    },
  ];

  // Define data for recovery
  const segmentsRecovery = [
    {
      label: "Aprobados Recuperación",
      count: approvedRecovery,
      part: calculatePart(approvedRecovery, total),
      color: "var(--color-badge-green)",
    },
    {
      label: "Desaprobados Recuperación",
      count: disapprovedRecovery,
      part: calculatePart(disapprovedRecovery, total),
      color: "#d9534f",
    },
  ];

  // Render descriptions for graduates
  const descriptionsGraduates = segmentsGraduates.map((segment) => (
    <Box
      key={segment.label}
      style={{ borderBottomColor: segment.color }}
      className="stat"
    >
      <Text tt="uppercase" fz="xs" c="dimmed" fw={700}>
        {segment.label}
      </Text>

      <Group justify="space-between" align="flex-end" gap={0}>
        <Text fw={700}>{segment.count}</Text>
        <Text c={segment.color} fw={700} size="sm" className="statCount">
          {Math.round(segment.part)}%
        </Text>
      </Group>
    </Box>
  ));

  // Render descriptions for recovery
  const descriptionsRecovery = segmentsRecovery.map((segment) => (
    <Box
      key={segment.label}
      style={{ borderBottomColor: segment.color }}
      className="stat"
    >
      <Text tt="uppercase" fz="xs" c="dimmed" fw={700}>
        {segment.label}
      </Text>

      <Group justify="space-between" align="flex-end" gap={0}>
        <Text fw={700}>{segment.count}</Text>
        <Text c={segment.color} fw={700} size="sm" className="statCount">
          {Math.round(segment.part)}%
        </Text>
      </Group>
    </Box>
  ));

  return (
    <>
      <Paper withBorder p="md" radius="md" mb="lg">
        <Group justify="space-between">
          <Group align="flex-end" gap="xs">
            <Text fz="xl" fw={700}>
              Examen inicial
            </Text>
          </Group>
          <IconDeviceAnalytics size="1.4rem" className="icon" stroke={1.5} />
        </Group>

        <Text c="dimmed" fz="sm">
          Total de alumnos que tomaron éxamen: {totalResults}
        </Text>

        <Progress.Root size={34} classNames={{ label: "progressLabel" }} mt={40}>
          {segmentsGraduates.map((segment) => (
            <Progress.Section
              value={segment.part}
              color={segment.color}
              key={segment.label}
            >
              {segment.part > 10 && (
                <Progress.Label>{Math.round(segment.part)}%</Progress.Label>
              )}
            </Progress.Section>
          ))}
        </Progress.Root>

        <SimpleGrid cols={{ base: 1, xs: 2 }} mt="xl">
          {descriptionsGraduates}
        </SimpleGrid>
      </Paper>

      <Paper withBorder p="md" radius="md">
        <Group align="flex-end" gap="xs">
          <Text fz="xl" fw={700}>
            Examen Recuperatorio
          </Text>
        </Group>
        <Text c="dimmed" fz="sm" mb="lg">
          Total de alumnos que recuperaron: {total}
        </Text>

        <Progress.Root size={34} classNames={{ label: "progressLabel" }}>
          {segmentsRecovery.map((segment) => (
            <Progress.Section
              value={segment.part}
              color={segment.color}
              key={segment.label}
            >
              {segment.part > 10 && (
                <Progress.Label>{Math.round(segment.part)}%</Progress.Label>
              )}
            </Progress.Section>
          ))}
        </Progress.Root>

        <SimpleGrid cols={{ base: 1, xs: 2 }} mt="xl">
          {descriptionsRecovery}
        </SimpleGrid>
      </Paper>
    </>
  );
}
