import React, { useState } from 'react';
import "./CommercialInformation.css";
import { FileInput, Grid, NumberInput, Pill, Select, Textarea, TextInput } from "@mantine/core";
import MantineDateInput from "../../../../../components/MantineDateInput/MantineDateInput";
import { IconExternalLink, IconPhoto, IconVideo } from "@tabler/icons-react";
import { renderFile, renderVideo } from "../../../../../utils/renderFile";
import useS3 from "../../../../../hooks/useS3";
import { showNotification } from "@mantine/notifications";
import { isBase64 } from "../../../../../utils/isBase64";
import { openBase64InNewTab } from "../../../../../utils/openBase64InNewTab";
import { openUrl } from "../../../../../utils/openUrl";
import MantineSwitchButton from '../../../../../components/MantineSwitchButton/MantineSwitchButton';

function CommercialInformationForm(props) {
   const { form } = props;
   const { uploadToS3 } = useS3();
   const [loading, setLoading] = useState(false);

   const handleUploadToS3 = async (_file, _name) => {
      return uploadToS3({
         image: _file,
         fileDirectory: `public/images/academicUnits`,
         fileName: `${_name}`,
         isRequired: false
      })
   }

   return (
      <Grid style={{ width: "100%" }}>
         <Grid.Col span={12}>
            <Select
               label="Tipo de la unidad académica"
               {...form.getInputProps('type')}
               data={[
                  { value: "Curso", label: "Curso" },
                  { value: "Masterclass", label: "Masterclass" },
                  { value: "Diplomado", label: "Diplomado" },
                  { value: "Posgrado", label: "Posgrado" },
                  { value: "Webinar", label: "Webinar" },
                  { value: "Taller", label: "Taller" },
               ]}
            />
         </Grid.Col>
         <Grid.Col span={12}>
            <TextInput
               label="Nombre de la unidad académica"
               {...form.getInputProps('name')}
            />
         </Grid.Col>
         <Grid.Col span={6}>
            <FileInput
               {...form.getInputProps("image")}
               label="Cargar imagen de la unidad académica"
               withAsterisk
               w={"100%"}
               description="La nueva imagen reemplazará al anterior."
               placeholder="Cargar imagen"
               leftSection={<IconPhoto size={30} />}
               disabled={(form.values.name === "") || loading}
               onChange={(file) => {
                  renderFile(file)
                     .then(async (_fileBase64) => {
                        setLoading(true);
                        const fileName = form.values.name
                           ? form.values.name.toLowerCase().replace(/ /g, '_')
                           : file.name.toLowerCase().replace(/ /g, '_');

                        await handleUploadToS3(_fileBase64, fileName).then((url) => {
                           form.setFieldValue("image", url);
                        }).catch((error) => {
                           showNotification({
                              color: 'red',
                              status: 'error',
                              title: 'Ha ocurrido un problema al cargar el archivo.',
                              autoClose: 12000,
                              message: `Detalle: ${error}`
                           });
                        }).finally(() => {
                           setLoading(false)
                        });
                     });
               }}
               accept={['image/*']}
            />
         </Grid.Col>
         {form.values.image && (
            <Grid.Col span={6}>
               <div className="SalesPaymentForm-iamgeLink-div">
                  <h6 className="SalesPaymentForm-image-header">
                     Link de la imagen
                  </h6>
                  <div className="SalesPaymentForm-pill-container">
                     <Pill>
                        {form.values.image}
                     </Pill>
                     <IconExternalLink className='materialsTable__icon' onClick={() => {
                        if (!form.values.image || form.values.image === "Sin imagen") {
                           showNotification({
                              color: "yellow",
                              status: "warning",
                              title: "La imagen no existe",
                              message: `Detalle: No se ha cargado una imagen.`,
                              autoClose: 12000
                           });
                        } else if (isBase64(form.values.image)) {
                           openBase64InNewTab(form.values.image);
                        } else {
                           openUrl(form.values.image)
                        }
                     }} />
                  </div>
               </div>
            </Grid.Col>
         )}
         <Grid.Col span={12}>
            <Textarea
               label="Descripción de la unidad académica"
               {...form.getInputProps('description')}
               autosize
               minRows={2}
            />
         </Grid.Col>
         <Grid.Col span={6}>
            <MantineDateInput
               label="Fecha de inicio"
               form={form}
               name="initialDate"
            />
         </Grid.Col>
         <Grid.Col span={6}>
            <MantineDateInput
               label="Fecha de finalización"
               form={form}
               name="endDate"
            />
         </Grid.Col>
         <Grid.Col span={6}>
            <MantineDateInput
               label="Inicio de receso"
               form={form}
               name="initBreak"
            />
         </Grid.Col>
         <Grid.Col span={6}>
            <MantineDateInput
               label="Fin de receso"
               form={form}
               name="endBreak"
            />
         </Grid.Col>
         <Grid.Col span={12}>
            <MantineDateInput
               label="Fecha de cierre de campus"
               form={form}
               name="closeCampusDate"
            />
         </Grid.Col>
         <Grid.Col span={6}>
            <TextInput
               placeholder="Lunes y Jueves"
               label="Días de clase"
               {...form.getInputProps('classDays')}
            />
         </Grid.Col>
         <Grid.Col span={6}>
            <TextInput
               styles={{
                  input: {
                     outline: !/^(?:[01]?[0-9]|2[0-3]):([0-5]?[0-9])$/.test(form.values?.classHour) ? "1px solid red" : ""
                  }
               }}
               placeholder="19:00"
               label="Hora de clase"
               {...form.getInputProps('classHour')}
            />
            {!/^(?:[01]?[0-9]|2[0-3]):([0-5]?[0-9])$/.test(form.values?.classHour) && <small>La hora debe estar en formato HH:mm (00:00 a 23:59)</small>}
         </Grid.Col>
         <Grid.Col span={12}>
            <NumberInput
               label="Cantidad de clases"
               {...form.getInputProps('classQuantity')}
               min={1}
            />
         </Grid.Col>
         <Grid.Col span={12}>
            <MantineSwitchButton
               checked={form.values.isElite}
               onChange={() =>
                  form.setFieldValue("isElite", !form.values.isElite)
               }
               label={<span>¿Es <strong>oferta elite</strong>?</span>}
            />
         </Grid.Col>
         {form.values.isElite &&
            <>
               <Grid.Col span={6}>
                  <FileInput
                     {...form.getInputProps("video")}
                     label="Cargar video de la unidad académica (opcional)"
                     w={"100%"}
                     description="El nuevo video reemplazará al anterior."
                     placeholder="Cargar video"
                     leftSection={<IconVideo size={30} />}
                     disabled={(form.values.name === "") || loading}
                     onChange={(file) => {
                        renderVideo(file)
                           .then(async (_fileBase64) => {
                              setLoading(true);
                              const fileName = form.values.name
                                 ? form.values.name.toLowerCase().replace(/ /g, '_')
                                 : file.name.toLowerCase().replace(/ /g, '_');

                              await handleUploadToS3(_fileBase64, fileName).then((url) => {
                                 form.setFieldValue("video", url);
                              }).catch((error) => {
                                 showNotification({
                                    color: 'red',
                                    status: 'error',
                                    title: 'Ha ocurrido un problema al cargar el video.',
                                    autoClose: 12000,
                                    message: `Detalle: ${error}`
                                 });
                              }).finally(() => {
                                 setLoading(false)
                              });
                           });
                     }}
                     accept={['video/mp4', 'video/webm', 'video/ogg']}
                  />
               </Grid.Col>
               {form.values.video && (
                  <Grid.Col span={6}>
                     <div className="SalesPaymentForm-iamgeLink-div">
                        <h6 className="SalesPaymentForm-image-header">
                           Link del video
                        </h6>
                        <div className="SalesPaymentForm-pill-container">
                           <Pill>
                              {form.values.video}
                           </Pill>
                           <IconExternalLink className='materialsTable__icon' onClick={() => {
                              if (!form.values.video) {
                                 showNotification({
                                    color: "yellow",
                                    status: "warning",
                                    title: "El video no existe",
                                    message: `Detalle: No se ha cargado una imagen.`,
                                    autoClose: 12000
                                 });
                              } else if (isBase64(form.values.video)) {
                                 openBase64InNewTab(form.values.video);
                              } else {
                                 openUrl(form.values.video)
                              }
                           }} />
                        </div>
                     </div>
                  </Grid.Col>
               )}
            </>
         }


      </Grid>
   );
}

export default CommercialInformationForm;