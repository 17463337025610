import axios from "axios";
import React, {useContext, useEffect, useState} from "react";
import AuthContext from "../../../contexts/AuthContext";
import {createColumnHelper} from "@tanstack/table-core";
import showNotification from "../../../utils/showNotification";
import {ERROR_HANDLER} from "../../../utils/error-handler";
import useS3 from "../../useS3";
import {Avatar} from "@mantine/core";
import {IconDownload, IconExternalLink, IconEye} from "@tabler/icons-react";
import {isValidPhoneNumber} from "react-phone-number-input";
import {isBase64} from "../../../utils/isBase64";
import {openUrl} from "../../../utils/openUrl";

const URL_USERS = `${process.env.REACT_APP_BACKEND_SERVER}admin/academico/getUsers`

export default function useAcaUsers() {
    const {authToken} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false)
    const [displayForm, setDisplayForm] = useState(false);
    const [initialValuesEdit, setInitialValuesEdit] = useState(null);
    const [data, setData] = useState(null)
    const [hasToRender, setHasToRender] = useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [studentModal, setStudentModal] = useState(null)
    const [cellModal, setCellModal] = useState(null)
    const {uploadToS3} = useS3()

    const axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        }
    }

    useEffect(() => {
        const getUsers = async () => {
            try {
                setIsLoading(true)
                const {data} = await axios.get(URL_USERS, axiosConfig)
                setData(data)
            } catch (error) {
                console.error(error)
                showNotification({
                    color: "red",
                    status: "error",
                    title: "Error al cargar los todos los profesores.",
                    message: `Detalle: ${ERROR_HANDLER[error.request?.status] || ERROR_HANDLER.defaultError}`,
                    autoClose: 12000
                });
            } finally {
                setIsLoading(false)
            }
        }
        getUsers()
    }, [authToken, hasToRender]);

    const columnHelper = createColumnHelper();
    const colTeachers = [
        columnHelper.accessor('profilePicture', {
            cell: info => < Avatar h={30} w={30} m={'auto'} src={info.getValue()} alt="foto de perfil"/>,
            header: 'Imagen',
            size: 64,
            enableColumnFilter: false,
            enableSorting: false,
        }),
        columnHelper.accessor('fullName', {
            cell: info => {
                const firstName = info.row.original.name;
                const lastName = info.row.original.lastname;
                return <span className='cell-pointer' onClick={() => setCellModal({
                    text: `${firstName} ${lastName}`,
                    title: 'Nombre completo'
                })}>{`${firstName} ${lastName}`}</span>
            },
            header: 'Nombre',
            enableColumnFilter: true,
            filter: "text",
            enableSorting: false,
            filterFn: (row, id, filterValue) => {
                if (!filterValue) return true;
                return row.original.name.toLowerCase().includes(filterValue.toLowerCase()) ||
                    row.original.lastname.toLowerCase().includes(filterValue.toLowerCase());
            },
            size: 120
        }),
        columnHelper.accessor('email', {
            cell: info => <span
                className='cell-pointer'
                onClick={() => setCellModal({text: info.getValue(), title: 'Email'})}
            >
            {info.getValue()}
         </span>,
            header: 'Email',
            enableColumnFilter: true,
            enableSorting: false,
            size: 136
        }),
        columnHelper.accessor('phone', {
            cell: info => <span
                className='cell-pointer'
                onClick={() => setCellModal({text: info.getValue(), title: 'Teléfono'})}
            >
            {info.getValue()}
         </span>,
            header: 'Teléfono',
            enableColumnFilter: true,
            enableSorting: false,
            size: 88
        }),
        columnHelper.accessor('academicUnits', {
            cell: info => {
                const academicUnits = info.getValue();
                if (academicUnits && academicUnits.length > 0) {
                    return <span
                        className="cell-pointer"
                        onClick={() => setCellModal({
                            text: academicUnits.map(unit => <p>- {unit.name}</p>), title: "Unidades académicas"
                        })}
                    >{academicUnits.map(unit => unit.name).join(', ')}</span>;
                }
                return '';
            },
            header: 'Unidad académica',
            enableColumnFilter: true,
            enableSorting: false,
        }),
        // columnHelper.accessor('academicUnitsCoDirector', {
        //     cell: info => {
        //         const academicUnitsCoDirector = info.getValue();
        //         if (academicUnitsCoDirector && academicUnitsCoDirector.length > 0) {
        //             return academicUnitsCoDirector.map(unit => unit.name).join(', ');
        //         }
        //         return '';
        //     },
        //     header: 'UA Codirector',
        //     size: 64,
        //     enableColumnFilter: true,
        //     enableSorting: false,
        // }),
        // columnHelper.accessor('academicUnitsDirector', {
        //     cell: info => {
        //         const academicUnitsDirector = info.getValue();
        //         if (academicUnitsDirector && academicUnitsDirector.length > 0) {
        //             return academicUnitsDirector.map(unit => unit.name).join(', ');
        //         }
        //         return '';
        //     },
        //     header: 'Unidad A. Director',
        //     size: 64,
        //     enableColumnFilter: true,
        //     enableSorting: false,
        // }),
        columnHelper.accessor('signature', {
            cell: info => {
                if (!info.getValue()) return null
                return (
                    <IconExternalLink
                        className="academicUnits__icon"
                        size={24}
                        onClick={() => {
                            openUrl(info.getValue());
                        }}
                    />
                )
            },
            header: 'Firma',
            size: 64,
            enableColumnFilter: false,
            enableSorting: false,
        }),
    ];

    const colStudents = [
        columnHelper.accessor('fullName', {
            cell: info => {
                const firstName = info.row.original.name;
                const lastName = info.row.original.lastname;
                return <span className='cell-pointer' onClick={() => setCellModal({
                    text: `${firstName} ${lastName}`,
                    title: 'Nombre completo'
                })}>{`${firstName} ${lastName}`}</span>
            },
            header: 'Nombre',
            enableColumnFilter: true,
            enableSorting: false,
            filterFn: (row, id, filterValue) => {
                if (!filterValue) return true;
                return row.original.name.toLowerCase().includes(filterValue.toLowerCase()) ||
                    row.original.lastname.toLowerCase().includes(filterValue.toLowerCase());
            },
            size: 120
        }),
        columnHelper.accessor('email', {
            cell: info => <span
                className='cell-pointer'
                onClick={() => setCellModal({text: info.getValue(), title: 'Email'})}
            >
            {info.getValue()}
         </span>,
            header: 'Email',
            enableColumnFilter: true,
            enableSorting: false,
            size: 136
        }),
        columnHelper.accessor('phone', {
            cell: info => info.getValue(),
            header: 'Teléfono',
            enableColumnFilter: true,
            enableSorting: false,
            size: 88
        }),
        columnHelper.accessor('academicUnits', {
            cell: info => {
                const academicUnits = info.row.original.academicUnits;
                if (academicUnits && academicUnits.length > 0) {
                    return <span
                        className="cell-pointer"
                        onClick={() => setCellModal({
                            text: academicUnits.map(unit => <p>- {unit.name}</p>), title: "Unidades académicas"
                        })}
                    >{academicUnits.map(unit => unit.name).join(', ')}</span>;
                }
                return '';
            },
            header: 'Unidades académicas',
            enableColumnFilter: true,
            enableSorting: false,
        }),
        columnHelper.accessor('certificates', {
            cell:
                info =>
                    <div
                        onClick={() => setStudentModal({
                            data: info.row.original.certificates,
                            userId: info.row.original.id,
                            title: `${info.row.original.name} ${info.row.original.lastname} - Certificados`,
                            isExams: false
                        })}
                        className="AcademicView-studentTable--iconContainer"
                    >
                        < IconDownload/>
                    </div>,
            header: 'Certificados',
            size: 64,
            enableColumnFilter: false,
            enableSorting: false,
        }),
        columnHelper.accessor('exams', {
            cell: info => {
                return <div
                    className="AcademicView-studentTable--iconContainer"
                    onClick={() => setStudentModal({
                        data: info.row.original.exams,
                        title: `${info.row.original.name} ${info.row.original.lastname} - Exámenes`,
                        isExams: true
                    })}
                >
                    < IconEye/>
                </div>
            },
            header: 'Exámenes',
            size: 64,
            enableColumnFilter: false,
            enableSorting: false,
        }),
    ];

    const postTeacher = async (values, config) => {
        const {profilePicture, name, lastname, sendMail, signature} = values
        if (sendMail !== 'true' && sendMail !== 'false') {
            return showNotification({
                color: "red",
                status: "error",
                title: "Operación fallida.",
                message: `No se pudo crear / editar el profesor. Detalle: la confimación del email solo puede ser verdadera o falsa.`,
                autoClose: 12000
            });
        }

        const profileImageURL = await uploadToS3({
            image: profilePicture,
            fileDirectory: `public/images/users`,
            fileName: `${name}-${lastname}-profilePicture`,
            isRequired: false
        })

        const signatureImageURL = await uploadToS3({
            image: signature,
            fileDirectory: `public/images/users`,
            fileName: `${name}-${lastname}-signature`,
            isRequired: false
        })

        const jsonData = {
            data: {
                ...values,
                profilePicture: profileImageURL,
                signature: signatureImageURL,
            }
        }
        // FALTA EL ENDPOINT
        return axios.post(
            `${process.env.REACT_APP_BACKEND_SERVER}admin/academico/createUser`,
            jsonData,
            config
        );
    }

    const putTeacher = async (values, config) => {
        const {profilePicture, name, lastname, signature} = values
        let profilePictureURL = profilePicture
        let signatureURL = signature

        if (isBase64(profilePicture)) {
            profilePictureURL = await uploadToS3({
                image: profilePicture,
                fileDirectory: `public/images/users`,
                fileName: `${name}-${lastname}-profilePicture`,
                isRequired: false
            })
        }

        if (isBase64(signature)) {
            signatureURL = await uploadToS3({
                image: signature,
                fileDirectory: `public/images/users`,
                fileName: `${name}-${lastname}-signature`,
                isRequired: false
            })
        }

        const {id, ...restOfValues} = values
        const jsonData = {
            item: {_id: id},
            field: {
                ...restOfValues,
                profilePicture: profilePictureURL,
                signature: signatureURL
            }
        }
        return axios.put(
            `${process.env.REACT_APP_BACKEND_SERVER}admin/academico/users`,
            jsonData,
            config
        );
    }

    const onEditTeacher = (rowData) => {
        if (rowData) {
            setInitialValuesEdit({
                id: rowData.id,
                name: rowData.name,
                lastname: rowData.lastname,
                email: rowData.email,
                phone: rowData.phone,
                profilePicture: rowData.profilePicture ?? '',
                signature: rowData.signature ?? '',
                sendMail: rowData.sendMail ?? 'false'
            })
            setDisplayForm(true)
        }
    }

    const onDeleteUser = async (rowData) => {
        // si el modal esta abierto
        if (deleteModalOpen) {
            try {
                setIsLoading(true)
                await axios.delete(URL_USERS, {
                    axiosConfig,
                    data: selectedUserId
                })

                setHasToRender(!hasToRender)
            } catch (error) {
                console.error(error)
                showNotification({
                    color: "red",
                    status: "error",
                    title: "Operación fallida.",
                    message: `No se pudo eliminar el profesor. Detalle: ${ERROR_HANDLER[error.request?.status] || ERROR_HANDLER.defaultError}`,
                    autoClose: 12000
                })
            } finally {
                setIsLoading(false)
                setDeleteModalOpen(false)
            }
        }
        // si el modal no esta abierto, lo abre
        else {
            setDeleteModalOpen(true)
            const userId = rowData?.id
            if (!userId) return setDeleteModalOpen(false)
            setSelectedUserId(userId)
        }
    }

    const formValidation = {
        name: (value) => {
            if (!value) return 'El nombre no puede estar vacío.'
            if (value.length > 55) return 'Escribe un nombre mas corto.'
            const isValid = /^[a-zA-ZÀ-ÿ\s']+$/.test(value)
            return isValid ? '' : "El nombre no puede contener símbolos."
        },
        lastname: (value) => {
            if (!value) return 'El apellido no puede estar vacío.'
            if (value.length > 55) return 'Escribe un apellido mas corto.'
            const isValid = /^[a-zA-ZÀ-ÿ\s']+$/.test(value)
            return isValid ? '' : "El apellido no puede contener símbolos."
        },
        email: (value) => {
            if (!value) return 'El email no puede estar vacío.'
            if (value.length > 76) return 'Escribe un email mas corto.'
            const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
            return isValid ? '' : "El mail ingresado no cumple con el formato esperado."
        },
        phone: (value) => {
            if (!value) return 'El teléfono no puede estar vacío.'
            if (!isValidPhoneNumber(value)) return 'El teléfono no es válido.'
        },
    }

    const handleSubmit = async (values) => {
        try {
            setIsLoading(true);
            // Check if it's an edit or a new event
            if (initialValuesEdit) {
                await putTeacher(values, axiosConfig)
            } else {
                await postTeacher(values, axiosConfig)
            }

            showNotification({
                color: 'green',
                status: 'success',
                title: '¡Excelente! Operación exitosa.',
                message: `El profesor ha sido creado / editado con éxito.`,
            });
            setHasToRender(!hasToRender)

        } catch (error) {
            showNotification({
                color: "red",
                status: "error",
                title: "Operación fallida.",
                message: `No se pudo crear / editar el profesor. Detalle: ${ERROR_HANDLER[error.request?.status] || ERROR_HANDLER.defaultError}`,
                autoClose: 12000
            });
        } finally {
            setIsLoading(false);
            setDisplayForm(false);
        }
    }

    return {
        data,
        columns: {
            teachers: colTeachers,
            students: colStudents,
        },
        onEditTeacher,
        onDeleteUser,
        initialValuesEdit,
        setInitialValuesEdit,
        displayForm,
        setDisplayForm,
        isLoading,
        handleSubmit,
        formValidation,
        deleteModalOpen,
        setDeleteModalOpen,
        selectedUserId,
        setSelectedUserId,
        // downloadCertificate,
        studentModal,
        setStudentModal,
        setCellModal,
        cellModal
    }
}
