import React, { useContext, useEffect } from "react";
import Axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";
import showNotification from "../../utils/showNotification";
import { Loader } from "@mantine/core";

export default function Callback() {
  const { isAuthenticated, user } = useAuth0();
  const { authToken } = useContext(AuthContext);
  const navigate = useNavigate();

  const routeDictionary = {
    moderacion: "/admin/moderacion",
    marketing: "/admin/marketing",
    academico: "/admin/academico",
    ventas: "/admin/ventas",
    soporte: "/admin/soporte",
    administracion: "/admin/administracion",
  };

  useEffect(() => {
    const validateUser = () => {
      Axios.get(`${process.env.REACT_APP_BACKEND_SERVER}campus/login`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authToken,
        },
      })
        .then(({ data }) => {
          if (data.complete) {
            const roles = data?.user.forVetRole;
            const firstRole = roles[0];
            const route = routeDictionary[firstRole];
            return navigate(route);
          }
          return navigate("/");
        })

        .catch((err) => {
          console.error(err);
          if (err.request?.status === 400) {
            return navigate("/");
          }
          showNotification({
            color: "red",
            status: "error",
            title: "Ha ocurrido un problema al iniciar sesión.",
            message: `Detalle:`,
            autoClose: 12000,
          });
          return navigate("/");
        });
    };

    if (isAuthenticated) {
      validateUser();
    }
  }, [user, isAuthenticated, navigate]);

  return <Loader />;
}
