import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../contexts/AuthContext";
import axios from "axios";
import showNotification from "../../../utils/showNotification";
import { ERROR_HANDLER } from "../../../utils/error-handler";
import { createColumnHelper } from "@tanstack/react-table";
import useS3 from "../../useS3";
import moment from "moment";
import filterFunctions from "../../../utils/filterFunctions";
import { Link } from "react-router-dom";
import { IconExternalLink, IconPhotoUp } from "@tabler/icons-react";
import { Badge, Group } from "@mantine/core";

const URL = `${process.env.REACT_APP_BACKEND_SERVER}admin/marketing/opportunities`

export default function useOpportunities() {
   const { uploadToS3 } = useS3()
   const { authToken } = useContext(AuthContext);
   const [opportunities, setOpportunities] = useState(null);
   const [reFetch, setReFetch] = useState(false)
   const [isLoading, setIsLoading] = useState(false);
   const [initialValuesEdit, setInitialValuesEdit] = useState(null);
   const [displayForm, setDisplayForm] = useState(false);
   const [deleteModalOpen, setDeleteModalOpen] = useState(false);
   const [selectedOpportunityId, setSelectedOpportunityId] = useState(null);
   const [isOpportunityModal, setIsOpportunityModal] = useState(false)

   const axiosConfig = {
      headers: {
         "Content-Type": "application/json",
         Authorization: `Bearer ${authToken}`
      }
   }

   useEffect(() => {
      const getOpportunities = async () => {
         try {
            setIsLoading(true)

            const { data } = await axios.get(URL, axiosConfig)

            setOpportunities(data)
         } catch (err) {
            console.error(err)
            showNotification({
               color: "red",
               status: "error",
               title: "Error al cargar las oportunidades.",
               message: `Detalle: ${ERROR_HANDLER[err.request?.status] || ERROR_HANDLER.defaultError}`,
               autoClose: 12000
            });
         } finally {
            setIsLoading(false)
         }
      }
      getOpportunities()
   }, [reFetch])

   const columnHelper = createColumnHelper();
   const columns = [
      columnHelper.accessor("title", {
         cell: (info) => {
            return info.getValue();
         },
         header: "Título",
         size: 300,
         filter: "text",
         enableSorting: false,
         filterFn: filterFunctions.textFilter,
      }),
      columnHelper.accessor("description", {
         cell: (info) => {
            return info.getValue();
         },
         header: "Descripción",
         size: 200,
         filter: "text",
         enableSorting: false,
         filterFn: filterFunctions.textFilter,
      }),
      columnHelper.accessor("BannerHighlight", {
         cell: (info) => {
            return info.getValue()
               ? <Group align="center" justify="center">
                  <Badge color="var(--color-badge-green)">DESTACADA</Badge>
               </Group>
               : <Group align="center" justify="center">
                  <Badge color="var(--color-badge-red)">NO DESTACADA</Badge>
               </Group>
         },
         header: "Destacada",
         size: 180,
         enableColumnFilter: true,
         enableSorting: false,
         filter: "select",
         filterOptions: [
            { value: 'true', label: 'DESTACADAS' },
            { value: 'false', label: 'NO DESTACADAS' },
         ],
         filterFn: (row, id, filterValue) => {
            if (!filterValue) return true;
            return row.original[id].toString()?.toLowerCase() === filterValue.toString().toLowerCase() || false;
         },
      }),
      columnHelper.accessor("redirectUrl", {
         cell: (info) => {
            return info.getValue();
         },
         header: "Enlace",
         size: 150,
         filter: "text",
         enableSorting: false,
         filterFn: filterFunctions.textFilter,
      }),
      columnHelper.accessor("logoUrl", {
         cell: (info) => {
            return <Link
               style={{ width: '100%', height: '100%', display: 'grid', placeItems: 'center', color: 'inherit' }}
               target="_blank" to={info.getValue()} >< IconPhotoUp /></Link>;
         },
         header: "Logo",
         size: 80,
         enableSorting: false,
         enableColumnFilter: false,
      }),
      columnHelper.accessor("bannerImageUrl", {
         cell: (info) => {
            return (
               <Link
                  style={{
                     width: '100%',
                     height: '100%',
                     display: 'grid',
                     placeItems: 'center',
                     color: 'inherit'
                  }}
                  target="_blank"
                  to={info.getValue()}
               >
                  < IconExternalLink />
               </Link>
            )
         },
         header: "Banner",
         size: 80,
         enableSorting: false,
         enableColumnFilter: false,
      }),
   ];

   // TABLE FUNCTIONS
   const postOpportunity = async (values, config) => {
      const jsonData = {
         data: {
            ...values,
         }
      }
      return axios.post(
         URL,
         jsonData,
         config
      );
   }

   const putOpportunity = async (values, config) => {

      const jsonData = {
         item: { _id: values._id },
         field: {
            ...values,
         }
      }

      return axios.put(
         URL,
         jsonData,
         config
      );
   }

   const editOpportunity = (rowData) => {
      if (rowData) {
         setInitialValuesEdit({
            _id: rowData._id,
            title: rowData.title,
            description: rowData.description,
            redirectUrl: rowData.redirectUrl,
            logoUrl: rowData.logoUrl,
            bannerImageUrl: rowData.bannerImageUrl,
            BannerHighlight: rowData.BannerHighlight,
         })
         setDisplayForm(true)
      }
   }

   const deleteOpportunity = async (rowData) => {
      // si el modal esta abierto
      if (deleteModalOpen) {
         try {
            setIsLoading(true)
            await axios.delete(URL, {
               ...axiosConfig,
               data: { _id: selectedOpportunityId }
            })
            setReFetch(!reFetch)
         } catch (error) {
            console.error(error)
            showNotification({
               color: "red",
               status: "error",
               title: "Operación fallida.",
               message: `No se pudo eliminar la oportunidad. Detalle: ${ERROR_HANDLER[error.request?.status] || ERROR_HANDLER.defaultError}`,
               autoClose: 12000
            })
         } finally {
            setIsLoading(false)
            setDeleteModalOpen(false)
         }
      }
      // si el modal no esta abierto, lo abre
      else {
         setDeleteModalOpen(true)
         const opportunityId = rowData?._id
         if (!opportunityId) return setDeleteModalOpen(false)
         setSelectedOpportunityId(opportunityId)
      }
   }

   const handleSubmit = async (values) => {

      try {
         setIsLoading(true);

         if (values.logoUrl) {
            const clearTitle = `${values.title}-logoUrl`.replace(/[^\w\s]/g, "").trim();
            values.logoUrl = await uploadToS3({
               image: values.logoUrl,
               fileName: `${clearTitle}-${moment().format("DD-MM-YYYY")}`,
               fileDirectory: "public/images/opportunities",
               isRequired: true,
            });
         }

         if (values.bannerImageUrl) {
            const clearTitle = `${values.title}-bannerImageUrl`.replace(/[^\w\s]/g, "").trim();
            values.bannerImageUrl = await uploadToS3({
               image: values.bannerImageUrl,
               fileName: `${clearTitle}-${moment().format("DD-MM-YYYY")}`,
               fileDirectory: "public/images/opportunities",
               isRequired: true,
            });
         }

         if (initialValuesEdit) {
            await putOpportunity(values, axiosConfig)
         } else {
            await postOpportunity(values, axiosConfig)
         }

         showNotification({
            color: 'green',
            status: 'success',
            title: '¡Excelente! Operación exitosa.',
            message: `La oportunidad ha sido creado / editado con éxito.`,
         });

         setReFetch(!reFetch);
         setInitialValuesEdit(null)
         setSelectedOpportunityId(null)
         setIsOpportunityModal(false)
      } catch (error) {
         console.error(error)
         showNotification({
            color: "red",
            status: "error",
            title: "Operación fallida.",
            message: `No se pudo crear / editar la oportunidad. Detalle: ${ERROR_HANDLER[error?.request?.status] || ERROR_HANDLER.defaultError}`,
            autoClose: 12000
         });
      } finally {
         setIsLoading(false);
         setDisplayForm(false);
      }
   }

   const formValidation = {
      title: (value) => {
         if (!value) return 'El título no puede estar vacío.';
         if (value.length > 250) return 'El mensaje debe ser menor o igual a 250 caracteres';
         return null;
      },
      description: (value) => {
         if (!value) return 'La descripcion no puede estar vacía.';
         return (value.length < 250) ? null : 'El mensaje debe ser menor a 250 caracteres.';
      },
      redirectUrl: (value) => {
         if (!value) {
            return 'El enlace no puede estar vacío.';
         }
         const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
         if (!urlRegex.test(value)) {
            return 'El enlace debe ser una URL válida.';
         }
         const maliciousCodeRegex = /<script\b[^>]*>(.*?)<\/script>/gi;
         if (maliciousCodeRegex.test(value)) {
            return 'Puede que el enlace contenga código malicioso.';
         }
         return null;
      },
      BannerHighlight: value => {
         if (typeof value !== 'boolean') return 'La oportunidad solo puede tener un valor de verdadero o falso';
         return null;
      }
      // image: (value) => {
      //    if (!value) {
      //       return 'Falta cargar la imagen de fondo.';
      //    }
      //    const isURL = /(https?|ftp):\/\/[^\s/$.?#].[^\s]*\.(jpg|jpeg|png)$/i.test(value);
      //    if (!isURL && !isBase64(value)) return 'La imagen debe cargarse en formato URL con una extensión: (jpg|jpeg|png).';
      // },
   }

   return {
      data: opportunities,
      columns,
      displayForm,
      setDisplayForm,
      formValidation,
      isLoading,
      handleSubmit,
      initialValuesEdit,
      setInitialValuesEdit,
      deleteModalOpen,
      setDeleteModalOpen,
      setSelectedOpportunityId,
      deleteOpportunity,
      editOpportunity,
      isOpportunityModal,
      setIsOpportunityModal
   }
} 