import axios from "axios";
import { useContext, useEffect, useState } from "react";
import showNotification from "../../../utils/showNotification";
import AuthContext from "../../../contexts/AuthContext";
import { createColumnHelper } from "@tanstack/react-table";
import { ERROR_HANDLER } from "../../../utils/error-handler";
import { isImageUrl } from "../../../utils/isImageUrl";
import { isBase64 } from "../../../utils/isBase64";
import moment from "moment";

const NEWS_URL = `${process.env.REACT_APP_BACKEND_SERVER}admin/marketing/news`

export default function useMktgNewsView() {
   const { authToken } = useContext(AuthContext)
   const [isLoading, setIsLoading] = useState()
   const [allNews, setAllNews] = useState(null)
   const [displayForm, setDisplayForm] = useState(false);
   const [initialValuesEdit, setInitialValuesEdit] = useState(null);
   const [deleteModalOpen, setDeleteModalOpen] = useState(false);
   const [selectedNewId, setSelectedNewId] = useState(null);
   const [hasUpadte, setHasUpadte] = useState(false);

   const axiosConfig = {
      headers: {
         "Content-Type": "application/json",
         "Authorization": "Bearer " + authToken,
      }
   }

   useEffect(() => {
      const getAllNews = async () => {
         setIsLoading(true)
         try {
            const { data } = await axios.get(NEWS_URL, axiosConfig)

            setAllNews(data);
         } catch (error) {
            showNotification({
               color: "red",
               status: "error",
               title: "Error al cargar todas las novedades.",
               message: `Detalle: ${ERROR_HANDLER[error.request?.status] || ERROR_HANDLER.defaultError}`,
               autoClose: 12000
            })
         } finally {
            setIsLoading(false)
         }
      }
      getAllNews()
   }, [authToken, hasUpadte])

   const mapNewActive = {
      true: 'Si',
      false: 'No'
   }

   // COLUMN DEFINITIONS
   const columnHelper = createColumnHelper();
   const columns = [
      columnHelper.accessor('title', {
         cell: info => info.getValue(),
         header: 'Título',
      }),
      columnHelper.accessor('text', {
         cell: info => info.getValue(),
         header: 'Descripcion',
         size: 150
      }),
      columnHelper.accessor('url', {
         cell: info => info.getValue(),
         header: 'Enlace',
         size: 150
      }),
      columnHelper.accessor('active', {
         cell: info => mapNewActive[info.getValue()],
         header: 'Activo',
         size: 150
      }),
   ];
   
   // UTIL
   /* cargar imagen a AWS */
   const uploadToS3 = async ({ image, fileName, fileDirectory }) => {
      if (!image) return showNotification({
         color: "red",
         status: "error",
         title: "Error al cargar la imagen.",
         message: `Detalle: No se ha encontrado ningún archivo o URL para guardar.`
      })

      let imageUrl

      if (isBase64(image)) {
         // Convertir el archivo base64 en un Blob
         const base64Data = image.split(',')[1]; // Remove data URI prefix
         const byteString = atob(base64Data);
         const mimeType = image.split(';')[0].split(':')[1]; // Extract mimeType
         const byteArray = new Uint8Array(byteString.length);
         for (let i = 0; i < byteString.length; i++) {
            byteArray[i] = byteString.charCodeAt(i);
         }
         const blob = new Blob([byteArray], { type: mimeType });

         // Crear FormData y agregar el Blob
         const formData = new FormData()
         formData.append('directory', fileDirectory)
         formData.append('name', `${fileName}.${mimeType.split('/')[1]}`)
         formData.append('file', blob)

         const awsResponse = await axios.post(
            `${process.env.REACT_APP_BACKEND_SERVER}admin/uploadToS3`,
            formData, {
            headers: {
               'Content-Type': 'multipart/form-data',
               "Authorization": `Bearer ${authToken}`
            },
         }
         );

         imageUrl = awsResponse.data.url
      }

      const notValidImage = formValidations.image(imageUrl)
      if (notValidImage) return showNotification({
         color: "red",
         status: "error",
         title: "Error al cargar la imagen.",
         message: `Detalle: ${notValidImage}`
      })

      return imageUrl
   }


   // TABLE FUNCTIONS
   const postNew = async (values, config) => {
      let imageURL = values.image

      if (isBase64(values.image)) {
         imageURL = await uploadToS3({
            image: values.image,
            fileDirectory: `public/images/news`,
            fileName: `${values._id}-${moment().format('DD-MM-YYYY')}`,
         })
      }

      const jsonData = {
         data: {
            ...values,
            image: imageURL
         }
      }
      return axios.post(
         NEWS_URL,
         jsonData,
         config
      );
   }

   const putNew = async (values, config) => {
      let imageURL = values.image

      if (isBase64(imageURL)) {
         imageURL = await uploadToS3({
            image: values.image,
            fileDirectory: `public/images/news`,
            fileName: `${values._id}-${moment().format('DD-MM-YYYY')}`,
         })
      }

      const jsonData = {
         item: { _id: values._id },
         field: {
            ...values,
            image: imageURL
         }
      }

      return axios.put(
         NEWS_URL,
         jsonData,
         config
      );
   }

   const editNew = (rowData) => {
      if (rowData) {
         setInitialValuesEdit({
            _id: rowData._id,
            title: rowData.title,
            text: rowData.text,
            active: rowData.active,
            image: rowData.image,
            url: rowData.url,
         })
         setDisplayForm(true)
      }
   }

   const deleteNew = async (rowData) => {
      // si el modal esta abierto
      if (deleteModalOpen) {
         try {
            setIsLoading(true)
            await axios.delete(NEWS_URL, {
               ...axiosConfig,
               data: {_id: selectedNewId}
            })
            setHasUpadte(!hasUpadte)
         } catch (error) {
            console.error(error)
            showNotification({
               color: "red",
               status: "error",
               title: "Operación fallida.",
               message: `No se pudo eliminar el consejo. Detalle: ${ERROR_HANDLER[error.request?.status] || ERROR_HANDLER.defaultError}`,
               autoClose: 12000
            })
         } finally {
            setIsLoading(false)
            setDeleteModalOpen(false)
         }
      }
      // si el modal no esta abierto, lo abre
      else {
         setDeleteModalOpen(true)
         const adviceId = rowData?._id
         if (!adviceId) return setDeleteModalOpen(false)
         setSelectedNewId(adviceId)
      }
   }

   const handleSubmit = async (values) => {
      try {
         setIsLoading(true);
         // Check if it's an edit or a new event
         if (initialValuesEdit) {
            await putNew(values, axiosConfig)
         } else {
            await postNew(values, axiosConfig)
         }

         showNotification({
            color: 'green',
            status: 'success',
            title: '¡Excelente! Operación exitosa.',
            message: `La novedad ha sido creado / editado con éxito.`,
         });

         setHasUpadte(!hasUpadte);
      } catch (error) {
         console.error(error)
         showNotification({
            color: "red",
            status: "error",
            title: "Operación fallida.",
            message: `No se pudo crear / editar la novedad. Detalle: ${ERROR_HANDLER[error?.request?.status] || ERROR_HANDLER.defaultError}`,
            autoClose: 12000
         });
      } finally {
         setIsLoading(false);
         setDisplayForm(false);
      }
   }

   // FORM VALIDATIONS
   const formValidations = {
      title: (value) => {
         if (!value) return 'El título no puede estar vacío.';
         if (value.length > 250) return 'El mensaje debe ser menor o igual a 250 caracteres';
         return null;
      },
      text: (value) => {
         if (!value) return 'La descripcion no puede estar vacía.';
         return (value.length < 150) ? null : 'El mensaje debe ser menor a 150 caracteres.';
      },     
      url: (value) => {
         if (!value) {
            return 'El enlace no puede estar vacío.';
         }
         const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
         if (!urlRegex.test(value)) {
            return 'El enlace debe ser una URL válida.';
         }
         const maliciousCodeRegex = /<script\b[^>]*>(.*?)<\/script>/gi;
         if (maliciousCodeRegex.test(value)) {
            return 'El enlace no puede contener código malicioso.';
         }
         return null;
      },
      image: (value) => {
         if (!value) {
            return 'Falta cargar la imagen de fondo.';
         }
         const isURL = /(https?|ftp):\/\/[^\s/$.?#].[^\s]*\.(jpg|jpeg|png)$/i.test(value);
         if (!isURL && !isBase64(value)) return 'La imagen debe cargarse en formato URL con una extensión: (jpg|jpeg|png).';
      },
   }

   return {
      formValidations,
      isLoading,
      data: allNews,
      columns,
      editNew,
      handleSubmit,
      displayForm,
      setDisplayForm,
      initialValuesEdit,
      setInitialValuesEdit,
      deleteModalOpen,
      setDeleteModalOpen,
      setSelectedNewId,
      deleteNew,
   }
}
