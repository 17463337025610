import { Checkbox, Group, Stack } from '@mantine/core'
import { IconBrandZoom, IconCalendar, IconEdit, IconUser, Icon123 } from '@tabler/icons-react'
import moment from 'moment'
import React from 'react'

export default function ModerationStructuresModules({
   modules,
   setIsModal,
   setClassesToEdit
}) {

   return (
      <Stack mt="xs">
         {modules?.length === 0
            ? <p className='ModerationStructuresForm--bodyText'>Esta unidad académica no presenta modulos</p>
            : modules?.map(module => (
               <Stack key={module._id}>
                  <h4 className='ModerationStructuresForm--moduleTitle'>Módulo: {module.name}</h4>
                  <Stack>
                     {module.lessons.length === 0
                        ? <p className='ModerationStructuresForm--bodyText'>Este modulo no tiene clases</p>
                        : module.lessons
                           .sort((a, b) => moment.utc(a.initDate).local().valueOf() - moment.utc(b.initDate).local().valueOf())
                           ?.map(lesson => (
                              <Stack
                                 className='ModerationStructuresForm-lesson-container'
                              >
                                 <Group align='start' pos='relative'>
                                    <Stack align='start' justify='space-between' pos='absolute' right={0}
                                       top={0}>
                                       < IconEdit
                                          color='var(--color-tertiary)'
                                          className='ModerationStructuresForm--onEditIcon'
                                          onClick={() => {
                                             setClassesToEdit([lesson._id])
                                             setIsModal(true)
                                          }}
                                       />
                                       < Checkbox value={lesson._id} />
                                    </Stack>
                                    <Stack pr={30} gap={30}>
                                       <Stack gap={0}>
                                          <Group align='center' gap={1}>
                                             <span
                                                className='ModerationStructuresForm--bodyText'>Clase:</span>
                                          </Group>
                                          <h5 className='ModerationStructuresForm--bodyText ModerationStructuresForm--lessonName'>{lesson.name}</h5>
                                       </Stack>
                                       <Group>
                                          {lesson.initDate && <Group gap={1}>
                                             < IconCalendar className='ModerationStructuresForm--icon' />
                                             <span
                                                className='ModerationStructuresForm--bodyText'
                                             >
                                                {moment(lesson.initDate).format('DD MMMM YYYY')}
                                             </span>
                                          </Group>}
                                          {lesson.moderator?.name &&
                                             <Group gap={1} align='center'>
                                                < IconUser className='ModerationStructuresForm--icon' />
                                                <span
                                                   className='ModerationStructuresForm--bodyText'
                                                >
                                                   {lesson.moderator.name}
                                                </span>
                                             </Group>
                                          }
                                          {lesson.roomUrl &&
                                             <Group gap={1} align='center'>
                                                < IconBrandZoom className='ModerationStructuresForm--icon' />
                                                <p
                                                   className='ModerationStructuresForm--bodyText ModerationStructuresForm--zoomLink '
                                                >
                                                   {lesson.roomUrl}
                                                </p>
                                             </Group>
                                          }
                                          {lesson.zoomCode &&
                                             <Group gap={1} align='center'>
                                                < Icon123 className='ModerationStructuresForm--icon' />
                                                <p
                                                   className='ModerationStructuresForm--bodyText ModerationStructuresForm--zoomLink '
                                                >
                                                   {lesson.zoomCode}
                                                </p>
                                             </Group>
                                          }
                                       </Group>
                                    </Stack>
                                 </Group>
                              </Stack>
                           ))}
                  </Stack>
               </Stack>
            ))
         }
      </Stack>
   )
}
