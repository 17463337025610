import { Group, Stack, Flex, Tooltip, Skeleton, Paper } from '@mantine/core';
import { IconTrendingUp, IconTrendingDown } from '@tabler/icons-react';

const AcademicDbTeachersRankSkeleton = () => {
  return (
    <div style={{marginTop:"16px", marginBottom: "16px"}} className="academicDbTeachersRank-mainDiv">
      <div className="academicDbTeachersRank-gridContainer">
        {/* Mejor rankeadas */}
        <Paper className="academicDbTeachersRank-card" withBorder>
          <Group>
            <Group gap="0">
              <IconTrendingUp />
            </Group>
            <h3 className="academicDbTeachersRank-card-title">
              <Skeleton height={24} width="50%" />
            </h3>
          </Group>
          <Stack className="academicDbTeachersRank-stack-wrapper">
            {[...Array(5)].map((_, index) => (
              <Flex
                className="academicDbTeachersRank-card-rowItem"
                align="start"
                justify="space-between"
                w="100%"
                gap="5px"
                key={index}
              >
                <Tooltip label="Loading..." withArrow>
                  <Skeleton height={20} width="50%" />
                </Tooltip>
                <Skeleton height={24} width="20%" />
              </Flex>
            ))}
            {[...Array(1)].map((_, index) => (
              <Skeleton height={20} key={index} />
            ))}
          </Stack>
        </Paper>

        {/* Peor rankeadas */}
        <Paper className="academicDbTeachersRank-card" withBorder>
          <Group>
            <Group gap="0">
              <IconTrendingDown />
            </Group>
            <h3 className="academicDbTeachersRank-card-title">
              <Skeleton height={24} width="50%" />
            </h3>
          </Group>
          <Stack className="academicDbTeachersRank-stack-wrapper">
            {[...Array(5)].map((_, index) => (
              <Flex
                className="academicDbTeachersRank-card-rowItem"
                justify="space-between"
                align="start"
                w="100%"
                gap="5px"
                key={index}
              >
                <Tooltip label="Loading..." withArrow>
                  <Skeleton height={20} width="50%" />
                </Tooltip>
                <Skeleton height={24} width="20%" />
              </Flex>
            ))}
            {[...Array(1)].map((_, index) => (
              <Skeleton height={20} key={index} />
            ))}
          </Stack>
        </Paper>
      </div>
    </div>
  );
};

export default AcademicDbTeachersRankSkeleton;
