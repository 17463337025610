import './AllQuestionModal.css'
import { Accordion, Button, Loader, Modal, Radio, Stack, TextInput } from "@mantine/core";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import showNotification from "../../../../utils/showNotification";
import { ERROR_HANDLER } from "../../../../utils/error-handler";
import AuthContext from "../../../../contexts/AuthContext";
import { IconSearch, IconX } from '@tabler/icons-react'


export default function AllQuestionModal({ opened, onClose, addFunct }) {
   const { authToken } = useContext(AuthContext)
   const [allQuestions, setAllQuestions] = useState(null)
   const [isLoading, setIsLoading] = useState(false)
   const [selectedQuestion, setSelectedQuestion] = useState(null)
   const [inputSearch, setInputSearch] = useState('')

   useEffect(() => {
      const getQuestions = async () => {
         try {
            setIsLoading(true)
            const url = `${process.env.REACT_APP_BACKEND_SERVER}admin/academico/questions`

            const { data } = await axios.get(url, {
               headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${authToken}`
               }
            })
            setAllQuestions(data)
         } catch (error) {
            console.error(error)
            onClose(false);
            return showNotification({
               color: "red",
               status: "error",
               title: "Error al cargar todas las preguntas.",
               message: `Detalle: ${ERROR_HANDLER[error.request?.status] || ERROR_HANDLER.defaultError}`,
               autoClose: 12000
            });
         } finally {
            setIsLoading(false)
         }
      }
      getQuestions()
   }, [])



   const filteredQuestions = allQuestions?.filter(item => item.questionText.includes(inputSearch))

   return (
      <Modal 
         size='auto' 
         opened={opened} 
         onClose={() => { 
            onClose(false); 
            setSelectedQuestion(null) 
         }}
         withCloseButton={false}
         title={
            <Stack align='center'>
               <p>Selecciones una pregunta</p>
               < TextInput
                  w='100%'
                  placeholder='Buscar pregunta'
                  leftSection={< IconSearch />}
                  rightSection={< IconX style={{ cursor: 'pointer' }} onClick={() => setInputSearch('')} />}
                  value={inputSearch || ''}
                  onChange={(event) => setInputSearch(event.target.value)}
                  py={20}
               />

               <div className='AllQuestionModal--buttonContainer'>
                  <Button variant='outline' color='var(--color-tertiary)' onClick={() => { onClose(false); setSelectedQuestion(null) }}>
                     Volver
                  </Button>
                  <Button
                     disabled={!selectedQuestion}
                     color='var(--color-tertiary)'
                     onClick={() => {
                        addFunct(selectedQuestion)
                        onClose(false)
                     }}
                  >
                     Aceptar
                  </Button>
               </div>

            </Stack>
         }
         classNames={{ title: 'AllQuestionModal--modalTitle' }}
      >



         {isLoading &&
            <div className='AllQuestionModal--loader-container'>
               < Loader size={50} />
            </div>
         }


         {allQuestions?.length > 0 &&
            filteredQuestions?.map(item => (
               <article className='AllQuestionModal-questionGroup' >
                  <Accordion w='100%' chevronPosition="left">
                     <Accordion.Item key={item._id} value={item?._id || 'Escribe tu pregunta'}>
                        <Accordion.Control>
                           <h4 className='AllQuestionModal--title'>{item.title}</h4>
                           <h5 className='AllQuestionModal--questionText'>{item.questionText}</h5>
                        </Accordion.Control>
                        <Accordion.Panel>{item.responseOptions?.map(res => (
                           <p className={`QuestionnairePreview--answerText ${res.value === item.trueOption ? 'QuestionnairePreview--correctAnswer' : ''}`}>{res.label}</p>
                        ))}</Accordion.Panel>
                     </Accordion.Item>
                  </Accordion>
                  < Radio
                     name="question"
                     onChange={() => setSelectedQuestion(item)}
                  />
               </article>
            ))
         }

         {filteredQuestions?.length === 0 && <p className='AllQuestion-noContent-text'>No se han encontrado preguntas disponibles</p>}



      </Modal>
   )
}
