import React, {useContext, useEffect, useState} from "react";
import "./SalesPayments.css";
import axios from "axios";
import AuthContext from "../../../../contexts/AuthContext";
import TanStackTable from "../../../../components/Table/TanStackTable/TanStackTable";
import {createColumnHelper} from "@tanstack/react-table";
import ContentCreator from "../../../../components/ContentCreator/ContentCreator";
import showNotification from "../../../../utils/showNotification";
import {ERROR_HANDLER} from "../../../../utils/error-handler";
import AppContext from "../../../../contexts/AppContext";
import {SalesPaymentsView} from "../../../../components/SalesPaymentsView/SalesPaymentsView";
import SalesPaymentsForm from "../SalesPaymentsForm/SalesPaymentsForm";
import useS3 from "../../../../hooks/useS3";
import moment from "moment";
import {IconSquareX, IconSquareCheck} from "@tabler/icons-react";
import StudentModal from "../../../../components/StudentModal/StudentModal";
import {Badge, Loader} from "@mantine/core";
import {currencyFormat} from "../../../../utils/currencyFormat";
import filterFunctions from "../../../../utils/filterFunctions";
import NewSalesPaymentsForm from "../NewSalesPaymentsForm/NewSalesPaymentsForm";

const FORM_CONFIG = {
    "CREATE": "CREATE",
    "EDIT": "EDIT",
    "NONE": "NONE",
}

const createSale = async (values, config) => {
    const jsonData = {
        data: {
            ...values,
        },
    };
    return axios.post(
        `${process.env.REACT_APP_BACKEND_SERVER}admin/ventas/createSale`,
        jsonData,
        config
    );
};

const editSale = async (values, config, _uploadToS3) => {
    values.installments.total_cycles = parseInt(
        values.installments.total_cycles,
        10
    );
    if (values.receipt) {
        const clearTitle = `${values.sellerId}`.replace(/[^\w\s]/g, "").trim();
        values.receipt = await _uploadToS3({
            image: values.receipt,
            fileName: `${clearTitle}-${moment().format("DD-MM-YYYY")}`,
            fileDirectory: "receipts",
            isRequired: true,
        });
    }

    const jsonData = {
        data: {
            ...values,
        },
    };
    return axios.put(
        `${process.env.REACT_APP_BACKEND_SERVER}admin/ventas/setSellers/${values._id}`,
        jsonData,
        config
    );
};

const getCustomerBadge = (isNew) => {
    const text = isNew ? "Nuevo" : "Preexistente";
    return (
        <div className="salesPayment__badge__container">
            <Badge color={isNew ? "rgb(158, 192, 152)" : "rgb(150,61,61)"}>
                {text}
            </Badge>
        </div>
    );
};

const getStateBadge = (state, firstInstallmentState) => {
    let text, color;
    switch (state) {
        case "success":
            text = "Inscripto";
            color = "rgb(158, 192, 152)"; // var(--color-badge-green)
            break;
        case "cancelled":
            text = "Cancelado";
            color = "rgb(150,61,61)"; // var(--color-badge-red)
            break;
        default:
            text = "Pendiente";
            color = "rgb(197,189,132)"; // var(--color-badge-yellow)
    }
    if (state === "approval_pending" && firstInstallmentState === "PAID") {
        // This is a fix to handle not so good payment state.
        // If you want to change this. Relate to filtered rows on init function
        text = "Inscripto";
        color = "rgb(158, 192, 152)"; // var(--color-badge-green)
    }
    return (
        <div className="salesPayment__badge__container">
            <Badge color={color}>{text}</Badge>
        </div>
    );
};

const getAdminStateBadge = (state) => {
    let text, color;
    if (state === "success") {
        text = "Completas";
        color = "rgb(158, 192, 152)"; // var(--color-badge-green)
    } else {
        text = "Pendientes";
        color = "rgb(197,189,132)"; // var(--color-badge-yellow)
    }
    return (
        <div className="salesPayment__badge__container">
            <Badge color={color}>{text}</Badge>
        </div>
    );
};

function SalesPayments() {
    const {authToken} = useContext(AuthContext);
    const [salesPaymentsData, setSalesPaymentsData] = useState(null);
    const [displayForm, setDisplayForm] = useState(FORM_CONFIG.NONE);
    const [initialValuesEdit, setInitialValuesEdit] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [studentData, setStudentData] = useState();
    const [loading, setLoading] = useState(false);
    const [actionsLoading, setActionsLoading] = useState([]);
    const [updateData, setUpdateData] = useState(false);
    const {staffUsers, bankAccountsOptions} = useContext(AppContext);
    const {uploadToS3} = useS3();

    const PAYMENT_STATUS = {
        SUCCESS: "Success",
        CANCELLED: "Cancelled",
    };

    const handlePaymentConfirmation = async (paymentCode, paymentStatus) => {
        setActionsLoading((prev) => [...prev, paymentCode]);
        const data = {
            id: paymentCode,
            status: paymentStatus,
        };
        try {
            await axios.post(
                `${process.env.REACT_APP_BACKEND_SERVER}payment/paymentRequest/`,
                {data},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            showNotification({
                color: "green",
                status: "success",
                title: "¡Operación exitosa!",
                message: "La inscripción del estudiante se ha confirmado.",
                autoClose: 5000,
            });
        } catch (error) {
            showNotification({
                color: "red",
                status: "error",
                title: "Operación fallida.",
                message: `No se pudo confirmar la inscripción. Detalle: ${
                    ERROR_HANDLER[error.request?.status] || ERROR_HANDLER.defaultError
                }`,
                autoClose: 12000,
            });
        } finally {
            setUpdateData(!updateData);
            setActionsLoading((prev) => prev.filter((item) => item !== paymentCode));
        }
    };

    const handleStudentData = (_studentData) => {
        setStudentData(_studentData);
        if (studentData !== undefined) {
            setModalOpen(true);
        }
    };

    // COLUMN DEFINITIONS
    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor("created_at", {
            cell: (info) => {
                const dateValue = new Date(info.getValue());
                return (
                    <div className="salesPayment-cell-center">
                        {moment(dateValue).format("DD/MM/YYYY")}
                    </div>
                );
            },
            header: "Fecha",
            size: 248,
            enableColumnFilter: true,
            enableSorting: false,
            filter: "date",
            filterFn: filterFunctions.dateRangeFilter,
        }),
        columnHelper.accessor("dummyValue", {
            cell: (info) => {
                // Here you can configure the logic to indicate whether the admin has pending tasks or not.
                let badge = "pending";
                if (
                    info.row.original.sellerId !== undefined &&
                    info.row.original.sellerId !== null
                )
                    badge = "success";
                return getAdminStateBadge(badge);
            },
            header: "Tareas ADMIN",
            size: 156,
            enableColumnFilter: true,
            enableSorting: false,
            filter: "select",
            filterOptions: [
                {value: "success", label: "Completas"},
                {value: "pending", label: "Pendientes"},
            ],
            filterFn: (row, id, filterValue) => {
                if (!filterValue) return true;
                return row.original.sellerId !== undefined &&
                row.original.sellerId !== null
                    ? filterValue === "success"
                    : filterValue === "pending";
            },
            tooltip:
                "Indica si existen tareas pendientes. Ejemplo: Asignar el vendedor.",
        }),
        columnHelper.accessor("payer", {
            cell: (info) => {
                const value = info.getValue();
                return (
                    <span
                        className="salesPayment-studentData-span"
                        onClick={() => handleStudentData(value)}
                    >
            {value.name} {value.lastname}
          </span>
                );
            },
            header: "Datos del Alumno",
            size: 200,
            filter: "text",
            enableSorting: false,
            filterFn: filterFunctions.objectFilter,
        }),
        columnHelper.accessor("details", {
            cell: (info) => {
                const value = info.getValue();
                return value ? value : "No hay capacitación disponible";
            },
            header: "Capacitación",
            size: 304,
            filter: "text",
            enableSorting: false,
            filterFn: filterFunctions.textFilter,
        }),
        columnHelper.accessor("state", {
            cell: (info) => {
                const firstInstallmentState =
                    info?.row?.original?.installments?.[0]?.state || "NULL";
                return getStateBadge(
                    info.getValue().toLowerCase(),
                    firstInstallmentState
                );
            },
            header: "Inscripción",
            size: 136,
            enableColumnFilter: true,
            enableSorting: false,
            filter: "select",
            filterOptions: [
                {value: "success", label: "Inscripto"},
                {value: "pending", label: "Pendiente"},
                {value: "cancelled", label: "Cancelado"},
            ],
            filterFn: filterFunctions.selectFilter,
            tooltip:
                "Indica si el alumno se encuentra inscripto en la capacitación y cuenta con acceso al campus.",
        }),
        columnHelper.accessor("payer.isNewCustomer", {
            cell: (info) => {
                const customer = info.getValue();
                return getCustomerBadge(customer);
            },
            header: "Nuevo",
            size: 88,
            enableColumnFilter: true,
            enableSorting: false,
            filter: "select",
            filterOptions: [
                {value: "true", label: "Nuevo"},
                {value: "false", label: "Preexistente"},
            ],
            filterFn: (row, id, filterValue) => {
                if (!filterValue) return true;
                return (
                    row.original.payer.isNewCustomer.toString() === filterValue.toString()
                );
            },
            tooltip: "Indica si se trata de un nuevo estudiante para FORVET.",
        }),
        columnHelper.accessor("sellerId", {
            cell: (info) => {
                const staffUser = staffUsers.find(
                    (user) => user.value === info.getValue()
                );
                return staffUser ? staffUser.label : "No hay vendedor designado.";
            },
            header: "Vendedor",
            size: 120,
            enableColumnFilter: true,
            enableSorting: false,
            filter: "select",
            filterOptions: staffUsers,
            filterFn: filterFunctions.selectFilter,
        }),
        columnHelper.accessor("payment.type", {
            cell: (info) => info.getValue(),
            header: "Plataforma",
            size: 164,
            enableColumnFilter: true,
            enableSorting: false,
            filter: "select",
            filterOptions: [
                {value: "PAYPAL", label: "PAYPAL"},
                {value: "PAGOS360", label: "PAGOS360"},
                {value: "TRANSFERENCIA", label: "TRANSFERENCIA"},
            ],
            filterFn: (row, id, filterValue) => {
                if (!filterValue) return true;
                return (
                    row.original.payment.type.toLowerCase() === filterValue.toLowerCase()
                );
            },
        }),
        columnHelper.accessor("payment.bankTransferAccount", {
            cell: (info) => info.getValue(),
            header: "Cuenta",
            size: 164,
            enableColumnFilter: true,
            enableSorting: false,
            filter: "select",
            filterOptions: bankAccountsOptions,
            filterFn: (row, id, filterValue) => {
                if (!filterValue || !row.original.payment.bankTransferAccount)
                    return true;
                return (
                    row.original.payment.bankTransferAccount.toLowerCase() ===
                    filterValue.toLowerCase()
                );
            },
        }),
        columnHelper.accessor("type", {
            cell: (info) => {
                const value = info.getValue();
                if (value === "SINGLE_PAYMENT") {
                    return "No";
                } else {
                    return "Si";
                }
            },
            header: "Cuotas",
            size: 80,
            enableColumnFilter: true,
            enableSorting: false,
            filter: "select",
            filterOptions: [
                {value: "SINGLE_PAYMENT", label: "No"},
                {value: "SUBSCRIPTION", label: "Si"},
            ],
            filterFn: filterFunctions.selectFilter,
        }),

        columnHelper.accessor("agreedAmount", {
            cell: (info) => `$${currencyFormat(info.getValue())}`,
            header: "Monto",
            size: 112,
            enableColumnFilter: false,
            enableSorting: true,
            tooltip:
                "Monto abonado por el estudiante. Después de aplicar el descuento.",
        }),
        columnHelper.accessor("payment.currency", {
            cell: (info) => info.getValue(),
            header: "Moneda",
            size: 88,
            enableColumnFilter: true,
            enableSorting: false,
            filter: "select",
            filterOptions: [
                {value: "ARS", label: "ARS"},
                {value: "USD", label: "USD"},
            ],
            filterFn: (row, id, filterValue) => {
                if (!filterValue) return true;
                return (
                    row.original.payment.currency.toLowerCase() ===
                    filterValue.toLowerCase()
                );
            },
        }),
        columnHelper.accessor("discount.percent", {
            cell: (info) => `${info.getValue()}%`,
            header: "DCTO",
            size: 88,
            enableColumnFilter: false,
            enableSorting: true,
        }),
        columnHelper.accessor("commit", {
            cell: (info) => info.getValue(),
            header: "Comentarios",
            size: 400,
            filter: "text",
            enableSorting: false,
            enableColumnFilter: true,
            filterFn: filterFunctions.textFilter,
        }),
    ];

    // TABLE FUNCTIONS
    const editPaymentStateAction = (rowData) => {
        const paymentCode = rowData.gateway_id;
        const paymentDefaultStatus = rowData.state.toLowerCase();

        if (actionsLoading.includes(paymentCode)) return <Loader size="sm"/>;
        return paymentDefaultStatus === "pending" ? (
            <>
                <IconSquareCheck
                    className="salesPayment-confirmation-icon"
                    onClick={() =>
                        handlePaymentConfirmation(paymentCode, PAYMENT_STATUS.SUCCESS)
                    }
                />
                <IconSquareX
                    className="salesPayment-denied-icon"
                    onClick={() =>
                        handlePaymentConfirmation(paymentCode, PAYMENT_STATUS.CANCELLED)
                    }
                />
            </>
        ) : null;
    };

    const editSalesPaymentsEvent = (rowData) => {
        if (rowData) {
            setInitialValuesEdit({
                rowData,
                _id: rowData._id,
                inscriptionOrigin: rowData.inscriptionOrigin || "GENERAL",
                dollar_day: rowData.payment.dollar_day,
                isNewCustomer: rowData.payer.isNewCustomer ? "true" : "false",
                sellerId: rowData.sellerId,
                bankTransferAccount:
                    rowData.payment.bankTransferAccount ||
                    bankAccountsOptions[bankAccountsOptions.length - 1].value,
                type: rowData.type,
                commit: rowData.commit,
                agreedAmount: rowData.agreedAmount,
                installments: {
                    total_cycles: rowData.installments[0]?.total_cycles,
                    first_due_date:
                        moment(rowData.installments[0]?.date).toDate() || null,
                    data:
                        rowData.installments.length > 0
                            ? rowData.installments.map((_inst) => {
                                return {
                                    due_date: moment(_inst.date).toDate(),
                                    amount: _inst.amount, // Or agreedAmount
                                };
                            })
                            : null,
                },
                currency: rowData.payment.currency,
                trackingCode: rowData.tracking_code, // Tracking code to fetch the receipt
                receipt: null, // Initiates on null to force fetch on form
                discount: rowData.discount._id === "0" ? null : rowData.discount._id,
                discountId: rowData.discount._id === "0" ? null : rowData.discount._id,
                keepInstallmentsOnARS: rowData.payment.keepInstallmentsOnARS || false,
                hasAdvancedConfigEnabled: rowData.installments.length > 0 || false,
            });
            setDisplayForm(FORM_CONFIG.EDIT);
        }
    };
    const getFormAndPreview = (type, form) => {
        const isForm = type === "FORM";
        const isCreate = displayForm === FORM_CONFIG.CREATE;

        if (isForm && isCreate) {
            // Handle form creation
            return <NewSalesPaymentsForm form={form} loading={loading}/>
        } else if (isForm) {
            // Handle form editing
            return <SalesPaymentsForm form={form} loading={loading}/>
        } else if (isCreate) {
            // Handle preview creation
            return null
        } else {
            // Handle preview editing
            return <SalesPaymentsView data={form.values}/>
        }
    };


    const handleSubmit = async (values) => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authToken,
            },
        };

        try {
            setLoading(true);
            let notificationMessage = ""

            // Check if alreadyUser key exist to identify which form if being submitted
            if (values.hasOwnProperty("alreadyUser")) {
                // Handle sale creation
                await createSale(values, config);
                notificationMessage = `El pago de ${values.name} ${values.lastname} ha sido creado con éxito.`
            } else {
                // Handle sale edit
                await editSale(values, config, uploadToS3);
                notificationMessage = `El pago de ${values.rowData.payer.name} ${values.rowData.payer.lastname} ha sido editado con éxito.`
            }

            showNotification({
                color: "green",
                status: "success",
                title: "¡Excelente! Operación exitosa.",
                message: notificationMessage,
            });

            setUpdateData(!updateData);
            setDisplayForm(FORM_CONFIG.NONE);
            setInitialValuesEdit(null);
        } catch (error) {
            showNotification({
                color: "red",
                status: "error",
                title: "Operación fallida.",
                message: `No se pudo crear / editar la inscripción. Detalle: ${
                    ERROR_HANDLER[error.request?.status] || ERROR_HANDLER.defaultError
                }`,
                autoClose: 12000,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSetInitialValuesCreate = () => {
        setInitialValuesEdit({
            alreadyUser: false,
            type: "student",
            currency: "ARS",
            discount: null
        })
    }

    // DATA INITIALIZATION
    const init = async () => {
        const url = `${process.env.REACT_APP_BACKEND_SERVER}admin/ventas/paymentsTable`;
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`,
            },
        };
        const paymentsResponse = await axios.get(url, config);
        // Sort by date
        paymentsResponse.data.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
        });
        // Filter non-confirmed payments
        const PAYMENTS_TO_FILTER = ["payer_action_required"];
        const filteredPayments = paymentsResponse.data.filter((_pay) => {
            return !PAYMENTS_TO_FILTER.includes(_pay.state.toLowerCase());
        });
        // Also filter "APPROVAL_PENDING" payments that are not paid ("PENDING")
        // If you want to change this, mind that will have to getStateBadge function
        const filteredPayments2 = filteredPayments.filter((_pay) => {
            const firstInstallmentState = _pay.installments?.[0]?.state || "NULL";
            return !(
                _pay.state.toLowerCase() === "approval_pending" &&
                firstInstallmentState === "PENDING"
            );
        });

       setSalesPaymentsData(filteredPayments2);
    };

    useEffect(() => {
        init();
    }, [updateData]);

    return (
        <div className="salesPayments__container">
            {displayForm !== FORM_CONFIG.NONE && (
                <ContentCreator
                    title={initialValuesEdit ? "Crear inscripción" : "Editar inscripción"}
                    submitFunc={(values) => handleSubmit(values)}
                    onCancel={() => {
                        setDisplayForm(FORM_CONFIG.NONE);
                        setInitialValuesEdit(null);
                    }}
                    initialValues={initialValuesEdit ?? null}
                    renderPreview={(_form) => getFormAndPreview("PREVIEW", _form.form)}
                    renderForm={(_form) => getFormAndPreview("FORM", _form.form)}
                />
            )}
            {salesPaymentsData && (
                <TanStackTable
                    loading={loading}
                    data={salesPaymentsData}
                    columns={columns}
                    displayForm={displayForm !== FORM_CONFIG.NONE}
                    editFunc={editSalesPaymentsEvent}
                    actionsFunc={(rowData) => editPaymentStateAction(rowData)}
                    addButtonFunc={() => {
                        setDisplayForm(FORM_CONFIG.CREATE);
                        handleSetInitialValuesCreate();
                        if (displayForm !== FORM_CONFIG.NONE) setInitialValuesEdit(null);
                    }}
                />
            )}
            {modalOpen && studentData && (
                <StudentModal
                    open={modalOpen}
                    setOpen={setModalOpen}
                    studentData={studentData}
                />
            )}
        </div>
    );
}

export default SalesPayments;
