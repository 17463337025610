import './ModerationStructuresForm.css'
import { Accordion, Button, Checkbox, Flex, Grid, Group, Select, Stack } from '@mantine/core'
import { IconCalendar, IconClockHour12, IconEdit, IconSchool } from '@tabler/icons-react'
import React, { useContext, useState } from 'react'
import ModerationStructuresModules from '../ModerationStructuresModules/ModerationStructuresModules'
import ModerationStructuresFormModal from '../ModerationStructuresFormModal/ModerationStructuresFormModal'
import MaterialsModal from '../../../../components/MaterialsModal/MaterialsModal'
import AppContext from '../../../../contexts/AppContext'
import AuthContext from '../../../../contexts/AuthContext'
import axios from 'axios'
import showNotification from '../../../../utils/showNotification'
import { MaterialsTable } from '../../../../components/MaterialsTable/MaterialsTable'

export default function ModerationStructuresForm({ form, isLoading, handleSubmit }) {
   const { materialOptions, materialsData } = useContext(AppContext);
   const { authToken } = useContext(AuthContext)

   const axiosConfig = {
      headers: {
         "Content-Type": "application/json",
         Authorization: `Bearer ${authToken}`,
      },
   }

   const generalMaterials = form.values.generalMaterials;
   const { name, classDays, classHour } = form.values.comercialInformation
   const [classesToEdit, setClassesToEdit] = useState([])
   const [isModal, setIsModal] = useState(false)

   const [selectZoom, setSelectZoom] = useState('actual')
   const [selectZoomCode, setSelectZoomCode] = useState('actual')
   const [selectModerator, setSelectModerator] = useState('actual')
   const [sendNotification, setSendNotification] = useState(false)
   const [isMaterialModal, setIsMaterialModal] = useState(false)
   const [currentMaterial, setCurrentMaterial] = useState({
      currentMaterialId: null,
      sendNotification: false
   })

   const resetInitalValues = () => {
      setIsModal(false)
      setClassesToEdit([])
      setSelectModerator('actual')
      setSelectZoom('actual')
      setSelectZoomCode('actual')
      setSendNotification(false)
   }

   const onClickSelectAll = () => {
      if (!form?.values?.modules) return null

      if (classesToEdit.length === 0) {
         const allLessons = form.values.modules.flatMap(module => {
            return module.lessons.map(lesson => lesson._id)
         })

         return setClassesToEdit(allLessons)
      }


      return setClassesToEdit([])
   }

   const addMaterialRow = async () => {
      // Check if the material is already in the list
      try {
         if (!currentMaterial.currentMaterialId) return;
         if (generalMaterials.some(material => material._id === currentMaterial.currentMaterialId)) return;

         const putURL = `${process.env.REACT_APP_BACKEND_SERVER}admin/moderation/insertMaterials`


         const idToUse = Array.isArray(form.values.id) ? form.values.id : [form.values.id]

         const data = {
            academicUnits: idToUse,
            materialId: currentMaterial.currentMaterialId,
            lessons: [],
            sendNotification: currentMaterial.sendNotification
         }


         const { data: newMaterialId } = await axios.put(
            putURL,
            { data },
            axiosConfig
         )

         showNotification({
            color: "green",
            status: "success",
            title: "¡Excelente! Operación exitosa.",
            message: `El material ha sido cargado con éxito.`,
         });

         // Add the new material to the list
         form.setFieldValue("generalMaterials", [...generalMaterials, setMaterialData(newMaterialId)]);
         setCurrentMaterial({ currentMaterialId: null, sendNotification: false })
      } catch (error) {
         console.error(error)
         showNotification({
            color: "red",
            status: "error",
            title: "Operación fallida.",
            message: `No se pudo crear el material. Detalle: ${error}`,
            autoClose: 12000
         });
      }
   }

   const setMaterialData = (_currentMaterial) => {
      return materialsData.find(item => item._id === _currentMaterial)
   }

   const getMaterialData = () => {
      return generalMaterials.map((material) => {
         if (material?.isNew) return material
         if (material?._id) return materialsData.find((data) => data._id === material._id);
         return materialsData.find((data) => data._id === material);
      });
   }

   const removeMaterialRow = async (materialId) => {
      // Remove the material from the list
      try {
         const putURL = `${process.env.REACT_APP_BACKEND_SERVER}admin/moderation/deleteMaterial`

         const data = {
            academicUnitId: form.values.id,
            materialId
         }

         await axios.put(
            putURL,
            { data },
            axiosConfig
         )

         const newMaterials = form.values.generalMaterials.filter((material) => {
            if (typeof material === 'string') {

               return material !== materialId;
            } else if (typeof material === 'object' && material._id) {

               return material._id !== materialId;
            }

            return true;
         });


         showNotification({
            color: "green",
            status: "success",
            title: "¡Excelente! Operación exitosa.",
            message: `El material ha sido eliminado con éxito.`,
         });

         form.setFieldValue("generalMaterials", [...newMaterials]);
         return

      } catch (error) {
         console.error(error)
         showNotification({
            color: "red",
            status: "error",
            title: "Operación fallida.",
            message: `No se pudo eliminar el material. Detalle: ${error}`,
            autoClose: 12000
         });
      }
   }

   return (
      <>
         <Grid w='100%' >
            <Grid.Col span={12}>
               <Stack >
                  <Group gap={6} align='center'>
                     < IconSchool className='ModerationStructuresForm-academicName--icon' size='100%' />
                     <h3 className='ModerationStructuresForm--academicName'>{name}</h3>
                  </Group>

                  <Group>
                     <Group>
                        < IconCalendar />
                        <span className='ModerationStructuresForm--bodyText'>{classDays}</span>
                     </Group>
                     <Group>
                        < IconClockHour12 />
                        <span className='ModerationStructuresForm--bodyText'>{classHour}</span>
                     </Group>
                  </Group>


                  <Accordion>
                     <Accordion.Item key={"Materiales Generales"} value={"Materiales Generales"}>
                        <Accordion.Control><h3 className='ModerationStructuresForm--bodyText' style={{fontWeight: 700}} >
                        Materiales Generales
                           </h3></Accordion.Control>
                        <Accordion.Panel>
                           <Grid>

                              <Grid.Col span={{base: 12, sm: 8}}>
                                 <Select
                                    data={materialOptions}
                                    label="Materiales ya creados"
                                    searchable
                                    value={currentMaterial.currentMaterialId}
                                    onChange={(value) => setCurrentMaterial(prev => ({ ...prev, currentMaterialId: value }))}
                                 />
                              </Grid.Col>
                              <Grid.Col span={{base: 12, sm: 4}}>
                                 <Group>
                                    <Button
                                       style={{ width: "100%", marginTop: "20px" }}
                                       onClick={addMaterialRow}
                                    >
                                       Agregar material
                                    </Button>
                                    <Checkbox defaultChecked={currentMaterial.sendNotification} onChange={(value) => setCurrentMaterial(prev => ({ ...prev, sendNotification: value.currentTarget.checked }))} label='Notificar a los usuarios?' />
                                 </Group>
                              </Grid.Col>
                              <Grid.Col span={12}>
                                 <Button
                                    w='max-content'
                                    variant='outline' color='secondaryColor' onClick={() => setIsMaterialModal(true)}>Crear material nuevo</Button>
                              </Grid.Col>
                              <Grid.Col span={12}>
                                 <MaterialsTable
                                    data={getMaterialData()}
                                    height={200}
                                    removeRow={removeMaterialRow}
                                 />
                              </Grid.Col>
                           </Grid>
                        </Accordion.Panel>
                     </Accordion.Item>
                  </Accordion>


                  {isMaterialModal &&
                     < MaterialsModal
                        opened={isMaterialModal}
                        onCreate={
                           (newMaterial) => {
                              form.setFieldValue("generalMaterials", [...generalMaterials, newMaterial]);
                           }
                        }
                        onClose={setIsMaterialModal}
                        idToAdd={form.values.id}
                        isGeneral
                     />
                  }

                  <Stack>
                     <Group align='center'>
                        <span className='ModerationStructuresForm--bodyText'><strong>{classesToEdit.length}</strong> clases seleccionadas</span>
                        <Button
                           disabled={!classesToEdit.length}
                           color='var(--color-tertiary)'
                           onClick={() => {
                              setIsModal(true)
                           }}
                        >
                           < IconEdit />
                           <span>Editar</span>
                        </Button>
                     </Group>
                     <Button w='max-content' variant='outline'
                        onClick={() => onClickSelectAll()}
                        color='var(--color-tertiary)'
                     >{
                           classesToEdit.length === 0 ? 'Seleccionar todas' : 'Borrar selección'
                        }</Button>
                  </Stack>
               </Stack>
            </Grid.Col>

            <Grid.Col span={12}>

               <Checkbox.Group
                  value={classesToEdit}
                  onChange={setClassesToEdit}
               >
                  < ModerationStructuresModules
                     modules={form?.values?.modules}
                     setClassesToEdit={setClassesToEdit}
                     setIsModal={setIsModal}
                  />
               </Checkbox.Group>

            </Grid.Col>

         </Grid>

         < ModerationStructuresFormModal
            isModal={isModal}
            resetInitalValues={resetInitalValues}
            classesToEdit={classesToEdit}
            selectZoom={selectZoom}
            setSelectZoom={setSelectZoom}
            selectZoomCode={selectZoomCode}
            setSelectZoomCode={setSelectZoomCode}
            selectModerator={selectModerator}
            setSelectModerator={setSelectModerator}
            form={form}
            sendNotification={sendNotification}
            setSendNotification={setSendNotification}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
         />
      </>
   )
}
