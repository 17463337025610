import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import AcademicContext from "./AcademicContext.js";
import Axios from "axios";
import AuthContext from "../AuthContext";
import showNotification from "../../utils/showNotification";
import {ERROR_HANDLER} from "../../utils/error-handler";

const AcademicContextProvider = ({children}) => {
    const {authToken} = useContext(AuthContext);
    const [isReady, setIsReady] = useState(false);
    const [modulesOptions, setModulesOptions] = useState([]);
    const [lessonsOptions, setLessonsOptions] = useState([]);
    const [questionnairesData, setQuestionnairesData] = useState([]);
    const [specialtiesOptions, setSpecialtiesOptions] = useState([]);
    const [lessonsData, setLessonsData] = useState([]);
    const [lessonsDict, setLessonsDict] = useState({});
    const [modified, setModified] = useState(false);
    const handleModified = useCallback(() => {
        setModified(!modified)
    }, [modified]);

    useEffect(() => {
        const init = async () => {
            try {
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + authToken,
                    }
                }
                const response = await Axios.get(`${process.env.REACT_APP_BACKEND_SERVER}admin/academico/context`, config)
                const {data} = response
                setModulesOptions(data.modules)
                setLessonsOptions(data.lessons);
                setSpecialtiesOptions(data.specialties);

                const lessonsResponse = await Axios.get(`${process.env.REACT_APP_BACKEND_SERVER}admin/academico/lessons`, config)
                const lessonsDataProcessed = {}
                lessonsResponse.data.forEach(lesson => {
                    lessonsDataProcessed[lesson._id] = {
                        name: lesson.name,
                        modules: lesson.modules,
                        initDate: lesson.initDate,
                        roomUrl: lesson.roomUrl,
                    }
                })
                setLessonsDict(lessonsDataProcessed)
                setLessonsData(lessonsResponse.data)

                const url = `${process.env.REACT_APP_BACKEND_SERVER}admin/academico/questionaries`
                const questionnairesResponse = await Axios.get(url, config)
                setQuestionnairesData(questionnairesResponse.data)
            } catch (error) {
                showNotification({
                    color: "red",
                    status: "error",
                    title: "Ha ocurrido un problema al cargar tus datos.",
                    message: `Detalle: ${ERROR_HANDLER[error.request?.status] || ERROR_HANDLER.defaultError}`,
                    autoClose: 12000
                })
            } finally {
                setIsReady(true);
            }
        }

        init();
    }, [modified]);

    const context = useMemo(
        () => ({
            isReady,
            modulesOptions,
            lessonsOptions,
            questionnairesData,
            specialtiesOptions,
            lessonsData, setLessonsData,
            lessonsDict,
            handleModified
        }),
        [
            isReady,
            modulesOptions,
            lessonsOptions,
            questionnairesData,
            specialtiesOptions,
            lessonsData, setLessonsData,
            handleModified,
            lessonsDict
        ]
    );
    return (
        <AcademicContext.Provider value={context}>{children}</AcademicContext.Provider>
    );
};
export default AcademicContextProvider;
