import { showNotification } from "@mantine/notifications";
import { fileToBase64 } from "./fileToBase64";
import { isImageUrl, isVideoUrl } from "./isImageUrl";

// Esta función permite devolver un string para que una etiqueta <img /> lo pueda usar en su atributo src
// Si es una imagen en formato de URL devuelve ese mismo path (chequear si está en el array de opciones)
// Si sigue se entiende que es un código en base 64. La función convierte el archivo en un código que la etiqueta puede interpretar.
export const renderFile = async (file, allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf']) => {
    if (!file) return;

    if (isImageUrl(file)) return file;

    try {
        const base64File = await fileToBase64(file, allowedTypes);
        return base64File;
    } catch (error) {
        showNotification({
            color: 'red',
            status: 'error',
            title: 'Ha ocurrido un problema al cargar el archivo.',
            message: `${error}`,
        });
    }
};

export const renderVideo = async (
   file,
   allowedTypes = ['video/mp4', 'video/webm', 'video/ogg', 'video/mov', 'video/avi', 'video/mkv']
) => {
   if (!file) return;

   if (isVideoUrl(file)) return file;

   try {
      const base64Video = await fileToBase64(file, allowedTypes);
      return base64Video;
   } catch (error) {
      showNotification({
         color: 'red',
         status: 'error',
         title: 'Ha ocurrido un problema al cargar el video.',
         message: `${error}`,
      });
   }
};