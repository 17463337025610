import {
  RingProgress,
  Text,
  SimpleGrid,
  Paper,
  Center,
  Group,
} from "@mantine/core";
import { IconArrowUpRight, IconArrowDownRight, IconArrowRight } from "@tabler/icons-react";
import './AcademicDbExamsByType.css';

const icons = {
  green: IconArrowUpRight, // Flecha hacia arriba para verde
  yellow: IconArrowRight, // Flecha a la mitad para amarillo
  red: IconArrowDownRight, // Flecha hacia abajo para rojo
};

// Define the custom order
const order = [
  "Diplomado",
  "Posgrado",
  "Curso",
  "Masterclass",
  "Webinar",
  "Taller",
];

const getColor = (value) => {
  if (value >= 80) return "var(--color-badge-green)";
  if (value >= 60) return "var(--color-badge-yellow)";
  return "var(--color-badge-red)";
};

const getIcon = (color) => {
  if (color === "var(--color-badge-green)") return icons.green;
  if (color === "var(--color-badge-yellow)") return icons.yellow;
  return icons.red;
};

const AcademicDbExamsByType = ({ averageByType }) => {
  // Convert averageByType object to an array of objects for rendering
  const stats = Object.keys(averageByType).map((key) => {
    const color = getColor(averageByType[key]);
    return {
      label: key,
      stats: averageByType[key],
      progress: averageByType[key],
      color: color,
      icon: getIcon(color),
    };
  });

  // Sort stats based on the custom order
  const sortedStats = stats.sort(
    (a, b) => order.indexOf(a.label) - order.indexOf(b.label)
  );

  return (
    <>
      <h5 className="academicDbExams-card-title">
        Promedio de resultados por U.A
      </h5>
      <SimpleGrid cols={3}>
        {sortedStats.map((stat) => {
          const Icon = stat.icon;
          return (
            <Paper withBorder radius="md" p="xs" key={stat.label}>
              <Group>
                <RingProgress
                  size={80}
                  roundCaps
                  thickness={8}
                  sections={[
                    {
                      value: Math.min(stat.progress, 100),
                      color: stat.color,
                    },
                  ]}
                  label={
                    <Center>
                      <Icon style={{ width: 20, height: 20 }} stroke={1.5} />
                    </Center>
                  }
                />

                <div>
                  <Text size="xs" transform="uppercase" fontWeight={700}>
                    {stat.label}
                  </Text>
                  <Text fontWeight={700} size="xl">
                    {Math.round(stat.stats)}%
                  </Text>
                </div>
              </Group>
            </Paper>
          );
        })}
      </SimpleGrid>
    </>
  );
};

export default AcademicDbExamsByType;
