import './Academic.css'
import { Route, Routes } from "react-router";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import ViewLayout from "../../layouts/ViewLayout/ViewLayout";
import AcademicHome from "../../features/Academic/AcademicHome/AcademicHome";
import AcademicUsersView from '../../features/Academic/AcademicUsers/AcademicUserView/AcademicUsersView';
import AcademicSpecialtiesContainer from "../../features/Academic/AcademicSpecialtiesContainer/AcademicSpecialtiesContainer";
import AcademicUnitsContainer from "../../features/Academic/AcademicUnitsContainer/AcademicUnitsContainer";
import {withAcademicContextReady, withAcademicContextProvider} from "../../contexts/AcademicContext";
import AcademicValorationsContainer from '../../features/Academic/AcademicValorationsContainer/AcademicValorationsContainer';


const Academic = () => {
   return (
      <MainLayout>
         <Routes>
            < Route
               path='/'
               element={
                  <ViewLayout
                     title='Académico'
                     description='Aqui podras generar contenido específico para la sección relacionadas a lo Académico del sitio web y del campus.'
                  >
                     < AcademicHome />
                  </ViewLayout>
               }
            />
             < Route
                 path='/unidades-academicas'
                 element={
                     <ViewLayout
                         title='Unidades Académicas'
                         description='Aquí podrás editar las unidades académicas existentes o crear una nueva.'
                         backBtnText='Ir a Académico'
                         withBackButton
                     >
                         <AcademicUnitsContainer />
                     </ViewLayout>
                 }
             />
            < Route
               path='/usuarios'
               element={
                  <ViewLayout
                     title='Usuarios'
                     description='Aqui podras generar contenido específico para la sección relacionadas a los usuarios.'
                     backBtnText='Ir a Académico'
                     withBackButton
                  >
                     < AcademicUsersView />
                  </ViewLayout>
               }
            />
             < Route
                 path='/especialidades'
                 element={
                     <ViewLayout
                         title='Especialidades'
                         description='Aquí podrás editar las especialidades existentes o crear una nueva.'
                         backBtnText='Ir a Académico'
                         withBackButton
                     >
                         < AcademicSpecialtiesContainer />
                     </ViewLayout>
                 }
             />
             < Route
                 path='/valoraciones'
                 element={
                     <ViewLayout
                         title='Valoraciones'
                         description='Aquí podrás ver y revisar todas las valoraciones de las clases dadas'
                         backBtnText='Ir a Académico'
                         withBackButton
                     >
                         < AcademicValorationsContainer />
                     </ViewLayout>
                 }
             />
         </Routes>
      </MainLayout>
   );
};

export default withAcademicContextProvider(withAcademicContextReady(Academic));
