import './AdministrativeUserDetails.css'
import {Accordion, Avatar, Badge, Button, Card, Flex, Group, Loader, Stack,} from '@mantine/core'
import {IconPhone, IconMail, IconSchool, IconEye} from '@tabler/icons-react'
import {useContext, useEffect, useState} from 'react'
import AdministrativePaymentModal from '../AdministrativePaymentModal/AdministrativePaymentModal'
import {mapUserType} from '../../../../utils/user-types'
import AuthContext from '../../../../contexts/AuthContext'
import axios from 'axios'
import showNotification from '../../../../utils/showNotification'
import {ERROR_HANDLER} from '../../../../utils/error-handler'

export default function AdministrativeUserDetails({form, isLoading}) {
    const {authToken} = useContext(AuthContext)
    const {email, name, lastname, phone, disabled, profilePicture, type} = form.values
    const [payments, setPayments] = useState(null)
    const [paymentModal, setPaymentModal] = useState(null)

    const render = disabled
        ? {
            badge: {
                text: 'inhabilitado',
                color: '#DC3D3D'
            },
            button: {
                text: 'Habilitar',
                color: '#3bc23b'
            },
        } : {
            badge: {
                text: 'habilitado',
                color: '#3bc23b'
            },
            button: {
                text: 'Inhabilitar',
                color: '#DC3D3D'
            },
        }

    function getPaymentBadge(state) {
        const mapPaymentState = {
            pending: {
                text: 'pendiente',
                className: 'AdministrativePaymentModal--pending'
            },
            success: {
                text: 'pagado',
                className: 'AdministrativePaymentModal--success'
            },
            cancelled: {
                text: 'cancelado',
                className: 'AdministrativePaymentModal--cancelled'
            },
            default: {
                text: 'desconocido',
                className: 'AdministrativePaymentModal--default'
            }
        };

        const lowerState = state?.toLowerCase();
        return mapPaymentState[lowerState] || mapPaymentState.default;
    }

    useEffect(() => {

        const fetchUserPayments = async () => {
            try {
                const {data} = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER}admin/administracion/getUserPayment/${email}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + authToken,
                        }
                    }
                );

                setPayments(data?.payments)
            } catch (error) {
                console.error(error)
                showNotification({
                    color: "red",
                    status: "error",
                    title: "Error al intentar cargar los pagos del usuario.",
                    message: `Detalle: ${ERROR_HANDLER[error.request?.status] || ERROR_HANDLER.defaultError}`,
                    autoClose: 12000
                });
            }
        }
        fetchUserPayments();
    }, [authToken, email])

    return (
        <section className='AdministrativeUserDetails--container'>
            <Group justify='center'>
                <Card maw={{base: 'unset', md: '400px'}} w={{base: '100%'}} shadow="sm" padding="lg" radius="md"
                      withBorder>
                    <Group justify='center' className='AdministrativeUserDetails-card-headerContainer'>
                        <Avatar
                            src={profilePicture}
                            h={150}
                            w={150}
                            alt={`foto de perfil de ${name} ${lastname}`}
                        />
                        <Badge
                            className='AdministrativeUserDetails-card---userType'>{mapUserType[type] || 'usuario'}</Badge>
                    </Group>

                    <Flex gap='sm' direction={{base: 'column', sm: 'row'}}
                          justify={{base: 'unset', sm: 'space-between'}} align='center' mb={10}>
                        <h3 className='AdministrativeUserDetails--username'>{`${name} ${lastname}`}</h3>
                        <Badge color={render.badge.color}>{render.badge.text}</Badge>
                    </Flex>

                    <Stack>
                        {phone &&
                            <Flex
                                gap="sm"
                                direction={{base: 'column', sm: 'row'}}
                                align={'center'}
                            >
                                < IconPhone color='#5D5A6F'/>
                                <p className='AdministrativeUserDetails--itemText'>{phone}</p>
                            </Flex>
                        }
                        {email &&
                            <Flex
                                gap="sm"
                                direction={{base: 'column', sm: 'row'}}
                                align={'center'}
                            >
                                < IconMail color='#5D5A6F'/>
                                <p className='AdministrativeUserDetails--itemText'>{email}</p>
                            </Flex>
                        }

                    </Stack>

                    <Button
                        color={render.button.color}
                        fullWidth
                        mt="md"
                        radius="md"
                        type='submit'
                        disabled={isLoading}
                    >
                        {isLoading ? < Loader size={20} color='#fff'/> : render.button.text}
                    </Button>
                </Card>
            </Group>

            <Accordion defaultValue='payments'>


                <Accordion.Item key={'payments'} value='payments'>
                    <Accordion.Control>
                        <h4 className='AdministrativeUserDetails--username'>Pagos</h4>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <Stack>
                            {payments?.length > 0
                                ? payments?.map(item => (
                                    <Group align='start' w='100%' pb={20}
                                           style={{borderBottom: '1px solid var(--color-text-primary)'}}>
                                        <Button onClick={() => setPaymentModal(item)}>
                                            < IconEye/>
                                        </Button>
                                        <Stack align='start' justify='center'>
                                            <span
                                                className='AdministrativeUserDetails--academicUnitsName'>{`PAGO ${item.payment.type}`}</span>
                                            {item?.details?.length > 0 && <Group gap='5px' align='center'>
                                                < IconSchool/>
                                                <span
                                                    className='AdministrativeUserDetails--academicUnitsName'>{item.details[0].academicUnitId.comercialInformation.name}</span>
                                            </Group>}
                                        </Stack>
                                        <span
                                            className={`AdministrativePaymentModal--badge ${getPaymentBadge(item.state).className}`}>
                                           {getPaymentBadge(item.state).text}
                                        </span>
                                    </Group>
                                ))
                                : <p className='AdministrativeUserDetails--itemText'>No hay pagos relacionados</p>
                            }
                        </Stack>
                    </Accordion.Panel>
                </Accordion.Item>


                {/*  <Accordion.Item key={'academicUnits'} value={'academicUnits'}>
               <Accordion.Control >
                  <h4 className='AdministrativeUserDetails--username'>Unidades Académicas</h4>
               </Accordion.Control>
               <Accordion.Panel>
                  <Stack>
                     {academicUnits?.length > 0
                        ? academicUnits?.map(item => (
                           <Flex
                              key={item._id}
                              direction={{base: 'column', sm: 'row'}}
                              align={'center'}
                              gap='sm'
                           >
                              < IconSchool color='var(--color-text-secondary)' />
                              <h5 className='AdministrativeUserDetails--academicUnitsName'>{item.comercialInformation.name}</h5>
                           </Flex>
                        ))
                        : <p className='AdministrativeUserDetails--itemText'>No hay unidades académicas relacionadas</p>
                     }
                  </Stack>
               </Accordion.Panel>
            </Accordion.Item> */}
            </Accordion>

            {paymentModal &&
                < AdministrativePaymentModal
                    opened={paymentModal}
                    onClose={() => {
                        setPaymentModal(null)
                    }}
                    payment={paymentModal}
                />}

        </section>
    );
}
