import {
    Skeleton,
    SimpleGrid,
    Paper,
    Group,
    Box,
    Center,
  } from "@mantine/core";
  import { IconArrowUpRight, IconArrowDownRight, IconDeviceAnalytics } from "@tabler/icons-react";
  
  const icons = {
    up: IconArrowUpRight,
    down: IconArrowDownRight,
  };
  
  const AcademicDbExamsSkeleton = () => {
    return (
      <div>
        <h5 className="academicDbExams-card-title">
          <Skeleton height={24} width="60%" />
        </h5>
        <SimpleGrid cols={3} spacing="lg" mt="md">
          {[...Array(6)].map((_, index) => (
            <Paper withBorder radius="md" p="xs" key={index}>
              <Group>
                <Center>
                  <Skeleton height={80} width={80} circle />
                </Center>
                <div>
                  <Skeleton height={16} width="80%" mb="xs" />
                  <Skeleton height={20} width="60%" />
                </div>
              </Group>
            </Paper>
          ))}
        </SimpleGrid>
  
        <Paper withBorder p="md" radius="md" mt="xl">
          <Group justify="space-between" align="flex-end">
            <Skeleton height={24} width="50%" />
            <IconDeviceAnalytics size="1.4rem" className="icon" stroke={1.5} />
          </Group>
          <Skeleton height={16} width="60%" mt="md" mb="lg" />
          <Skeleton height={34} />
          <SimpleGrid cols={{ base: 1, xs: 3 }} mt="xl">
            {[...Array(3)].map((_, index) => (
              <Box
                key={index}
                style={{ borderBottomColor: '#e0e0e0' }}
                className="stat"
              >
                <Skeleton height={16} width="50%" />
                <Group justify="space-between" align="flex-end" gap={0}>
                  <Skeleton height={16} width="20%" />
                  <Skeleton height={16} width="20%" />
                </Group>
              </Box>
            ))}
          </SimpleGrid>
        </Paper>
  
        <Paper withBorder p="md" radius="md" mt="xl">
          <Group align="flex-end" gap="xs">
            <Skeleton height={24} width="50%" />
          </Group>
          <Skeleton height={16} width="60%" mb="lg" />
          <Skeleton height={34} />
          <SimpleGrid cols={{ base: 1, xs: 2 }} mt="xl">
            {[...Array(2)].map((_, index) => (
              <Box
                key={index}
                style={{ borderBottomColor: '#e0e0e0' }}
                className="stat"
              >
                <Skeleton height={16} width="50%" />
                <Group justify="space-between" align="flex-end" gap={0}>
                  <Skeleton height={16} width="20%" />
                  <Skeleton height={16} width="20%" />
                </Group>
              </Box>
            ))}
          </SimpleGrid>
        </Paper>
      </div>
    );
  };
  
  export default AcademicDbExamsSkeleton;
  