export const isImageUrl = (url) => {
   if (typeof url !== 'string') return false; // Verificar si la URL es una cadena de texto
   const imageExtensions = ['.jpg', '.jpeg', '.png']; // Extensiones comunes de imágenes

   const urlLowerCase = url.toLowerCase();
   return imageExtensions.some(ext => urlLowerCase.endsWith(ext));
}

export const isVideoUrl = (url) => {
   if (typeof url !== 'string') return false; // Verificar si la URL es una cadena de texto
   const videoExtensions = ['.mp4', '.webm', '.ogg', '.mov', '.avi', '.mkv']; // Extensiones comunes de videos

   const urlLowerCase = url.toLowerCase();
   return videoExtensions.some(ext => urlLowerCase.endsWith(ext));
};
