import { IconTrendingDown, IconTrendingUp } from "@tabler/icons-react";
import { Flex, Group, Stack, Tooltip } from "@mantine/core";
import "./AcademicDbRankings.css";
import AcademicDbRankByType from "../../../../components/AcademicDashboardComponents/AcademicDbRankByType/AcademicDbRankByType";

const transformToPercentage = (value) => Math.round(value * 100);

const AcademicDbRankings = ({ data }) => {
  if (!data?.top) return <p>Sin datos que mostrar</p>;

  const { bestValue, worstValue } = data.top;
  const { byType, average } = data;

  const sortedBestValue = bestValue.sort((a, b) => b.rate - a.rate);
  const sortedWorstValue = worstValue.sort((a, b) => b.rate - a.rate);

  // Determine the CSS class based on the average value
  let averageClass = '';
  if (average > 0.85) {
    averageClass = 'academicDbTeachersRank-averageNumber-green';
  } else if (average >= 0.70) {
    averageClass = 'academicDbTeachersRank-averageNumber-yellow';
  } else {
    averageClass = 'academicDbTeachersRank-averageNumber-red';
  }

  return (
    <div className="academicDbRankings-mainDiv">
      <h3 className="academicDbRankings-ranks-header">Unidades Académicas</h3>
      <div className='academicDbRankings-box'>
        <h6 className='academicDbRankings-boxText'>
          Promedio general de las U.A: <span className={`academicDbRankings-averageNumber ${averageClass}`}>{transformToPercentage(average)}%</span>
        </h6>
      </div>
      <div className="academicDbRankings-gridContainer">
        <article className="academicDbRankings-card">
          <Group>
            <Group gap="0">
              <IconTrendingUp />
            </Group>
            <h3 className="academicDbRankings-card-title">Mejor rankeadas</h3>
          </Group>
          <Stack className="academicDbRankings-stack-wrapper">
            {sortedBestValue && sortedBestValue.length > 0 ? (
              sortedBestValue.map((item) => (
                <Flex
                  className="academicDbRankings-card-rowItem"
                  align="start"
                  justify="space-between"
                  w="100%"
                  gap="5px"
                  key={item._id}
                >
                  <Tooltip label={`Cantidad de valoraciones: ${item.totalValorations}`} withArrow>
                    <p className="academicDbRankings-card-bodyText">{item._id.name}</p>
                  </Tooltip>
                  <span
                    style={{
                      backgroundColor: '#4fa04fff',
                      color: '#fff',
                      fontSize: 'var(--fs-tiny)',
                      padding: '2px 8px',
                      borderRadius: 'var(--border-radius-md)',
                      fontWeight: 'bold',
                    }}
                  >
                    {transformToPercentage(item.rate)}%
                  </span>
                </Flex>
              ))
            ) : (
              <p className="academicDbRankings-card-bodyText">Aún no hay rankings de unidades académicas</p>
            )}
          </Stack>
        </article>

        <article className="academicDbRankings-card">
          <Group>
            <Group gap="0">
              <IconTrendingDown />
            </Group>
            <h3 className="academicDbRankings-card-title">Peor rankeadas</h3>
          </Group>
          <Stack className="academicDbRankings-stack-wrapper">
            {sortedWorstValue && sortedWorstValue.length > 0 ? (
              sortedWorstValue.map((item) => (
                <Flex
                  className="academicDbRankings-card-rowItem"
                  justify="space-between"
                  align="start"
                  w="100%"
                  gap="5px"
                  key={item._id}
                >
                  <Tooltip label={`Cantidad de valoraciones: ${item.totalValorations}`} withArrow>
                    <p className="academicDbRankings-card-bodyText">{item._id.name}</p>
                  </Tooltip>
                  <span
                    style={{
                      backgroundColor: '#a04f4fff',
                      color: '#fff',
                      fontSize: 'var(--fs-tiny)',
                      padding: '2px 8px',
                      borderRadius: 'var(--border-radius-md)',
                      fontWeight: 'bold',
                    }}
                  >
                    {transformToPercentage(item.rate)}%
                  </span>
                </Flex>
              ))
            ) : (
              <p className="academicDbRankings-card-bodyText">Aún no hay rankings de unidades académicas</p>
            )}
          </Stack>
        </article>
        <article className="academicDbRankings-card">
            <AcademicDbRankByType data={byType}/>
        </article>
      </div>
    </div>
  );
};

export default AcademicDbRankings;
