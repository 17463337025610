import useAcademicDashboard from "../../../../hooks/Academic/Dashboard/useAcademicDashboard";
import { AcademicDbExams } from "../AcademicDbExams/AcademicDbExams";
import AcademicDbExamsSkeleton from "../AcademicDbExams/AcademicDbExamsSkeleton";
import AcademicDbRankings from "../AcademicDbRankings/AcademicDbRankings";
import AcademicDbRankingsSkeleton from "../AcademicDbRankings/AcademicDbRankingsSkeleton";
import AcademicDbTeachers from "../AcademicDbTeachers/AcademicDbTeachers";
import AcademicDbTeachersRankSkeleton from "../AcademicDbTeachers/AcademicDbTeachersRankSkeleton";
import AcademicDbUsers from "../AcademicDbUsers/AcademicDbUsers";
import AcademicDbUsersPieChartSkeleton from "../AcademicDbUsers/AcademicDbUsersPieChartSkeleton";

const AcademicDbView = () => {
  const { acaUnits, isLoading, exams, teachers, users } = useAcademicDashboard();
  return (
    <>
    <div>
      {isLoading || !acaUnits ? (
        <AcademicDbRankingsSkeleton/>
      ) : (
        <AcademicDbRankings data={acaUnits} />
      )}
    </div>

    <div>
      {isLoading || !exams ? (
        <AcademicDbExamsSkeleton/>
      ) : (
        <AcademicDbExams data={exams} />
      )}
    </div>
    <div>
      {isLoading || !teachers ? (
        <AcademicDbTeachersRankSkeleton/>
      ) : (
        <AcademicDbTeachers data={teachers} />
      )}
    </div>
    <div>
      {isLoading || !teachers ? (
        <AcademicDbUsersPieChartSkeleton/>
      ) : (
        <AcademicDbUsers data={users} />
      )}
    </div>
    </>
  );
};
export default AcademicDbView;
